.rotate {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    transform:rotate(360deg);
}
.street__wait__time__green{
    color: green !important;
  }
  .street__wait__time__yellow{
    color: #fac200 !important;
  }
  .street__wait__time__red{
    color: red !important;
  }

  /* Terminal Turn Time Colors */

.terminal__turn__time__green{
  color: green !important;
}


  .terminal__turn__time__red{
    color: red !important;
  }
  .terminal__turn__time__yellow{
    color: #fac200 !important;
  }

  /* Total wait time */
  .terminal__total__wait__time__green{
    color: green !important;
  }
  .terminal__total__wait__time__yellow{
    color: #fac200 !important;
  }
  .terminal__total__wait__time__red{
    color: red !important;
  }

  .note__heading{
      font-weight: 700;
  }

  .note__title{
    font-weight: 600;
    color: #000;
  }
  .TTTNoteDiv ul{
  padding-left: 20px;
}
 .TTTNoteDiv li{
    /* font-size: 14px; */
    margin-left: 10px;
    list-style-type: initial;
    /* display: inline; */

  }
  /* rect{
    height: 300px !important;
    width: 700px !important;
  } */