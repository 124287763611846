.downloadbutton{
    padding: 50px;
    float: left;
    display: block;
   
    margin: 10px;
}
.pdfbutton{
    padding: 50px;
    float: left;
    display: block;
    
    margin: 10px;

}