.railCrossinglRoutesMainDiv{
    margin-bottom:50px;
}

.railways-cross-section{
    margin-top:-100px;
}

.rc_marginalert{
    margin: 0 auto;
    margin-right: 10px;
    vertical-align: middle;
}
.railCrossing__fs20{
    font-size: 20px;
}