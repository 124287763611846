.vo-namecard-head{
    background-color: #4e4e4e;
    /* padding-left: 10%; */
}
.vessel_estimated {
    border-radius: 10px 10px 0px 0px;
  }
  .lastcol{
      padding-left: 70px;
  }

  .padd-left{
      padding-left:100px;
  }
 
  .operationicon-img{
      width: 30px;
  }