.calendar_icon{
    position: relative;
    margin-top: -33px;
    float: right;
    right: 8px;
}

.rc-time-picker-clear{
    display: none !important;
}

.rc-time-picker {
    display: block !important;
}

.rc-time-picker-input{
    height: 40px !important;
    font-size: inherit !important;
    color: fieldtext !important;
}

.rc-time-picker-panel{
    width: 115px !important;
}

@media (max-width: 858px) {
    .rc-time-picker-panel-select ul {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        background: bottom !important;
    }
}
