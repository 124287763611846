    .dropdownMobile {
      display: none;
    }

  .releaseheader {
    margin-top: 65px;
    width: 100%;
    height: 250px;
    background-color: rgb(67 121 127);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;    

  }

  .releasebody {
    display: flex;
    justify-content:space-between ;
    width: 80%;
  }
  .classesnav {
  width: 50%;
  }
 
  .classesnav ul {
      list-style:none;
      display:grid;
      justify-items: start;
  }
  
  .classesnav ul li {
      padding:40px 6px 10px 20px;
      display:inline-block;
      border-radius:10px;
      margin-bottom: 10px;	
  }
 
  
  .classesnav ul li span {
    cursor: pointer;

      display:block;	
      text-decoration:none;
      color:#000000;
  }
  
  .classesnavright {
    width: 60%;
  }
  
  .classesnavright ul {
      list-style:none;
      display:grid;
    justify-items: start;
  }
  .classesnavright ul li{
    list-style: none;
    /* list-style-type: disc;
  margin-left: 20px; */
  /* text-align: left; */
  text-align: justify;
  padding-bottom: 25px; 
}

.divComponent{
  padding-top: 40px;
}
.activeLinkClass{
  text-decoration: underline !important;
  text-underline-offset: 10px;
  font-weight: 600;
  color: #076d8e !important;
}

.contentClassList {
  text-wrap: unset;
}
/* .contentClassList ul {
  list-style: unset !important;
  padding: unset !important;
}

.contentClassList ul li {
  list-style: inside !important;
} */

.contentClassList {
  overflow: unset;
  display: list-item;
}

.contentClassList ol,
.contentClassList ul {
  margin: -20px 0;
  padding: 0 0 0 20px;
}

.contentClassList ol li{
  list-style: decimal !important;
  display: list-item;
  overflow: unset;
}
.contentClassList ul li {
  list-style: disc !important;
  display: list-item;
  overflow: unset;
}
.newsDetailsMainDiv .contentClassList{
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  white-space: pre-wrap;
  overflow: hidden;
  display: inline;
}

.contentClassList ol,
.contentClassList ul {
  display: block;
}

.contentClassList ul{
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  transition: none;
  text-align: left !important;
}

.contentClassList ol,
.contentClassList ul {
  display: block;
}

  @media screen and (max-width: 1023px) {
    .classesnavright {
      width: 100%;
    }
    .classesnavright ul h1 {
     text-align: left;
    }
  }
  @media screen and (max-width: 1023px) {
    .releaseheader {
    font-size: 2rem;
    text-align: center;
    }
  }
  @media screen and (max-width: 1023px) {
    .addedDiv {
      display: block;
      height: 60px;
    }
  }

  @media screen and (max-width: 1023px) {
    .classesnav ul li  {
      text-align: left;
    }
  }

  @media screen and (max-width: 858px){
    ul {
        position: unset !important;
        width: 100%;
        height: unset !important;
        background: unset !important;
        text-align: unset !important;
        transition: unset !important;
    }
}

@media screen and (max-width: 991px) {
  .contentClassList{
    /* text-align: justify; */
    padding-right: 2rem;
  }
  .classesnav{
    display: none;
  }
  .dropdownMobile {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-around;
    background: white;
    flex-direction: column;
    top: 60px;
  }
  .dropdownMobile button {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px;
    border: none;
    background: none;
  }
  .dropdownMobile div {
    background: gainsboro;
    /* margin-right: 17px; */
    text-align: left;
    padding: 20px;
    font-size: 24px;
  }
  .dropdownMobile div a {
  color: black;
  text-decoration: none;
  }

  .releasebody {
    width:100%;
}
}
  
  