.driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt{
    width:175px;
}
.driverregistration-section .col-lg-6.col-sm-12:nth-child(even) .formlabeltxt{
    width:200px;
}
.formlabeltxt {
    font-size: 16px !important;
    font-weight: 500;
  }
  .submitform {
    align-items: center;
    padding: 14px 48px !important;
    font-size: 17px !important;
  }
  .form-placeholder {
    font-size: 14px !important;
  }

  #invalidcheck3 span a {
    color: #076d8e;
  }
 