
.holiday-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--bg);
  float: right; 
  margin: 20px;
  border: 0; 
  background-size: cover;
  background-repeat: no-repeat;
  
  max-width: 500px; /* Limit maximum width to 500px */
  height: auto; /* Let the height adjust automatically based on content */


}
.holiday-button-container:nth-child(1) {
  --holiday-button-bg: #2C7B80;
  --bg: #ffff;
}

.holiday-button {
  --width: 300px;
  --height: 50px;
  border: 0;
  position: relative;
  min-width: var(--width);
  min-height: var(--height);
  border-radius: var(--height);
  color: #fff;
  font-family: "Poppins" !important;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  color: white;
  text-align: center;
  font-size: 25px;
}
.holiday-button .text,
.holiday-button .icon-container {
  position: relative;
  z-index: 2;
}
.holiday-button .icon-container {
  --icon-size: 25px;
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  transition: transform 500ms ease;
}
.holiday-button .icon-container .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--icon-size);
  height: var(--icon-size);
  transition: transform 500ms ease, opacity 250ms ease;
}
.holiday-button .icon-container .icon--left {
  transform: translateX(-200%);
  opacity: 0;
}
.holiday-button .icon-container .icon svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}
.holiday-button::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--holiday-button-bg);
  border-radius: var(--height);
  z-index: 1;
  transition: transform 500ms ease;
}
.holiday-button:hover::after {
  transform: translateX(80%);
}

.holiday-button:hover .icon-container .icon--left {
    margin-left: 20px;
  transform: translateX(0);
  opacity: 1;
}
.holiday-button:hover .icon-container .icon--right {
  transform: translateX(200%);
  opacity: 0;
}

.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}
.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  backface-visibility: hidden;
  transition: all 150ms ease;
}
.support a:hover {
  transform: scale(1.1);
}