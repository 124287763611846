/* #exampleModalToggle{
    z-index: 1200 !important; 
    height: auto;
    
} */
.Resource__modal{
    top : 23% ;
    z-index: 1200;
    height: auto;
    /* width: 600px; */
}
.Resource__form__select{
    width: 95% ;
}
@media (max-width: 1000px){
.Resource__contactusdiv{
    width: 100%;
}

.Resource__contactusdiv .text-danger {
    color: #dc3545!important;
    font-size: 12px!important;
    text-align: left!important;
    padding: 2px!important;
    width: 95% !important;
}
     
}

.modal-backdrop {
    z-index: 100 !important;
}

.floating-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2c7b80;
  color: white;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  text-align: center;
  z-index: 1000; /* Ensure it's on top of other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  writing-mode: vertical-rl; /* Rotate the text vertically */
  text-orientation: mixed;
}

.floating-button a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  display: block;
  transform: rotate(180deg); /* Rotate the text to make it readable */
}

.floating-button:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.5); /* Add shadow on hover */
  background-color: #0160a1; /* Change background color on hover */
  color: #ffffff !important; /* Change text color to red on hover */
}