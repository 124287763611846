@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


@font-face {
  font-family: "DigitalFont";
  src: url("../src/assets/fonts/DigitalFont.ttf");
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/*
@font-face {
  font-family: "OpenSans";
  src: url("../src/assets/fonts/open_sans_regular.ttf");
} */

body {
  overflow-x: hidden;
  font-family: "Poppins" !important;
  /* font-family: "OpenSans" !important; */
}

body.noscrolldiv {
  overflow: hidden !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  z-index: 1;
}
.react-datepicker-wrapper .react-datepicker__input-container input:focus {
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
}
button,
a,
input,
textarea {
  outline: none !important;
  box-shadow: none !important;
}
select {
  word-wrap: normal;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  background: transparent;
}
.react-datepicker__month-container,
.react-datepicker-popper {
  background: #fff;
}
.react-datepicker-popper {
  z-index: 2 !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 600 !important;
}
.w-80 {
  width: 80px;
}
.versionTxtDivAdmin {
  font-size: 14px;
  color: #666;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
}
.versionTxtDiv {
  font-size: 12px;
  color: #000000;
  display: block;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
}
/* ---------------colors--------------------- */
.darkblue {
  color: #076d8e;
}
/* ---------------colors--------------------- */

.w-95 {
  width: 95%;
}
.h-90 {
  height: 90%;
}
.f-14 {
  font-size: 14px;
}
.f-head {
  font-size: 2.188rem;
}
.p-0 {
  padding: 0px;
}
p {
  margin: 0px;
}
.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
  --cui-gutter-x: 0 !important;
  --cui-gutter-y: 0 !important;
}
.homenav {
  font-size: 15px;
  color: black;
  font-weight: 500;
}

header {
  position: relative;
  background-color: black;
  height: 85vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block !important;
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  opacity: 0.5;
  z-index: 1;
}

.resources-tolls h2 {
  margin-left: 12px;
}

@media (pointer: coarse) and (hover: none) {
  header {
    background: url("../src/assets/ship_bg.jpg") black no-repeat center center
      scroll;
    /*background: url("https://source.unsplash.com/XT5OInaElMw/1600x900") black
      no-repeat center center scroll;*/
  }
  header video {
    display: none;
  }
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

label.logo {
  color: white;
  font-size: 35px;
  line-height: 80px;
  padding: 0 100px;
  font-weight: bold;
}

nav ul {
  float: right;
  margin-right: 20px;
}

nav ul li {
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}

nav ul li a {
  color: white;
  font-size: 15px;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
}
.nav-link.text-dark {
  color: #000 !important;
}

a.active,
a:hover {
  color: #076d8e !important;
  text-decoration: none;
  transition: 0.5s;
}

#check {
  display: none;
}

@media (max-width: 952px) {
  label.logo {
    font-size: 30px;
    padding-left: 50px;
  }

  nav ul li a {
    font-size: 16px;
  }
  .navhide {
    display: none;
  }
}

header {
  position: relative;
  z-index: 1999;
}

navbar ul li {
  min-width: 170px;
}

navbar ul li:active {
  text-decoration: underline;
}
/* h2{
 text-align:center;
 text-decoration: underline;
} */

/* footer styles */

.footer-container {
  background-color: #034973;
}

.footer-list {
  padding: 0;
}

.footer-hr-border {
  color: #2C7B80;
}
/*Tools styles*/
.tools-text-center {
  max-width: 13.375rem;
  margin: 0 auto;
  /* width: 50px; */
  min-height: 100px;
}

.tools-background-image {
  /* background-image: url("../src/assets/tools-service-images.png"); */
  background-color: #131F28;
  background-size: cover;
  background-position: center;
  height: auto;
  min-height: 36rem;
}

/* find us */
.findus-row {
  --bs-gutter-x: 0rem !important;
}

.faq-num {
  font-size: 6.25rem;
}
.faq-num {
  color: rgba(35, 35, 35, 0.1);
  font-weight: bold;
  font-family: 72;
}

.faq-head {
  font-weight: bold;
}

.faq-text {
  font-family: Roboto;
  font-size: 0.688rem;
  font-weight: normal;
  max-width: 18.219rem;
}

.viewall-clr {
  font-size: 0.938rem;
  color: #0d4466;
  font-weight: 800;
}

.newsupdateimg {
  width: 210px;
  height: 190px;
}

#card-all {
  border-radius: 20px !important;
  border: 0px;
  margin: 0 20px 10px auto;
  margin-right: 20px;
  padding: 10px;
}

.marine-card-icon {
  width: 6.25rem;
  height: 4.063rem;
  position: absolute;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  left: 0;
  right: 0;
  bottom: 105px;
}

.hide {
  display: none;
}

.f-mobile {
  font-size: 1.875rem;
}

.news-vid-border {
  border: 8px solid #3e3e3e;
}

.tools-services {
  height: 26.875rem;
}

.tools-row1 {
  height: 240px;
  margin-top: 0 !important;
}

@media (max-width: 567px) {
  .tools-row1 {
    height: 600px;
  }
}

.navbar-nav a {
  /* padding-left: 3.5rem !important;
  padding-right: 3.5rem !important; */
  font-size: 15px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fixed-top {
  top: -40px;
  transform: translateY(40px);
  transition: transform 0.3s;
}
div .footer-links a:hover {
  color: #00e6ff !important;
}
div.fixed {
  position: fixed;
  top: 0px;
  z-index: 2006;
  width: 100%;
}

/* TODO HIDING THE TOP BAR  TODO LOGIN
/* top: 1.5rem !important; */
.nav-fixed {
  position: fixed !important;
  z-index: 1100 !important;
  background-color: white !important;
  color: black;

}
.nav-txt-color {
  color: black !important;
}

.nav-txt-color:active {
  color: blue !important;
}

.div#navbarNavAltMarkup {
  position: absolute;
  top: -329px;
  right: 25px;
  background: white;
}
/* ---------------------news and updates styles start---------------------- */

.news-date {
  font-size: 12px;
  color: #0d4466;
  margin-bottom: 4px;
}
/* .news-head {
  font-size: 15px;
  font-weight: bold;
} */
.news-text {
  font-size: 11px;
}
.home-tools-section .newsInnerBoxDiv .imgDivBox{
  height: 200px;
  margin-bottom: 10px;
}
.newsupdate-sec-img {
  width: 95%; 
  height: 95%;
  object-fit: cover;
  object-position: center;
}

.newsupdate-bg {
  background-color: #1e1e1e;
}

.news-video-div {
  border: 10px solid #3e3e3e;
  position: relative;
}

.news-sec1 {
  padding: 20px 10px;
}

.newsvid {
  width: 100%;
}

.newsupdatebgimage {
  background-image: url("./assets/download-image.png");
  background-repeat: no-repeat, repeat;
  background-position: bottom;
  background-size: cover;
  height: 100%;
}

.mobile-app-head {
  color: white;
}

.downloadmobileimage {
  width: 100%;
  height: auto;
}

.appstoreicon {
  width: 60%;
  height: auto;
}

/* ---------------------news and updates styles end---------------------- */

/* ---------------------faqs styles start---------------------- */
.faqs-num {
  /* font-size: 6.25rem;
  font-weight: bold;
  color: rgba(35, 35, 35, 0.1);
  margin-top: -35px; */
  font-size: 120px;
  font-weight: 700;
  color: rgba(35, 35, 35, 0.1);
  margin-top: 0px;
  display: inline-block;
  font-family: serif;
  letter-spacing: -4px;
  line-height: 100px;
}

.faqs-head {
  font-size: 17px;
  font-weight: 600;
}

.faqs-txt {
  font-size: 0.688rem;
  font-weight: normal;
  color: #232323;
  /* max-width: 18.156rem; */
}
/* ---------------------faqs styles end---------------------- */

/* ---------------------findus styles start---------------------- */
.center-head-underline h1 {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.center-head-underline h1:after {
  background-color: #000;
    bottom: -10px;
    content: "";
    height: 0.125rem;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    width: 100%;
}

.formemail {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.findus-map {
  padding-right: 0px !important;
  border-right: 1px solid #c3c3c3;
}

.formhead {
  font-size: 1.5rem;
  color: #232323;
  font-weight: 600;
}
.tollMapPopUpDiv,
.mapPopup {
  box-shadow: none !important;
}
.form-sec {
  background-color: #fbfbfb;
}
/* ---------------------findus styles end---------------------- */

/* ---------------------footer styles start---------------------- */
.footer-sec {
  background-color: #131F28;
  border-bottom: 1px solid #2C7B80;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-contact-icon {
  padding-right: 15px;
}

div .footer-links {
  color: white;
}
div .footer-links a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
}

.footer-head-underline {
  font-weight: bold;
  display: inline-block;
  padding-bottom: 3px;
  position: relative;
  color: white;
  font-size: 1.375rem;
}
.footer-head-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -10px;
  border-bottom: 3px solid white;
}

.policy-bg {
  background-color: #034973;
}
/* ---------------------findus styles end---------------------- */

/* ---------------------marine styles start---------------------- */
.blogtime {
  font-size: 3.5rem !important;
}
.blog-time1 {
  color: #36ab0d;
}
.blog-time2 {
  color: #dda40a;
}
.blog-time3 {
  color: #f03434;
}
.blog-time4 {
  color: #f03434;
}

.time-underline .blogmin {
  font-size: 1.0rem;
}
#card_body {
  position: relative;
  border: 1px dashed #ddd;
  border-top: none;
  border-radius: 0 0 20px 20px;
  padding: 10px;
  box-shadow: 1px 10px 10px 0px #dcdcdc;
}

.powerImgFooterDivNew {
  width: 150px;
  filter: invert(0%) brightness(0);
  vertical-align: text-bottom;
}
/* #card_body {
  position: relative;
  border: 1px dashed #dddddd;
  border-top: none;
  border-radius: 0px 0px 20px 20px;
  padding: 10px;
} */

.time-underline {
  display: inline;
  border-bottom: 2px solid black;
}

.blogImg {
  height: 180px;
  width: 100%;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  box-shadow: 0 -4px 7px 0px #ddd;
  object-fit: cover;
  object-position: top center;
}
.marineimg-div .imgDivBox {
  height: 180px;
  display: block;
}

/* .blogImg {
  width: 100%;
  height: auto;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
} */
/* ---------------------marine styles end---------------------- */

/* ---------------------navbar start---------------------- */
/* .nav-mobile {
  display: flex;
  justify-content: flex-end !important;
} */

.navcustomclass {
  background: white;
  max-height: 130px;
  position: relative;
  top: -110px;
  z-index: 2000;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
  /* margin-top: 10px; */
}

.portlink-div {
  width: -webkit-fill-available;
  /* width: 200px;
  float: right; */
}

.resources-div {
  width: 250px;
  float: right;
}
.legalnotice-div {
  width: 210px;
  float: right;
}

.footer-links:nth-child(2) .footer-contact-icon {
  vertical-align: top;
  margin-top: 5px;
}

.nav-fixed .customization_text2,
.customization_text2 {
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  top: 0;
  display: -webkit-flex;
  display: flex;
  z-index: 2001;
  -webkit-align-items: center;
  align-items: center;
  height: 130px;
  box-shadow: 0 0 0;
  margin-bottom: -36px;
  width: 150px;
}

/* .blogImg {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px!important;
    border-top-right-radius: 20px!important;
    object-fit: contain;
    box-shadow: 0 -4px 7px 0px #ddd;
} */

.nav-fixed .customization_text2 img,
.customization_text2 img {
  width: 125px;
  height: auto;
  margin-top: 0px;
}
.news-head {
  font-size: 16px;
  font-weight: 700;
  width: 300px;
  line-height: 24px;
  height: auto;
  margin: 0;
  margin-bottom: 15px;
}

.txt-overflow {
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 300px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.faqs-section {
  margin-top: 50px;
}
.faqs-section .col-lg-8.col-xl-9 {
  padding-left: 10px;
}
.faqs-section .divBoxNumber {
  width: 140px;
  padding: 0px;
}
.findus-map .mapIframe {
  display: block;
  height: 520px !important;
}
.contactusDiv p.text-danger {
  margin: 0;
}
.contactusDiv .form-control {
  width: 95%;
}
.contactusDiv .leftDivInput {
  width: 48%;
  float: left;
  margin: 15px 0px 0 15px;
}
.contactusDiv .rightDivInput {
  width: 48%;
  float: right;
  margin: 15px 0px 0px -5px;
}
.footer-links .footer-contact-icon {
  vertical-align: top;
  margin-top: 2px;
}
.footer-links .addressTxt {
  width: 80%;
}
.footer-links span {
  display: inline-block;
  text-align: left;
  width: 80%;
}
.marineterminal-home a .marineimg-div {
  position: relative;
}
.marineterminal-home a .marineimg-div {
  position: relative;
}
.marineterminal-home a:hover .marineimg-div:before {
  background-color: rgba(14, 159, 208, 0.77);
  content: "";
  display: block;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.marine-card-icon {
  z-index: 9;
}

.container.nav-mobile.d-flex.justify-content-between {
  margin-top: 10px !important;
}

/*.modules_rectangle:nth-child(2n){
                  background-color: red;
                }*/

a.active {
  display: inline-block;
  position: relative;
}

a.active::after {
  content: "";
  height: 0.125rem;
  width: 100%;
  background-color: #076d8e;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
.center-head-underline-clr h2:after {
  background-color: #117293 !important;
  border-bottom: 2px solid #117293 !important;
}
.selectUserTypeMainDiv a,
.driverregistration-section a,
a .text-primary {
  color: #117293 !important;
}
a.decoration_none {
  text-decoration: none !important;
}

/* ---------------------navbar end---------------------- */
/* ---------------------search navbar start---------------------- */
.searchicon {
  font-size: 10px;
  color: white;
}

.top-nav {
  height: 2.5rem;
}

/* ---------------------search navbar end---------------------- */
/* --------------------- Aboutus start---------------------- */

.aboutus_oakland_underline h2 {
  display: inline-block;
  position: relative;
}

.aboutus_oakland_underline h2:after {
  content: "";
  height: 0.125rem;
  width: 30%;
  background-color: black;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
}

.modules-col {
  min-width: 13.375rem;
}

.modules-col2 {
  background-color: #fdfdfd;
}

.marine-head {
  background-color: #fafafa;
  width: 95%;
  height: 13.375rem;
  padding-left: 20px;
}
/* --------------------- Aboutus end---------------------- */
.modules_head {
  width: max-content;
  margin: 0 4.813rem 0.625rem 0.094rem;
  font-size: 1.438rem;
  padding-bottom:5px;
  text-align: left;
  color: var(--black);
  border-bottom: 2px solid black;
}

.modules_rectangle {
  /* height: 21.375rem; */
  padding: 1.719rem 0.906rem 3.469rem 1.188rem;
  background-color: #fdfdfd;
}
.modules_rectangle .modules_first {
  padding: 0 40px 0 20px;
}
.modules_rectangle .modules_first .modules_para {
  width: 90%;
}
.modules_rectangle:nth-child(even) {
  background-color: #fafafa;
}

/* vessel operations css  start */
.vessel_img {
  width: 100%;
  height: auto;
  /* max-height: 31.25rem; */
  margin: 3.594rem 0 0;
}
.vo-bg {
  background-color: #f4f4f4;
}
.vo-menu {
  color: #117293;
  font-size: 16px;
  padding-left: 10px;
  margin: 0;
}
.railways-cross-section .railalert_radius .border-grey {
  padding: 10px;
}

.vessel-container {
  max-width: 1430px;
}

.vo-headtxt {
  font-size: 26px;
}

.vo-card {
  background-color: #2C7B80;
}
.nav-tabs a {
  color: #333;
}
.vo-bg a {
  color: #0160a1 !important;
  text-decoration: underline;
}
.vo-nameanddate {
  color: #0160a1;
  font-size: 18px;
}
.border-btm {
  border-bottom: dashed 2px #707070;
}

.read-more {
  position: absolute;
  bottom: 8px;
  right: 10px;
  z-index: 1;
}

/* ---------------------Driver Registration Start---------------------- */
/* ---------------------Driver Registration end---------------------- */
.f-15 {
  font-size: 15px;
}

/* TODO HIDING BLACK TOP    display: none;
*/
.blacknav {
  background-color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 14px;
 
}
.blacknav a:hover {
  color: #1db9ed !important;
}

.vesselalert {
  background-color: white;
  height: 105px;
  padding-top: 16px;
}
.GREEN {
  height: 25px;
  width: 25px;
  margin-bottom: -53px;
  margin-left: 10px;
  padding-bottom: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.RED {
  height: 25px;
  width: 25px;
  margin-bottom: -53px;
  margin-left: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.GRAY {
  height: 25px;
  width: 25px;
  margin-bottom: -53px;
  margin-left: 10px;
  background-color: #c7c7c7;
  border-radius: 50%;
  display: inline-block;
}

.GREEN,
.GRAY .RED {
  height: 25px;
  width: 25px;
  margin-bottom: -25px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
}

.sty {
  font-size: 23px;
  margin-top: 0px;
  padding-left: 57px;
  font-weight: 600;
}
.sty1 {
  padding-left: 880px;
  padding-top: -7px;
}

.tcolor {
  color: #0668b4;
  font-size: 20px;
  /* font-weight: bold; */
  padding-left: 57px;
  margin-top: -15px;
  padding-right: 10px;
}

.crossingborder {
  border: 1px solid grey;
}

.crossingsty {
  background-color: white;
  padding-top: 0;
  padding: 15px;
  /* height: 125px; */
}

.railalert_radius div:first-child {
  border-radius: 15px 15px 0px 0px;
}

.railalert_radius div:last-child {
  border-radius: 0px 0px 15px 15px;
}

.sty2 {
  font-size: 23px;
  margin-top: 0px;
  padding-left: 6px;
}
.sty3 {
  float: right;
  margin-top: 0px;
}
.altcolor {
  color: #0668b4;
  font-size: 23px;
  font-weight: bold;
  padding-left: 5px;
}
.railborder div:first-child {
  border-radius: 15px 15px 0px 0px;
}
.railborder div:last-child {
  border-radius: 0px 0px 15px 15px;
}

.custom-border {
  position: relative;
}

.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 1px solid #f0f0f0;
  top: 35%;
  right: 0;
  height: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.lastTableDiv {
  /*  border-radius: 0 0 20px 20px;*/
  border-radius: 0;
  margin-bottom: 50px;
  border-bottom: 2px solid #999;
}
.navbar {
  width: 100%;
}
.zeroBorderRadius {
  border-radius: 0 !important;
}
.borderDashed {
  border-bottom: 1px solid #999;
}
.vo-namecard-head {
  font-size: 22px;
  padding: 20px 0px 20px 60px !important;
  background-color: #4e4e4e;
}
.vessel_estimated {
  border-radius: 20px 20px 0 0;
  font-size: 25px;
}
.vessel_estimated h2 {
  font-size: 25px;
  color: #FFFFFF;
}

.rowDivData {
  color: #0160a1;
  font-size: 18px;
  border-left: 2px solid #999;
  border-right: 2px solid #999;
  padding: 10px 0 10px 60px !important;
}
.vessel,
.vessel_estimated {
  background: #2C7B80;
}

.center-head-underline-clr h2 {
  display: inline-block;
  position: relative;
  width: 100%;
}

.center-head-underline-clr h2:after {
  content: "";
  height: 4px;
  width: 100%;
  background-color: black;
  position: absolute;
  bottom: -20%;
  left: 0% !important;
  transform: translate(0%);
  /*content: "";
  height: 0.125rem;
  width: 50%;
  background-color: black;
  position: absolute;
  bottom: -20%;
  left: 60%;
  transform: translate(-50%);*/
}
.titleTabOption h2.text-dark {
  color: #666 !important;
}
.titleTabOption .vesselOptDiv span img {
  margin-top: -5px;
}
.titleTabOption h2:after {
  content: "";
  height: 4px;
  width: 100%;
  background-color: #999;
  position: absolute;
  bottom: -20%;
  left: 0%;
  transform: translate(0%);
}
.height-row {
  height: 47px;
}
/* marine treminals CSS */

.terminal {
  background-color: #034973;
  font-size: 20px;
}
.marine {
  background-color: #ebebeb;
  /* height:50px; */
  font-size: 20px;
}
.merine {
  border-radius: 100%;
}

.circle {
  display: flex !important;
  height: 167px;
  width: 166px;
  margin-bottom: -37px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  /* vertical-align:middle;  */
  /* padding: auto; */
}
.circle1 {
  position: relative;
  top: 42px;
  left: 19px;
}
.half-circle {
  width: 200px;
  height: 100px; /* as the half of the width */
  padding-top: 10px;

  border-top-left-radius: 100px; /* 100px of height + 10px of border */
  border-top-right-radius: 100px; /* 100px of height + 10px of border */
  border: 5px solid #ffba00;
  border-bottom: 0;
  border-image: linear-gradient(red, blue, green) border-half-circle;
}
.halfcircle-1 {
  position: relative;
  /* top: -101px; */
}

.line1 {
  color: grey;
  margin-top: 10px;
  height: 1px;
  width: 219px;
}
.bhr_width {
  max-width: 219px;
  height: 2px !important;
}

.card-adjust1 {
  padding: 30.5px 28px 27.5px 30.5px !important;
}
.card-adjust3 {
  justify-content: space-between;
}

/* New Styles */

.mt-20 {
  margin-top: 30vh;
}
.gatehours-td {
  text-align: center;
}
.gatehours-th {
  text-align: center;
  background-color: #3e3e3e !important;
  color: #fff;
}
.tborder {
  border-radius: 50%;
}

.teriminal-color {
  background-color: #4d4d4d;
}

.pie {
  margin: 0 auto;
  position: relative;
  width: 200px;
  height: 100px;
  border-radius: 200px 200px 0 0;
  overflow: hidden;
}
.pie::after {
  transform: rotate(-60deg); /*  set rotation degree  */
  background: linear-gradient(
    to right,
    rgba(228, 232, 7, 1) 0%,
    rgba(0, 218, 156, 1) 100%
  );
  transform-origin: center bottom;
}
.pie::before {
  border: 20px solid grey;
}
.pie .overlay {
  top: 20px; /*  match border width  */
  left: 20px; /*  match border width  */
  width: calc(100% - 40px); /*  match border width times 2  */
  height: calc(200% - 40px); /*  match border width times 2  */
  border-radius: 100%;
  background: white;
  z-index: 1; /*  move it on top of the pseudo elements  */
}
.pie *,
.pie::before,
.pie::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  box-sizing: border-box;
}
.letter {
  font-size: 35px;
}
.letter2 {
  font-size: 20px;
  font-weight: bold;
}

.homeresources-div .resources {
  background-color: #f4f4f4;
  min-height: auto;
}
.homeresources-div .resources a.text-dark {
  padding: 0;
}

.homeresources-div .resources a.text-dark div.listPointDiv{
  padding: 15px 0;
}
.resources {
  background-color: #f4f4f4;
  min-height: 66px;
}
.fsize {
  font-size: 21px;
}
.vesselMapTab {
  border-radius: 20px;
  margin-bottom: 50px;
  border: 1px solid #999;
}
.alertVesselTabDiv {
  border: 2px solid #dee2e6;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 !important;
  margin-bottom: 50px;
}
.vesselalert:nth-last-child(1) {
  border-bottom: 0px;
}
.vesselalert {
  display: inline-block;
  height: auto;
  padding: 35px 20px 20px 20px;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #dee2e6;
}
.vesselalert p {
  margin-bottom: 0;
  padding: 0;
}

.vesselalert p.sty1 {
  color: #333;
  text-align: right;
  font-size: 18px;
  padding: 0 !important;
}

.col-md-12.formemail {
  padding: 10px 0px 10px 15px !important;
}
.textareaDivContactus {
  padding: 0px 0px 0px 15px;
  margin: 0 !important;
}
.aboutus_oakland_underline h2,
.aboutus-underline h2 {
  font-size: 32px;
  text-transform: uppercase;
}
.aboutus-underline {
  margin-bottom: 20px;
}
.vesselestimationdiv .vessel_estimated {
  border-top: 1px solid #999;
  border-radius: 0px !important;
  /*border-radius: 20px 20px 0px 0px !important;*/
}

.innerVesselDiv .row {
  padding-left: 10px !important;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
}

.hoverToolDescp {
  display: none;
}
.desktopToolDescp {
  display: block;
}

.marineterminal-nav-section .marine p,
.marineterminal-nav-section .terminal p {
  margin-bottom: 0px;
}

.marineterminal-nav-section .marine,
.marineterminal-nav-section .terminal {
  padding: 0 0 10px 0;
  font-size: 20px;
  font-weight: 700;
}

.marineterminal-nav-section .marine:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.marineterminal-nav-section .marine:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

 

.currentWaitingTimeDiv .tabOptionCWT {
  margin-right: 10px;
  font-size: 18px;
}

.marine-container .marine-card {
  width: 90%;
  margin-bottom: 25px !important;
  cursor: pointer;
}

.terminal-address-section .terminalAddressDiv p {
  margin-bottom: 0px !important;
}

.table-responsive-md thead th {
  width: 50%;
  font-size: 20px;
  line-height: 100%;
  vertical-align: middle;
}

.table-responsive-md thead th:first-child {
  border-top-left-radius: 10px;
}
.table-responsive-md thead th:last-child {
  border-top-right-radius: 10px;
}
.table-responsive-md tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.table-responsive-md tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.table-responsive-md tbody td {
  width: 50%;
  font-size: 16px;

  line-height: 100%;
  vertical-align: middle;
}

.contactDetailsDiv div {
  text-align: center;
  margin-bottom: 100px;
}
.contactDetailsFAQ div {
  text-align: center;
  margin: 25px;
}
.navmob {
  display: inline-block !important;
  width: 100%;
  margin: auto;
}
.navNewMenuDiv {
  display: inline-block !important;
  width: 100%;
  text-align: center;
  margin: auto;
}
.navNewMenuDiv .navNewMenuListDiv {
  float: none;
  margin: 0 auto;
  margin-top: -175px;
  height: 275px;
  width: 100%;
}
.navNewMenuDiv .navNewMenuListDiv li {
  line-height: 25px;
  width: 11.5%;
  text-align: center;
}
.navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
  width: 19%;
}
.navNewMenuDiv .navNewMenuListDiv li:last-child {
  width: auto;
}

.navNewMenuDiv .navNewMenuListDiv li .customization_text {
  bottom: -40px;
}
.nav-fixed .navNewMenuDiv .customization_text2 img {
  width: 130px;
  height: auto;
  margin-top: 10px;
}
.nav-fixed .navNewMenuDiv .navNewMenuListDiv {
  float: none;
  margin: 0 auto;
  margin-top: 20px;
  height: 60px;
  width: 100%;
  position: relative;
}
.nav-fixed .navNewMenuDiv .navNewMenuListDiv li {
  line-height: 25px;
  width: 13%;
  text-align: center;
  padding-top: 0;
}
.nav-fixed .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
  width: 135px;
  margin: 0 35px 0 25px;
}

.nav-fixed .navNewMenuDiv .customization_text2 {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  top: 0;
  display: flex;
  z-index: 2001;
  align-items: center;
  height: 140px;
  box-shadow: none;
  margin-bottom: 0;
  width: 160px;
  bottom: 0px;
  margin-top: -30px;
}
.navmob.navNewMenuDiv .customization_text2 {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  top: 0;
  display: flex;
  z-index: 2001;
  align-items: center;
  height: 140px;
  box-shadow: none;
  margin-bottom: 0;
  width: 160px;
  bottom: 0px;
  margin-top: -30px;
}
.nav-fixed .navNewMenuDiv .navNewMenuListDiv li:last-child {
  width: 15%;
  text-align: center;
  /*text-align: right;*/
}
.navNewMenuDiv .navNewMenuListDiv {
  display: block;
  padding: 0;
}

.navNewMenuDiv .navNewMenuListDiv li:last-child {
  text-align: center;
  /*text-align: right;*/
}

.nav-fixed .navNewMenuDiv.navmob .navNewMenuListDiv li:nth-child(4) {
  width: 135px;
  margin: 0 35px 0 0px;
}

.loginsection .form-section form .forgotBtn {
  margin: 10px 0;
}

a {
  color: #0160a1;
  text-decoration: none !important;
}

.modalcustom {
  position: relative !important;
}

.modal-backdrop {
  z-index: 1100 !important;
}

.modalcustom {
  z-index: 1101 !important;
}
.navNewMenuDiv .navNewMenuListDiv li {
  padding-top: 20px;
}
.customization_text {
  position: relative;
  background: white;
  border-radius: 50%;
  display: flex;
  z-index: 2001;
  bottom: 60px;
  height: 210px;
  width: 230px;
  align-items: center;
  justify-content: center;
}

.customization_text img {
  width: 190px;
  height: auto;
  margin-top: 25px;
}
.topNoticeHeaderMain {
  position: relative;
  min-height: 50px;
}
.marineterminal-head-section .lastUpdatedTxt {
  /* position: absolute; */
  top: 15px;
  font-size: 20px;
  text-align: right;
}
.marineterminal-head-section .terminal-name {
  font-size: 30px;
  padding: 20px 0 0 0;
}
.marineterminal-head-section .terminalImgDiv img {
  width: 250px;
}

.currentWaitingTimeDiv .marine-container .mainCardDiv {
  width: 25%;
}
.currentWaitingTimeDiv .marine-container .mainCardDiv .location-icon:hover {
  filter: invert(50%);
}
.tooldivTwo .desktopToolDescp {
  padding-bottom: 0 !important;
}
.tools-section .tools-background-image {
  min-height:560px;
  padding-top: 10px !important;
}
/* .tools-section .tools-background-image {
  min-height: 690px;
  padding-top: 70px !important;
} */
.toolsandservicelogo.transition__img {
  margin-top: -4px;
}

.driverregistration-section h2 {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.driverregistration-section h2:after {
  content: "";
  height: 0.125rem;
  width: 30%;
  background-color: black;
  position: absolute;
  bottom: -10px;
  left: 0%;
  transform: translate(0%);
}

.underContPageDiv {
  display: block;
  position: relative;
  margin-top: 15%;
  margin-bottom: 5%;
  text-align: center;
}
.underContPageDiv .underContMainDiv img {
  width: 600px;
}

.marine-gallery-img {
  height: 250px;
  /* border-radius: ; */
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
  object-position: center;
}
.banner_img {
  width: 100%;
  height: 600px;
  margin: 0.188rem 0 0;
}

.confirmPwdErrorDiv.text-center {
  width: 95% !important;
  text-align: left !important;
  display: block !important;
  padding-left: 180px;
  margin-top: -15px;
}

.driverRegistrationForm.driverregistration-section
  .col-lg-6.col-sm-12
  .formlabeltxt,
.driverRegistrationForm.driverregistration-section
  .col-lg-6.col-sm-12
  .form-control {
  font-size: 18px !important;
}
.driverRegistrationForm.driverregistration-section
  .col-lg-6.col-sm-12
  .formlabeltxt.companyNameLabelTxt {
  display: flex;
  align-items: center;
  float: left;
  height: 50px;
}

.driverregistration-section .mobileOTPDiv .mobileInputDiv {
  border-radius: 5px 0 0 5px !important;
}
.driverregistration-section .input-group input {
  width: 100%;
  border-radius: 5px !important;
}
.driverregistration-section .input-group select {
  width: 100%;
  display: inline-block;
  border-radius: 5px !important ;
  border: solid 1px #c0c0c0;
  padding: 8px 10px;
  color: #000;
  font-size: 16px;
}
.custom-modal.driverregistration-popup {
  top: 30vh !important;
}
.custom-modal {
  width: 30rem !important;
}

.btn-primary,
.btn-primary:hover {
  color: #fff !important;
  background-color: #0d58b4 !important;
  border-color: #0d58b4 !important;
}

.aboutus-section .aboutus-underline {
  margin-top: 10px;
}
.loginsection .text-danger {
  font-size: 12px;
}
.loginsection .emaildiv .toggle-password {
  top: 0;
  margin-top: 10px;
}

.driverregistration-popup {
  padding: 0px !important;
  border-radius: 20px 20px 20px 20px !important;
}

.driverregistration-popup-head {
  border-radius: 20px 20px 0px 0px;
}

.driverregistration-popup {
  padding: 0px !important;
  border-radius: 20px 20px 20px 20px !important;
}

.driverregistration-popup-head {
  border-radius: 20px 20px 0px 0px;
}

.driverregistration-popup.cameraModalDiv {
  padding: 0px !important;
  border-radius: 20px 20px 20px 20px !important;
  display: block;
  height: auto;
  left: 50%;
  transform: translate(-50%);
  width: 580px;
  height: 500px;
  top: 35vh !important;
}

.driverregistration-popup.cameraModalDiv .driverregistration-popup-head {
  border-radius: 20px 20px 0 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding: 0px !important;
  display: block;
}
.driverregistration-popup.cameraModalDiv .innerImgDiv {
  display: inline-block;
  height: 300px;
  width: 100%;
}
.driverregistration-popup.cameraModalDiv .imgCameraBig {
  width: 100%;
  border-radius: 20px 20px 0 0;
  object-fit: fill;
  height: 100%;
}
.driverregistration-popup.cameraModalDiv button {
  margin: 10px auto !important;
  text-transform: uppercase;
  font-weight: 700;
}
.vesselMainPage {
  padding-right: 0;
  padding-left: 0;
}

.vesselMainPage .container-fluid {
  padding: 0;
  margin: 0;
}
.row > * {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.titleTabOption h2 b {
  padding-top: 5px;
  display: inline-block;
  font-weight: normal;
  font-size: 22px;
}
.center-underline h2,
.titleTabOption h2 {
  padding: 0 30px 0 0;
}

.truckRouteDiv h2,
.osowInfoDiv h2 {
  text-align: center;
  padding: 10px 0 40px 0;
  font-family: "Poppins";
  /* font-family: OpenSans; */
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  position: relative;
}
.osowInfoDiv h2 span {
  display: block;
  font-size: 38px;
}
.truckRouteDiv h2:after {
  content: "";
  height: 0.125rem;
  width: 30%;
  background-color: black;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}
.osowInfoDiv h2::after {
  content: "";
  height: 0.125rem;
  width: 20%;
  background-color: black;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}
.vesselMapTab.osowInfoDiv {
  width: 100% !important;
}
.osowInfoInnerDiv {
  border-radius: 20px;
  display: inline-block;
  width: 100%;
  border: 1px solid #999;
  margin-bottom: 0px;
}
.truckRouteDiv .truckRouteInnerDiv {
  border: 0;
}
.osowInfoInnerDiv .resources:first-child {
  /*border-radius: 20px 20px 0 0;*/
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.osowInfoInnerDiv .resources:last-child {
  /*border-radius: 0 0 20px 20px;*/
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
} 
.truckRouteInnerDiv,
.trafficCameraInnerDiv {
  border-radius: 20px;
  display: inline-block;
  width: 100%;
  border: 1px solid #999;
  margin-bottom: 50px;
}
.trafficCameraInnerDiv iframe,
.truckRouteInnerDiv iframe {
  border-radius: 20px;
  display: inline-block;
  width: 100%;
}
.truckRouteInnerDiv iframe {
  height: 625px;
  display: block;
}

/* Bootstrap 4 text input with search icon */

.trafficCameraFindDiv .formDiv .has-search .form-control {
  padding-left: 60px;
  height: 60px;
  border-radius: 20px;
}
.trafficCameraFindDiv .formDiv .has-search .form-control-feedback img {
  width: 32px;
}
.trafficCameraFindDiv
  .formDiv
  .has-search
  .form-control-feedback
  img.searchIconImg {
  width: 24px;
}
.trafficCameraFindDiv .formDiv .has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding: 10px 15px;
}

.trafficCameraFindDiv {
  background: #fbfafa;
  border-radius: 20px;
  margin: 10px auto;
  text-align: center;
  padding: 20px;
}

.trafficCameraFindDiv .formDiv {
  width: 60%;
  margin: 0 auto;
  display: inline-block;
}

.trafficCameraFindDiv .formDiv .form-group {
  margin: 20px 0;
}
.trafficCameraFindDiv .switchBtnDiv {
  display: inline-block;
  margin: 0 0 0px 40px;
  height: 100%;
  float: none;
  top: -40px;
  position: relative;
}
.trafficCameraFindDiv .switchBtnDiv img {
  width: 42px;
}

.weatherDetailDiv {
  margin-bottom: 50px;
  position: relative;
}
.vesselMapTab.weatherDetailDiv {
  width: 100% !important;
}
.weatherDetailDiv .weatherInnerDiv {
  /*background: url(./assets/weatherBg-image.png);*/
  background: url(./assets/weatherBG1.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  border-radius: 20px;
  position: relative;
}
.weatherDetailDiv .bgOverlay {
  background: #000;
  z-index: 9;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 20px;
}

.weatherDetailDiv .weatherTitleDiv {
  /*padding: 80px 20px 20px 20px;*/
  padding: 20px;
  display: block;
  width: 100%;
  z-index: 99;
  position: relative;
}
.enterLocationInput {
  width: auto;
  display: inline-block;
  float: left;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dcdcdc;
  outline: none;
}
.weatherDetailDiv .weatherTitleDiv h2 {
  display: none !important;
  width: auto;
  display: inline-block;
  float: left;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.weatherDetailDiv .weatherTitleDiv h2 b {
  color: #8c8c8c;
}

.weatherDetailDiv .weatherTitleDiv span {
  width: auto;
  /*float: right;*/
  float: left;
  display: inline-block;
  text-align: center;
}

.weatherDetailDiv .weatherTitleDiv span img {
  width: 60px;
  display: block;
  margin: 0 10px;
  float: left;
  /*width: 40px;
  display: inline-block;
  margin: 0 10px;*/
}
.weatherDetailDiv .weatherTitleDiv span b.timeUpdateTxt {
  display: block;
  font-size: 18px;
}
.weatherDetailDiv .weatherTitleDiv span b {
  color: #fff;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
  text-align: center;
  top: 15px;
  position: relative;
}
.weatherDetailDiv .weatherTempInfoDiv {
  padding: 5px 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 99;
  position: relative;
}
.weatherDetailDiv .weatherTempInfoDiv h1 {
  color: #fff;
  font-size: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
  text-align: left;
  float: left;
  font-weight: 100;
}
.weatherDetailDiv .weatherTempInfoDiv h1 img {
  width: 200px;
  display: inline-block;
  top: -25px;
  margin: 0 0 0 200px;
  position: relative;
}
.weatherDetailDiv .currentWeatherStatus {
  width: 250px;
  float: right;
  display: inline-block;
  padding: 0px 0 0 0;
  z-index: 99;
  position: relative;
}

.weatherDetailDiv .currentWeatherStatus span {
  margin: 10px 0;
  color: #fff;
  display: block;
}
.weatherDetailDiv .currentWeatherStatus span img {
  width: 32px;
  margin-right: 20px;
}
.weatherDetailDiv .currentWeatherStatus span b {
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
}
.weatherDetailDiv .textUpperCase {
  text-transform: uppercase;
}
.weatherDetailDiv .currentWeatherStatus span b.textCapitalize {
  text-transform: capitalize;
}
.todaySunStatus ul {
  list-style: none;
  padding: 0;
  color: #fff;
  text-align: center;
  z-index: 99;
  position: relative;
}

.todaySunStatus ul li {
  display: inline-block;
  position: relative;
  border-right: 3px solid #fff;
  height: 40px;
}
.todaySunStatus ul li:last-child {
  border-right: 0px solid #fff;
}

.todaySunStatus ul li img {
  width: 64px;
  margin: 0 20px;
  top: 0px;
  vertical-align: bottom;
  position: relative;
}
.todaySunStatus ul li b .textUpperCase {
  font-size: 22px;
  font-weight: 500;
}
.todaySunStatus ul li b {
  display: inline-block;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: left;
  margin-right: 20px;
}
.currentTempStatusDiv {
  margin: 15px 0 0 0;
  display: inline-block;
  height: 240px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.currentTempStatusDiv .bgOverlay {
  position: absolute;
  background-color: #000;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  border-radius: 0 0 25px 25px;
  z-index: 2;
}

.currentTempStatusDiv ul {
  list-style: none;
  padding: 0;
  height: 100%;
  display: flex;
  width: 100%;
  color: #fff;
  z-index: 9;
  position: relative;
  margin-left: 5px;
}
.currentTempStatusDiv ul li:last-child {
  border: 0;
}
.currentTempStatusDiv ul li {
  display: inline-block;
  width: 14.1%;
  border-right: 2px solid #fff;
  padding: 15px 20px;
}

.currentTempStatusDiv ul li b {
  display: block;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  padding: 5px 0;
  color: #fff;
}
.currentTempStatusDiv ul li img {
  width: 62px;
  display: block;
  margin: 20px auto;
}
.currentTempStatusDiv ul li span {
  display: block;
}
.currentTempStatusDiv ul li span b {
  display: inline-block;
  width: 50%;
}

.selectUserTypeMainDiv:nth-child(even) {
  margin-right: 0 !important;
}
.selectUserTypeMainDiv {
  width: 42%;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 14.5px 0 rgb(0 0 0 / 13%);
  padding: 30px;
  margin: 30px 0;
  margin-right: 8%;
}

.selectUserTypeMainDiv .driverTitleDiv img {
  width: 85px;
  margin-top: 20px;
}
.selectUserTypeMainDiv .driverTitleDiv h2 {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 20px auto;
  height: 80px;
}
.selectUserTypeMainDiv .driverTitleDiv p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  margin: 22px auto;
  margin-bottom: 20px;
}

.selectUserTypeMainDiv .custom-checkbox {
  margin: 40px auto;
  display: inline-flex;
}
 
.selectUserTypeMainDiv .custom-checkbox label {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  margin-left: 10px;
  width: 90%;
  vertical-align: bottom;
}
.selectUserTypeMainDiv button {
  margin: 10px auto;
  padding: 8px 30px;
  border-radius: 10px;
  /*
  background-color: #bfbfbf;
  color: #fff;
  */
  color: #fff;
  background-color: #0d6efd;
  font-size: 18px;
  border: 0;
  border-radius: 5px;
}
.selectUserTypeMainDiv button:hover {
  background-color: #0d58b4;
}
.alertListShowDiv {
  margin-top: 20px !important;
}
.alertListShowDiv .col-md-12 {
  border-radius: 0 !important;
  padding: 20px !important;
  margin: 10px auto !important;
  width: 98%;
}
.page-item.active .page-link {
  color: #fff !important;
}

.tabOptionCWT .activeTab .terminal-graph {
  background-color: #0d6efd !important;
}
.tabOptionCWT .terminal-graph {
  background-color: #6c757d !important;
}

.speedometerMainDiv svg.speedometer {
  width: 312px !important;
  height: 200px !important;
  text-align: center;
  display: block;
  margin: auto;
}
.speedometerMainDiv .currentwaittime-head {
  text-align: center;
}

.railways-cross-section .railCrossing__fs20 span {
  display: inline-block;
}

.alertVesselTabDiv .vesselalert .sty {
  margin-bottom: 8px;
}
.alertVesselTabDiv .locationAlertTxt {
  display: inline-block;
  float: left;
  font-size: 22px;
}
.mobileOTPDiv .mobileInputDiv {
  width: 80% !important;
  display: inline-block;
  float: left;
  flex: none !important;
}
.mobileOTPDiv button {
  width: 20%;
  border: 0;
  border-radius: 0 5px 5px 0px;
  background: #0d58b4;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.weatherForecastDailyDiv {
  display: block;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #999;
  border-radius: 20px;
}
.weatherForecastDailyDiv h1 {
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: #005495;
}
.weatherForecastDailyDiv h1 img {
  width: 80px;
  position: relative;
  top: -10px;
  margin: 0 20px;
}

.weatherForecastDailyDiv ul {
  list-style: none;
  padding: 0;
}
.weatherForecastDailyDiv ul li {
  display: block;
  box-shadow: 0 0 38.7px 4.3px rgb(0 0 0 / 13%);
  padding: 0 20px;
  margin: 20px 0;
}
.weatherForecastDailyDiv ul li span {
  width: 25%;
  text-align: center;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  text-transform: capitalize;
}
.weatherForecastDailyDiv ul li span .dayTxt {
  display: block;
  font-size: 24px;
}
.weatherForecastDailyDiv ul li span .dateTxt {
  display: block;
  font-size: 18px;
}
 
.weatherForecastDailyDiv ul li span .highTempTxt {
  display: inline-block;
  font-size: 48px;
}
.weatherForecastDailyDiv ul li span .lowTempTxt {
  display: inline-block;
  font-size: 24px;
  color: #666;
}
.weatherForecastDailyDiv ul li span .lowTempTxt i {
  padding: 0 5px;
  position: relative;
  top: 0px;
  font-size: 32px;
}
.weatherForecastDailyDiv ul li span .txtDescp {
  font-size: 32px;
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
}
.weatherInnerDiv .searchInputDiv {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px 10px;
  position: relative;
}
.weatherInnerDiv .searchInputDiv .fa-search {
  left: 10px;
  position: absolute;
  top: 7px;
  font-size: 28px;
  color: #fff;
  z-index: 9;
  margin-left: 5%;
}

.weatherInnerDiv .searchInputDiv input {
  width: 90%;
  border: 1px solid #dcdcdc !important;
  border-radius: 10px;
  padding: 10px;
  background: transparent;
  z-index: 9;
  position: relative;
  box-shadow: 0px 0px 2px #dcdcdc;
  font-size: 20px;
  line-height: 20px;
  padding-left: 50px;
  color: #fff;
  outline: none;
} /*
.vesselMapTab .filterBtn{ 
    position: relative;
    right: -2px;
    margin: 0;
    top: 70px;
    font-size: 20px;
    width: 64px;
    height: 64px;
    border: 0;
    background: transparent;
}
.vesselMapTab .filterOptDiv{
 
  position: absolute;
  right: 0;
  width: 200px;
  background: #fff;
  z-index: 9999;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
  -moz-transform: translate3d(100px, 0px, 0px);
  -webkit-transform: translate3d(100px, 0px, 0px);
  -o-transform: translate(100px, 0px);
  -ms-transform: translate(100px, 0px);
  transform: translate3d(100px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}*/
#drawer {
  position: absolute;
  height: 75%;
  right: 0;
  top: 60px;
  z-index: 99;
  overflow-x: hidden; /* Needed for initial hidden state */
}
#drawer > div {
  height: auto;
  border-left: 2px solid white;
}
#drawer-handle {
  z-index: 99;
  width: auto;
  border: 0;
  background: transparent;
  position: relative;
  top: 2px;
  box-shadow: 0 0px 8px 0px #dcdcdc;
  left: 2px;
  margin-right: 2px;
  display: block;
  float: left;
  border-radius: 10px 0px 0 10px;
}
#drawer-handle img {
  width: 50px;
  border-radius: 10px 0px 0 10px;
}
#drawer #drawer-handle {
  position: relative;
}

#drawer #drawer-handle .imgBtnIcon {
  position: absolute;
  z-index: 9;
}
#drawer.openDiv #drawer-handle .imgBtnIcon {
  position: absolute;
  z-index: 9;
  transform: rotate(180deg);
}
.trafficCameraMapDivTab {
  height: 750px !important;
  margin-bottom: 0;
  border: 0 !important;
}
#drawer-content {
  width: 335px;
  margin-right: -335px; /* -width */
  background-color: #f0f0f0;
  display: inline-block;
  top: 0%;
  position: relative;

  -webkit-animation: slide 10s forwards;
  -webkit-animation-delay: 10s;
  animation: slide 10s forwards;
  animation-delay: 10s;
}

#drawer.openDiv #drawer-content {
  margin-right: 0px;
}
.drawer-wrapper {
  margin: 0px;
  display: inline-block;
  z-index: 9999;
}
.filterMapMainDiv .filterOptDiv .checkbox {
  padding: 15px 10px;
  width: 100%;
  border-bottom: 1px solid #bababa;
  text-transform: capitalize;
  /* font-size: 20px; */
  color: #076d8e;
}
.filterMapMainDiv .filterOptDiv .checkbox input {
  margin: 0 8px;
  width: 30px !important;
  height: 30px;
  position: relative;
  top: 8px;
}
.filterMapMainDiv
  .filterOptDiv
  .checkbox
  input[type="checkbox"]:checked
  + .filterMapMainDiv
  .filterOptDiv
  .checkbox
  i::after {
  color: #fff;
  border-color: #028f3a;
  background-color: #028f3a;
}
.selectedUserBorder {
  box-shadow: 0px 0 5px 1px #0d58b4;
  border: 0 !important;
}

.form-check-input[type="checkbox"] {
  width: 20px !important;
  height: 20px;
  top: -2px !important;
}
.form-check-input:checked[type="checkbox"] {
  background-image: none !important;
}
input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border-radius: 5px;
  background: #fff;
  height: 15px;
  width: 15px !important;
  border: 1px solid #999;
  top: 5px;
  position: relative;
  border-radius: 3px;
}

.settingProfileMain input[type="radio"] {
  position: relative;
  top: 3px;
  margin-right: 5px;
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #8e8e8e;
  background-color: transparent;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
.settingProfileMain input[type="radio"]:checked {
  background-color: #3399ff;
  border: 1px solid #3399ff;
}

.settingProfileMain input[type="checkbox"] {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.settingProfileMain input[type="checkbox"]:checked {
  background: #3399ff;
  border: 0px solid #999;
}
input[type="checkbox"]:checked {
  background: #2aa1c0;
  border: 0px solid #999;
}

input[type="checkbox"]:hover {
  filter: brightness(90%);
}

input[type="checkbox"]:disabled {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

input[type="checkbox"]:after {
  content: "";
  position: relative;
  left: 38%;
  top: 12%;
  width: 32%;
  height: 60%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

.filterMapMainDiv .filterOptDiv button {
  background: #0b669c;
  color: #fff;
  text-align: center;
  padding: 5px 20px;
  display: block;
  margin: 25px auto;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 12px 34.6px 3.4px rgba(6, 84, 138, 0.28);
}

.reportSectionMainDiv .searchFilterDiv {
  background: #fff;
  padding-left: 25px;
  margin: 10px 0;
  text-align: right;
}
.reportSectionMainDiv .searchFilterDiv input {
  width:350px;
  padding: 3px 10px;
  line-height: 30px;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  background-color: #f9f9f9;
}
.reportSectionMainDiv .searchFilterDiv .serachOption {
  text-align: right;
  display: inline-block;
  width: 150px;
  margin-bottom: 50px;
}
.bookedParkingMainDiv .searchFilterDiv .serachOption button:disabled,
.reportSectionMainDiv .searchFilterDiv .serachOption button:disabled {
  background-color: #dedede;
  color: #999;
}
.reportSectionMainDiv .searchFilterDiv .serachOption button {
  border-radius: 5px;
  box-shadow: 0 0 25.4px 1.6px rgb(0 0 0 / 9%);
  background-color: #1c9ae2;
  border: 0;
  color: #fff;
  padding: 7px 10px;
  width: 90%;
  display: inline-block;
  position: relative;
  top: 0px;
}
/*---Truck Turn Time Main---*/
.historicalTTTMainDiv .lastUpdateTitleDiv {
  display: inline-block;
}
.historicalTTTMainDiv .listOptionDiv {
  width: 100% !important;
  display: inline-block;
  text-align: left !important;
}
.historicalTTTMainDiv .listOptionDiv .col-md-6:last-child {
  width: 16%;
}
.historicalTTTMainDiv .listOptionDiv .col-md-6 {
  width: 28%;
  padding-right: 10px !important;
}
.historicalTTTMainDiv .listOptionDiv label {
  display: inline-block;
  width: 100%;
  font-weight: 600;
  margin-bottom: 5px;
}
.historicalTTTMainDiv .listOptionDiv select {
  margin: 0px !important;
  width: 100% !important;
}
.historicalTTTMainDiv .listOptionDiv button {
  margin: auto;
  margin-top: 24px;
  padding: 0px 15px !important;
  display: block;
  float: right;
}
.historicalTTTMainDiv .react-datepicker-wrapper input {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #bfbfbf;
  padding: 7px 4px;
}
.showGraphMainDiv.show {
  display: block !important;
}
.faq-section .mb-0 > a:after {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  font-weight: 600;
}

.faq-section .mb-0 > a[aria-expanded="true"]:after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}
.truckTurnTimeMainDiv .titleDiv {
  position: relative;
}
.truckTurnTimeMainDiv .titleDiv .arrowDDBtn.collapsed {
  background-image: url(./assets/TTTArrow.png);
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.1s;
}
.truckTurnTimeMainDiv .titleDiv .arrowDDBtn {
  background-image: url(./assets/TTT_UpArrow.png);
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  transition: all 0.1s;
}
.truckTurnTimeMainDiv,
.alertsMainDivPage,
.reportTabsMainDiv {
  /* margin-top: 20px; */
  display: flex;
}
.truckTurnTimeMainDiv .titleDescpDiv,
.alertsMainDivPage .titleDescpDiv {
  text-align: center;
  margin: 20px auto;
}
.truckTurnTimeMainDiv .titleDescpDiv h2,
.alertsMainDivPage .titleDescpDiv h2 {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.alertsMainDivPage .titleDescpDiv h2:after,
.truckTurnTimeMainDiv .titleDescpDiv h2:after {
  content: "";
  height: 0.125rem;
  width: 50%;
  background-color: #000;
  position: absolute;
  bottom: -20%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.lastUpdateDiv {
  display: inline-block;
  width: 100%;
  clear: both;
  margin: 20px auto;
}
.lastUpdateDiv h3 {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  width: 50%;
  float: left;
}
.lastUpdateDiv .listOptionDiv {
  width: 50%;
  float: right;
  text-align: right;
}
.lastUpdateDiv .listOptionDiv select {
  width: 300px;
  border-radius: 5px;
  border: solid 1px #bfbfbf;
  padding: 12px 4px;
  margin: 0 10px;
}
.historicalTTTMainDiv .listOptionDiv .react-datepicker-popper button {
  margin-top: 5px;
}
.historicalTTTMainDiv
  .listOptionDiv
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  transform: translate3d(25px, 0px, 0px) !important;
}
.lastUpdateDiv .listOptionDiv button {
  border-radius: 5px;
  width: auto;
  border: 0;
  padding: 2px 12px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: normal;
  text-align: left;
  background: transparent;
  border: solid 1px #bfbfbf;
}
.lastUpdateDiv .listOptionDiv button img {
  width: 24px;
  margin-right: 10px;
}
.truckTurnTimeListOpt {
  margin: 50px 0;
}
.truckTurnTimeListOpt ul {
  list-style: none;
  padding: 0;
}
.truckTurnTimeListOpt ul li {
  display: inline-block;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 50.8px 11.2px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 20px 0;
}

.truckTurnTimeListOpt ul li .titleDiv {
  width: 100%;
  margin-bottom: 20px;
}
.truckTurnTimeListOpt ul li .titleDiv span {
  width: 70%;
}
.truckTurnTimeListOpt ul li .titleDiv span img {
  width: 90px;
  margin: 0 10px;
  vertical-align: top;
}

.truckTurnTimeListOpt ul li .titleDiv span h2 {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
}
.truckTurnTimeListOpt ul li .arrowDDBtn {
  width: 38px;
  float: right;
  margin: 10px;
  vertical-align: top;
}

.truckTurnTimeListOpt ul li .infoListDiv:first-child {
  margin-right: 2%;
  margin-left: 0%;
}

.truckTurnTimeListOpt ul li .infoListDiv:last-child {
  margin-right: 0%;
  margin-left: 2%;
}
.truckTurnTimeListOpt ul li .infoListDiv {
  width: 21%;
  display: inline-block;
  float: left;
  text-align: center;
  margin-right: 2%;
  margin-left: 2%;
}

.truckTurnTimeListOpt .historicalSearchList {
  clear: both;
}
.truckTurnTimeListOpt .historicalSearchList .infoListDiv {
  width: 33%;
  display: inline-block;
  float: left;
  text-align: center;
  margin: 0 auto;
}
.truckTurnTimeListOpt .historicalSearchList .card {
  margin-bottom: 15px;
}
.truckTurnTimeListOpt .historicalSearchList .infoListDiv span {
  background: #d5d0d54a;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  color: #034973;
  display: block;
  width: 85%;
  text-align: center;
  margin: 10px auto;
}
.truckTurnTimeListOpt .historicalSearchList .infoListDiv label {
  display: inline-block;
  margin: 0px auto;
  margin-bottom: 10px;
  width: 95%;
  font-size: 20px;
  font-weight: 600;
}
.truckTurnTimeListOpt .historicalSearchList .infoListDiv.imgLogoDiv {
  margin: 0px auto;
  display: flex;
  width: 150px;
  height: 100%;
  align-items: center;
  text-align: center;
}
.truckTurnTimeListOpt .historicalSearchList .infoListDiv.imgLogoDiv .img-fluid {
  max-width: 100%;
  height: auto;
  width: 150px;
}
.truckTurnTimeListOpt .historicalSearchList .leftImgMainDiv {
  /* height: 100px; */
  width: 100%;
  display: flex;
  float: none;
}
.truckTurnTimeListOpt .historicalSearchList .rightContentMainDiv {
  width: 100%;
  float: right;
}
.truckTurnTimeListOpt ul li .infoListDiv .dynamicTxt {
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 18px;
  color: #000;
  display: block;
  width: 150px;
  text-align: center;
  margin: 10px auto;
}
.txtColorGreen {
  color: #469615 !important;
}
.truckTurnTimeListOpt ul li .infoListDiv .fixedTxt {
  display: inline-block;
  margin: 10px auto;
  width: 80%;
}

.truckTurnTimeListOpt ul li .infoListDiv .fixedTxt b {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  text-align: center;
  padding: 15px 0;
  margin: 0 10px 0 0;
  float: left;
  display: inline-block;
  vertical-align: top;
}
.truckTurnTimeListOpt ul li .infoListDiv .fixedTxt b img {
  width: 32px;
}
.truckTurnTimeListOpt ul li .infoListDiv .fixedTxt label {
  width: 65%;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  padding: 10px 0;
  float: left;
}
.pinkBgColor {
  background-color: #f1f0fe;
}
.blueBgColor {
  background-color: #e7fafd;
}
.redBgColor {
  background-color: #fdefef;
}
.greenBgColor {
  background-color: #eafaf1;
}
.showGraphMainDiv {
  display: none;

  clear: both;
  /*display: inline-block;*/
  border-top: 1px solid #999;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  padding-top: 50px;
}
.showGraphMainDiv .innerGraphDiv {
  width: 50%;
  display: inline-block;
}
.showGraphMainDiv .innerGraphDiv h2 {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.26px;
  text-align: center;
  margin-bottom: 20px;
}
.alertsMainDivPage .card.parentDivCard {
  margin: 5px 0;
}
.alertInnerDivList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.card.alertInnerDiv {
  border: 0 !important;
}
.alertInnerDiv .card-body {
  padding: 0;
}
.alertInnerDivList li:first-child {
  margin-top: 0;
}

.alertInnerDivList li:last-child {
  margin-bottom: 5px;
}
.alertInnerDivList li {
  width: 100%;
  display: inline-block;
  background: #f9f9f9;
  margin: 4px 0;
}
.newAlertVesselDiv li {
  display: flex;
}
.alertInnerDivList li:hover {
  background: #fbfbfb;
}
.alertInnerDivList li:nth-child(even) {
  background: #ececec;
}

.alertInnerDivList li span {
  width: 90%;
  display: inline-block;
  padding: 5px 20px;
}
.alertInnerDivList li span p {
  width: 100%;
  text-align: left;
}
.alertInnerDivList li span label {
  width: 100%;
  text-align: right;
}
.alertInnerDivList li button img {
  width: 32px;
}
.alertInnerDivList li .buttonDiv {
  float: right;
  position: relative;
  display: inline-block;
  width: 10%;
  text-align: center;
}

.alertInnerDivList li .buttonDiv button {
  width: 60px;
  border: 0;
  background: #33b4e9;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alertsMainDivPage #accordion .card-header, .eriDiv .accordion .card-header {
  background-color: #d3d3d3;
  color: #000;
}
.alertsMainDivPage #accordion .card-header h5 a,
.alertsMainDivPage #accordion .card-header h5 a:hover,
.alertsMainDivPage #accordion .card-header h5 a:active {
  padding: 20px 10px;
  display: block;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 28px;
}

.alertsMainDivPage #accordion .alertInnerDiv .card-header h5 a,
.alertsMainDivPage #accordion .alertInnerDiv .card-header h5 a:hover,
.alertsMainDivPage #accordion .alertInnerDiv .card-header h5 a:active {
  font-size: 22px;
  padding: 8px 10px;
}
.alertsMainDivPage .mb-0 > a {
  display: block;
  position: relative;
}
.alertsMainDivPage .mb-0 > a:after {
  content: "\f078"; /* fa-chevron-down */
  font-family: "FontAwesome";
  position: absolute;
  right: 0;
}
.alertsMainDivPage .mb-0 > a[aria-expanded="true"]:after {
  content: "\f077"; /* fa-chevron-up */
}
.reportTabsMainDiv .tab-content .downloadBtn {
  float: right;
  border-radius: 25px;
  background-color: #2a6fc0;
  color: #fff !important;
  text-align: center;
  padding: 5px 20px;
  margin: 20px 0;
  border: 0;
}
.reportTabsMainDiv .tab-content .downloadBtnLeft {
  float: left;
  border-radius: 25px;
  background-color: #2a6fc0;
  color: #fff !important;
  text-align: center;
  padding: 5px 20px;
  margin-bottom: 50px;
  border: 0;
}
.reportTabsMainDiv .nav-tabs .nav-link {
  background-color: #3e3e3e;
  color: #fff !important;
  margin-right: 1px;
}
.reportTabsMainDiv .nav-tabs .nav-link.active {
  background-color: #2a6fc0;
  color: #fff !important;
}
.vesselPerformanceReportDiv table {
  width: 100%;
}
.reportTabsMainDiv .tab-content {
  box-shadow: 0 0 38.7px 4.3px rgba(0, 0, 0, 0.13);
  padding: 50px 30px 100px 30px;
  margin-bottom: 50px;
}
.reportTabsMainDiv .tab-content table thead {
  background: #3e3e3e;
  color: #fff;
}
.reportTabsMainDiv .tab-content table thead td {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  border-right: 1px #fff solid;
}
.reportTabsMainDiv .tab-content table thead td:first-child {
  border-left: 1px solid #000;
}

.reportTabsMainDiv .tab-content table thead td:last-child {
  border-right: 1px solid #000;
}
.reportTabsMainDiv .tab-content table tbody td {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  border: 1px solid #000;
}
.marineterminal-nav-section .gateTab_1 {
  margin: auto;
  width: 30%;
}
.marineterminal-nav-section .gateTab_2 {
  margin: auto;
  width: 50%;
}
.marineterminal-nav-section .gateTab_3 {
  margin: auto;
  width: 80%;
}
.marineterminal-nav-section .gateTab_4 {
  margin: auto;
  width: 100%;
}
.marineterminal-nav-section .gateTab_1 .marine {
  width: 100%;
}
.marineterminal-nav-section .gateTab_2 .marine {
  width: 50%;
}
.marineterminal-nav-section .gateTab_3 .marine {
  width: 33%;
}
.marineterminal-nav-section .gateTab_4 .marine {
  width: 25%;
}
.railcrossing-head .col-4 {
  width: 40%;
}
.railcrossing-head .col-8 {
  width: 60%;
}
.portMessageSignsMain .locationTxt {
  width: 90%;
  font-weight: bold;
  padding: 5px;
}

.portMessageSignsMain .dateTimeTxt {
  font-size: 16px;
  font-weight: 500;
}

.portMessageSignsMain .msgDataDiv {
  background: #000;
  color: #fb4a4a;
  font-family: "DigitalFont";
  font-size: 20px;
  line-height: 22px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 10px auto;
  font-weight: 500;
  letter-spacing: 1px;
}

.popupCaltransCamerasMain {
  height: 100% !important;
  padding: 10px !important;
}
.popupCaltransCameras {
  font-size: 16px;
  line-height: 18px;
  text-align: left;
}
.popupCaltransCameras .dataDescpTxt span {
  display: block;
  width: 100%;
}
.popupCaltransCameras .dataDescpTxt span label {
  display: inline-block;
  margin-right: 25px;
}
.vesselOperationMainDetail .closeBtnPopup {
  border: 0;
}
.popupCaltransCameras .dataDescpTxt .cameraLiveImg {
  display: block;
  margin: 10px auto;
  width: 95%;
  height: auto;
  text-align: center;
}

.popupCaltransCameras .dataDescpTxt .cameraLiveImg img {
  width: 90%;
}

.messageSignMainDiv {
  height: 100% !important;
  padding: 10px !important;
}
.textDespMsgSign .titleTxt {
  font-size: 20px;
  text-transform: uppercase;
  width: 90%;
  display: block;
  margin-bottom: 10px;
}
.textDespMsgSign div {
  background: #000;
  color: rgb(182, 113, 40);
  text-transform: uppercase;
  padding: 10px 20px;
}
.textDespMsgSign span {
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}
.textDespMsgSign p.dateTxt {
  text-align: left;
  font-size: 12px;
  text-transform: capitalize;
}
.textDespMsgSign p.dateTxt label {
  margin-right: 5px;
}
.chainControlMainDiv {
  height: 100% !important;
  padding: 10px !important;
}
.chainControlMainDiv .chainControlDescp .titleTxt {
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  width: 90%;
  display: block;
  margin-bottom: 10px;
}
.chainControlDescp .contentTxt .ccIconImg {
  float: left;
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
  margin: 5px;
}
.chainControlDescp .contentTxt span {
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 5px;
}
.chainControlDescp .dateTxt {
  text-align: left;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 5px;
}
.chainControlDescp .dateTxt label {
  margin-right: 5px;
}

.gatehours-section .gatehours-td,
.gateinformation-section .gatehours-td {
  text-transform: uppercase;
}

 

.lmcRegisterMainDiv .titleTxtDiv h2 {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  margin-bottom: 40px;
}
.lmcRegisterMainDiv .titleTxtDiv h2:after {
  content: "";
  height: 0.125rem;
  width: 50%;
  background-color: #000;
  position: absolute;
  bottom: -20%;
  left: 0%;
  -webkit-transform: translate(-0%);
  transform: translate(-0%);
}

.primaryInformationForm,
.otherInformationForm {
  margin: 5px auto;
  display: block;
  background-color: #f9f9f9;
  padding-bottom: 20px;
}
.otherInformationForm .headingTxt,
.primaryInformationForm .headingTxt {
  width: 100%;
  background-color: #f2f2f2;
  display: block;
  padding: 5px 20px;
  margin: 15px auto;
}
.otherInformationForm .headingTxt h1,
.primaryInformationForm .headingTxt h1 {
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  text-transform: capitalize;
}
.otherInformationForm .formMainDiv,
.primaryInformationForm .formMainDiv {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
  padding: 20px;
}
.otherInformationForm .formMainDiv .formFieldDiv,
.primaryInformationForm .formMainDiv .formFieldDiv {
  width: 50%;
  display: inline-block;
  height: 85px;
  float: left;
}
.otherInformationForm .formMainDiv .formFieldDiv label {
  width: 40%;
  float: left;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  vertical-align: middle;
  align-content: center;
  text-transform: capitalize;
  /* display: flex; TODO 30 JULY */
  align-items: center;
  float: left;
  margin-right: 20px;
  height: 50px;
}
.primaryInformationForm .formMainDiv .formFieldDiv label {
  width: 30%;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  vertical-align: middle;
  align-content: center;
  text-transform: capitalize;
  /* display: flex;  TODO 30 JULY */
  align-items: center;
  float: left;
  height: 50px;
  margin-right: 20px;
}

.otherInformationForm .formMainDiv .formFieldDiv span {
  width: 50%;
  display: inline-block;
}
.otherInformationForm .formMainDiv .formFieldDiv input,
.otherInformationForm .formMainDiv .formFieldDiv select {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  border: solid 1px #c0c0c0;
  padding: 10px;
  color: #000;
  font-size: 18px;
  margin-left: 5px;
  float: left;
}
.primaryInformationForm .formMainDiv .formFieldDiv span {
  width: 60%;
  display: inline-block;
}

.primaryInformationForm .formMainDiv .formFieldDiv select {
  -webkit-appearance: none;
  -moz-appearance: window;
  background-image: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.primaryInformationForm .formMainDiv .formFieldDiv input,
.primaryInformationForm .formMainDiv .formFieldDiv select {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  border: solid 1px #c0c0c0;
  padding: 10px;
  color: #000;
  font-size: 18px;
}

.primaryInformationForm .formMainDiv .formFieldDiv option:first-of-type {
  color: #c0c0c0;
}
.otherInformationForm .saveBtn,
.otherInformationForm .formMainDiv button,
.primaryInformationForm .formMainDiv button {
  background-color: #0d58b4;
  border: none;
  color: #fff;
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin: 40px auto;
  display: block;
  border-radius: 10px;
  text-transform: capitalize;
}
.uploadDocumentMainDiv h2 {
  width: 100%;
  background-color: #f2f2f2;
  display: block;
  padding: 5px 20px;
  margin: 15px auto;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  text-transform: capitalize;
}
.uploadDocumentMainDiv .attachmentListDescp {
  padding: 20px;
  /* display: inline-block;  */
  width: 96%;
  float: none;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  margin: 2%;
}
.uploadDocumentMainDiv .attachmentListDescp ul {
  padding: 20px 30px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.uploadDocumentMainDiv .attachmentListDescp ul li {
  margin: 10px 0;
  display: inline-block;
  display: list-item;
  list-style: inside;
}
.uploadDocumentMainDiv .attachmentListDescp p input {
  margin: 0px 10px;
  height: 20px;
  width: 20px !important;
  top: 3px;
  position: relative;
}
.uploadDocumentMainDiv .attachmentListDescp p input[type="checkbox"]:after {
  left: 32%;
}
.uploadDocumentMainDiv .attachmentListDescp .imgCameraBig {
  width: 100%;
  object-fit: contain;
  display: inline-block;
  height: 100%;
}
.uploadDocumentMainDiv .attachmentListDescp .uploadImgDiv {
  width: 100%;
  height: 300px;
  border: 2px solid #dcdcdc;
  margin: auto;
  display: block;
}
.lmcRegisterMainDiv .custom-select.input__readonly {
  background-color: #ebebeb;
  border: 0 !important;
}

.uploadDocumentMainDiv .applyBtnDiv {
  width: 500px;
  display: block;
  margin: 20px auto;
}
.uploadDocumentMainDiv .applyBtnDiv button {
  width: 40%;
  display: block;
  float: left;
  padding: 10px 20px;
  background: #dcdcdc;
  font-size: 18px;
  color: #000;
  text-transform: capitalize;
  margin: 10px 20px;
  border-radius: 10px;
  border: 0;
}
.uploadDocumentMainDiv .applyBtnDiv button:hover {
  background-color: #0d58b4;
  color: #fff;
}

.uploadDocumentMainDiv .attachmentViewDiv {
  width: 40%;
  height: 200px;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  margin: 20px auto;
}

.newsDetailsMainDiv {
  display: block;
  margin: 50px auto;
}
.newsDetailsMainDiv h1 {
  font-size: 48px;
  line-height: 100%;
  font-weight: 800;
  color: #000000;
  /* text-transform: uppercase; */
  margin: 0 0 10px 0;
}
.newsDetailsMainDiv h2 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 800;
  color: #000000;
  /* text-transform: uppercase; */
}
.newsDetailsMainDiv .news-category-title {
  font-weight: 700;
  color: #231f20;
  font-size: 20px;
  margin: 0 0 15px 0;
  line-height: 1;
}
.news-content_info {
  margin: 0px;
  padding: 0px;
  color: #000000;
  font-family: "Poppins", Arial, Verdana, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
}
.newsDetailsMainDiv .news-date {
  color: #636363;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
  margin: 0 0 35px 0;
}

.newsDetailsMainDiv .news-Img {
  padding: 10px 10px 10px 0px;
}

.newsDetailsMainDiv .news-content,
.newsDetailsMainDiv .news-content_info {
  overflow: unset;
  display: list-item;
}

.newsDetailsMainDiv pre {
  margin-bottom: 0 !important;
}
.newsDetailsMainDiv .news-content ol,
.newsDetailsMainDiv .news-content_info ol,
.newsDetailsMainDiv .news-content ul,
.newsDetailsMainDiv .news-content_info ul {
  margin: -20px 0;
  padding: 0 0 0 20px;
}
.newsDetailsMainDiv .news-content ol li,
.newsDetailsMainDiv .news-content_info ol li {
  list-style: decimal !important;
  display: list-item;
  overflow: unset;
}
.newsDetailsMainDiv .news-content ul li,
.newsDetailsMainDiv .news-content_info ul li {
  list-style: disc !important;
  display: list-item;
  overflow: unset;
}
.newsDetailsMainDiv .news-content,
.newsDetailsMainDiv .news-content_info,
.newsDetailsMainDiv .news-content {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  white-space: pre-wrap;
  overflow: hidden;
  display: inline;
}

.newsDetailsMainDiv .news-content_info {
  display: block !important;
  margin-top: 0px;
}
.newsDetailsMainDiv .news-contacts {
  font-family: "Poppins", sans-serif;
  white-space: pre-wrap;
  overflow: hidden;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-top: 50px;
}
.news-Img {
  width: 500px;
  display: inline-block;
  height: auto;
  margin: 0 20px 20px 0px;
  float: left;
  vertical-align: middle;
}

.news-Img img {
  width: 100%;
  object-fit: cover;
}

.railborder,
.railways-cross-section {
  width: 100%;
  margin: auto;
}

.resourcesMapPopup .modal-header,
.resourcesMapPopup .modal-footer {
  padding: 0;
  border: 0;
}
.resourcesMapPopup .modal-header .btn-close {
  padding: 20px;
  outline: none;
}
.resourcesMapPopup .modal-dialog {
  margin: 0 auto;
  margin-top: 160px;
}
.resourcesMapPopup .modal-body {
  padding: 40px 10px 0;
}
.resourcesMapPopup .leftDivInput {
  margin-right: 4%;
}
.resourcesMapPopup .leftDivInput,
.resourcesMapPopup .rightDivInput {
  width: 48%;
}
.resourcesMapPopup .col-md-12.formemail,
.resourcesMapPopup .textareaDivContactus {
  padding: 15px 0px 2px 0px !important;
}
.resourcesMapPopup .Resource__form__select {
  width: 100%;
}
.resourcesMapPopup .modal-header .close {
  border: 0;
  background: transparent;
  font-size: 32px;
  padding: 0;
  margin: 5px;
  position: absolute;
  right: 0px;
  top: -15px;
  z-index: 99;
}
.forgetmodel_parent {
  top: 30vh;
}
.downloadOptInnerList {
  width: 100% !important;
}
.reportModalClass .downloadOptDiv a {
  padding: 20px !important;
}
.reportModalClass .downloadOptDiv a,
.reportModalClass .downloadOptDiv div {
  display: block;
  width: 25%;
  text-align: center;
  float: left;
  margin: auto;
}
.reportModalClass .modal-header .close {
  border: 0;
  background: transparent;
  font-size: 32px;
  padding: 0;
  margin: 5px;
  position: absolute;
  right: 0px;
  top: -15px;
  z-index: 99;
}
.reportModalClass .downloadOptDiv div button {
  padding: 10px;
}
.reportModalClass .downloadOptDiv div {
  padding: 10px;
}
.reportModalClass .downloadOptDiv svg {
  font-size: 64px;
  width: 64px;
  height: auto;
}
.reportModalClass .downloadbutton {
  padding: 10px !important;
  margin: auto !important;
}
.reportModalClass .downloadOptDiv {
  text-align: center;
  display: inline-block;
  width: 100%;
  background-color: #0d58b4 !important;
}
.reportModalClass .modal-header,
.reportModalClass .modal-footer {
  padding: 0;
  border: 0;
}
.reportModalClass .modal-header .btn-close {
  padding: 20px;
  outline: none;
}
.reportModalClass .modal-dialog {
  margin: 0 auto;
  margin-top: 80px;
}
.reportModalClass .modal-body h5 {
  width: 90%;
}
.reportModalClass .modal-body {
  padding: 10px 10px 0;
}
.marine-container .marine-card .camera-txt {
  height: 70px;
}
.form-control[type="email"],
.form-control[type="password"] {
  padding-right: 35px;
}

.custom-modal {
  top: 30vh !important;
}

.marineCameraModal {
  padding-right: 0 !important;
}
.marineCameraModal .modal-content {
  height: 475px;
  display: block;
  border-radius: 12px;
}
.marineCameraModal .modal-body {
  padding: 0;
}
.bg-warning {
  border-radius: 20px 20px 0 0;
}

.marineCameraModal .modal-dialog {
  margin: auto;
  top: 15vh;
}

.marineCameraModal .vesselMapTab {
  height: 420px;
  border: 0px solid #999;
}
.marineCameraModal .popupCaltransCameras .dataDescpTxt span label {
  padding-right: 25px;
}
.marineCameraModal .gm-style .gm-style-iw-c {
  transform: translate3d(-50%, -105%, 0);
}
.marineCameraModal .gm-style .gm-style-iw-c,
.marineCameraModal .gm-style .gm-style-iw-d {
  max-height: 300px !important;
}
.trafficCameraDiv {
  overflow: hidden !important;
}
.gateinformation-section p.text-center {
  display: block;
  width: 90%;
  margin: 10px auto;
}
.allAlertsMainDiv h5 {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
    no-repeat calc(100% - 10px) center;
  background-size: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #000000cc !important;
  margin: 0;
  padding: 10px;
}
.allAlertsMainDiv .active h5 {
  background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
}
.faq-section .mb-0 > a,
.faq-section .mb-0 > a:hover {
  color: #333 !important;
  padding: 10px !important;
  font-size: 18px !important;
}
.faq-section h5 {
  background: none;
  padding: 0;
}
.faq-section .mb-0 > a:after {
  content: "" !important;
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
    no-repeat calc(100% - 10px) center;
  background-size: 20px;
  cursor: pointer;
  font-size: 18px;
  width: 30px;
  height: 30px;
}
.faq-section .mb-0 > a[aria-expanded="true"]:after {
  background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
}

.alertsMainDivPage .card,
.alertsMainDivPage .card.parentDivCard,
.eriDiv .card {
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  margin-top: 5px;
}
.alertsMainDivPage .card.parentDivCard .faq-section #accordion .card {
  margin-top: 5px;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.aggrementLink {
  font-weight: bold;
  text-decoration: underline !important;
}
.alertsMainDivPage .card.parentDivCard .faq-section #accordion .card-header {
  border-radius: 0px;
}
.alignCenterDivTable {
  width: 90%;
  display: flex;
  height: 100%;
  align-items: center;
}
.alignCenterDivTableTitle {
  width: 95%;
  display: block;
  height: 100%;
  align-items: center;
}
.alertsMainDivPage
  .card.parentDivCard
  .faq-section
  #accordion
  .card:first-child
  .card-header {
  border-radius: 0;
}
.alertsMainDivPage
  .card.parentDivCard
  .faq-section
  #accordion
  .card:last-child
  .card-header {
  border-radius: 0;
}
 

.alertsMainDivPage .card.parentDivCard ul,
.alertsMainDivPage .card.parentDivCard .collapse.show ul {
  padding: 0;
}
.alertsMainDivPage .card.parentDivCard .accordion {
  margin: 5px 0;
}
.marineTerminalAlerts #accordion .card-header {
  border-radius: 0 !important;
}
.marineTerminalAlerts #accordion .card-header .card-body {
  background-color: transparent;
}
.alertsMainDivPage
  .card.parentDivCard
  .accordion
  .card:first-child
  .card-header {
  border-radius: 10px 10px 0 0;
}
.alertsMainDivPage
  .card.parentDivCard
  .accordion
  .card:last-child
  .card-header,
  .eriDiv
  .accordion
  .card:last-child
  .card-header {
  border-radius: 0 0 10px 10px;
}
/* .text-danger {
  color: #dc3545!important;
  font-size: 12px;
  text-align: left; 
} */
.pwdInputDiv {
  padding-bottom: 20px;
}
.confirmPwdErrorDiv.text-center {
  margin-top: -35px;
  position: relative;
  margin-bottom: 15px;
}

.otherInformationForm .formMainDiv .formFieldDiv .text-danger {
  padding: 2px 0px 0 5px;
}
.primaryInformationForm .formMainDiv .formFieldDiv input[type="date"] {
  text-transform: uppercase;
}
.newsUpdateContentMain {
  display: inline-block;
  width: 100%;
  margin: 10px auto;
}
.custom-modal.driverregistration-popup p {
  padding: 0 10px;
}
.vesselOperationMainDetail .closeBtnPopup {
  position: absolute;
  right: -11px;
  z-index: 9999;
  background: #8b0909;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  font-weight: 600;
  top: -20px;
  cursor: pointer;
}
.vesselOperationMainDetail .vesselInfoDiv {
  overflow-y: auto;
  height: 450px;
  margin-top: 10px;
}
 
.vesselOperationMainDetail .vesselMapTab {
  border-radius: 0;
  margin-bottom: 0px;
}
.vesselLocationInfo {
  height: auto !important;
  padding: 10px 0 !important;
}
.vesselLocationInfo label b {
  margin-right: 5px;
  margin-top: 5px;
  display: inline-block;
}
.notfoundMainDiv {
  width: 400px;
  height: 400px;
  margin: auto;
  display: block;
}
.faqListDataDiv .notfoundMainDiv,
.securitySafetyMain .notfoundMainDiv,
.currentWaitingTimeDiv .notfoundMainDiv,
.alertsMainDivPage .notfoundMainDiv,
.trafficAlertDDown .notfoundMainDiv {
  width: 250px;
  height: 250px;
}

.breadCumNewDiv {
  margin-top: 130px;
}
.disclaimerMainDiv {
  margin-top: 50px;
  margin-bottom: 50px;
  display: block;
  color: #000;
}
.disclaimerMainDiv a,
.disclaimerMainDiv a:hover {
  color: #000;
}
.disclaimerMainDiv .linkBlueDiv a,
.disclaimerMainDiv .linkBlueDiv a:hover {
  color: #034973;
}
.disclaimerMainDiv .listContentDiv {
  padding-left: 40px;
}
.powerImgFooterDiv {
  width: 150px;
  /* filter: invert(100%) brightness(5); */
  vertical-align: text-bottom;
}
.textareaDivContactus .resourceInnerForm {
  width: 100%;
}
.textareaDivContactus span {
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  background: #fff;
  width: 95%;
  border-radius: 5px;
}
.textareaDivContactus span textarea {
  border: 0 !important;
  width: 100% !important;
  border-radius: 5px;
}
.textareaDivContactus span textarea:focus,
.languageSelectDiv:focus {
  outline: 0;
}
.languageSelectDiv {
  width: 90%;
  margin: auto;
  background: transparent;
  color: #fff;
  border: 0;
}
.uploadImgMainDiv .uploadImgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.uploadImgMainDiv .uploadImgBox {
  position: relative;
  height: 100%;
  width: 100%;
}
.uploadImgMainDiv {
  display: inline-block;
  border: 1px solid #aeaeae !important;
  height: 200px !important;
  width: 100% !important;
}
.uploadImgMainDiv .uploadCloseBtn {
  position: absolute;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0;
  background: #830f0f;
  color: #fff;
  right: 10px;
  top: 10px;
}
.uploadImgMainDiv .adminLmcBtn .saveBtn {
  margin: 80px auto;
}
.otpDivForm {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  margin-bottom: 20px;
}
.col-form-label.otpTxtdiv {
  font-size: 28px;
  line-height: 40px;
}
.timerTxtDiv {
  font-size: 40px;
  line-height: 40px;
  font-family: "DigitalFont";
  letter-spacing: 5px;
  display: block;
  width: 100px;
  margin: auto;
  background: #ddd;
  padding: 5px 0px 0 10px;
  text-align: center;
}
.otp__text-center div {
  width: 20%;
}
.otpResendBtn,
.forgotPasswordMainDiv .submitBtn {
  margin: 2px auto !important;
}
.otp__text-center div input {
  width: 90% !important;
  font-size: 32px;
  line-height: 40px;
  margin: auto;
}

.loginsection .form-section.forgotPasswordMainDiv form .form-group {
  margin: 0 auto;
  padding: 5px 10px 0 10px !important;
}
.txtOTPDesp {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px !important;
  display: block;
}
.formOTPMainDiv {
  margin-bottom: 20px !important;
}
.allAlertsMainDiv .parentDivCard .card .active1 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.alertTabSecuritySafety .osowInfoInnerDiv .resources {
  border-radius: 0 !important;
  margin-bottom: 10px;
  background: #ececec;
}
.alertTabSecuritySafety .osowInfoInnerDiv .resources:nth-child(even) {
  background: #f9f9f9;
}
.alertTabSecuritySafety {
  border: 0 !important;
}
.allAlertsMainDiv .faq-section .mb-0 > a:after,
.faq-section .trafficAlertDDown .mb-0 > a:after {
  float: right;
  right: 0%;
  top: 20%;
}
.news-video-div {
  width: 90%;
  height: 400px;
}
.news-video-div .newsvid {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center left;
}
.videoPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}
.languageSelectDiv option {
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}
.pagination {
  display: flex;
  list-style: none;
  text-transform: capitalize;
}
.pagination li {
  margin: 0;
  height: 100%;
}
.tools-section img.toolsandservicelogo {
  height: 70px;
}
.tools-section .toolsandservicelogo.transition__img {
  height: 70px;
  margin-top: -1px;
}

.pageNotFound h1 {
  text-transform: uppercase;
  font-size: 50px;
}
.pageNotFound span {
  width: 50%;
  float: left;
  display: block;
  text-align: center;
  height: 350px;
}
.pageNotFound span img {
  width: 100%;
}
.pageNotFound span.pageNotTxt {
  display: inline-block;
  height: 100%;
  margin: 10% auto;
}
.pageNotFound span .backToHomeBtn {
  border: 0;
  background: #333;
  font-size: 14px;
  color: #fff;
  padding: 10px 5px;
  border-radius: 5px;
  margin: auto;
}
.vesselinportclass b.thTxt,
.vesselestimationdiv b.thTxt {
  font-weight: normal;
  line-height: 20px;
}
.bannerImgMainDiv,
.bannerImgMainDiv .banner_img {
  position: relative;
  text-align: left;
}

.bannerLogoNoticeDiv {
  position: absolute;
  top: 25%;
  width: 75%;
  background: rgb(0 0 0 / 50%);
  padding: 7%;
  margin: 20px auto;
  height: 400px;
}
.bannerLogoNoticeDiv .logoImgDiv {
  float: left;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  width: 150px;
  height: 150px;
}
.logoImgDiv .marineterminal-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
.bannerLogoNoticeDiv .noticeDespDiv {
  font-size: 32px;
  color: #fff;
  padding: 0 10px;
  display: inline-block;
  width: 85%;
  float: right;
}
.bannerLogoNoticeDiv .noticeDespDiv p {
  display: block;
  overflow: auto;
  height: 200px;
  font-size: 28px;
}
.terminalNoticeDespDiv {
  font-size: 22px;
  line-height: 24px;
  margin-top: 20px;
  display: inline-block;
  font-weight: bold;
}
.terminalNoticeDespDiv p {
  font-size: 18px;
  line-height: 22px;
  color: #000;
  font-weight: normal;
  margin-top: 10px;
}
.liveStreamRedIcon {
  width: 40px;
  height: 40px;
  float: left;
  margin: -7px 0px 0 -10px;
  display: inline-block;
}
.pageNotFound404MainDiv {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 60px 0 0 0;
}
.notfound404MainDiv {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  margin-bottom: -110px;
}

.alertListMainDiv .alertListOpt {
  width: 92%;
  float: left;
  display: inline-block;
}
.alertListMainDiv .buttonDiv {
  width: 7%;
  height: 100%;
  display: inline-block;
  text-align: right;
  float: right;
  position: relative;
}
.alertListMainDiv .buttonDiv button {
  /* width: 40px; */
  border: 0;
  background: #33b4e9;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dateAlert{
  margin-right: 20px;
}
.textDescpDiv .txt-overflow {
  height: 70px;
}
.selectRecordNumberDiv {
  font-size: 14px;
  font-weight: 600;
  padding-left: 5px;
  float: left;
}
.selectRecordNumberDiv select {
  margin: 0 5px;
}
.marine-container .marine-card .noImgDiv {
  object-fit: none;
}
.TTTNoteDiv .terminalNoticeDespDiv {
  font-size: 22px;
  margin-bottom: 0;
  text-transform: uppercase;
  display: none !important;
}
.TTTNoteDiv ul {
  padding-left: 30px !important;
}
.TTTNoteDiv ul li::marker {
  font-size: 20px;
}
.profilePicMainDiv.viewDetailLMCSection {
  width: 100%;
  height: 250px;
  border: 1px solid #999;
  text-align: center;
}
.openWeatherTxtDiv {
  text-align: center;
  /*width: 400px;
   background: #fff;   
    box-shadow: 0 0 8px 0px #afafaf;
    margin: auto;*/
}
.openWeatherTxtDiv span {
  font-size: 26px;
  font-weight: bold;
}
.openWeatherTxtDiv img {
  width: 60px;
  vertical-align: bottom;
}
.deleteicon.text-danger .fa {
  color: red;
}
.alertAnnouncementsMain span.High {
  background: red;
  height: 25px;
  width: 25px;
  margin-bottom: -21px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
}
.alertAnnouncementsMain span.Medium {
  background: orange;
  height: 25px;
  width: 25px;
  margin-bottom: -21px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
}
.alertAnnouncementsMain span.Low {
  background: green;
  height: 25px;
  width: 25px;
  margin-bottom: -21px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
}
.alertAnnouncementsMain .catTitleTxt {
  float: left;
  font-size: 18px;
  font-weight: 600;
}
.alertAnnouncementsMain .dateAlertTxt {
  float: right;
  font-size: 18px;
  margin: 0 10px;
  font-weight: 600;
}
.removedOverlayTrigger.alertInnerDivList li span {
  width: 85%;
  float: left;
  padding: 5px 10px;
}
.alertAnnouncementsMain .leftDescpDiv {
  width: 85%;
  float: left;
}
.removedOverlayTrigger.alertInnerDivList li .buttonDiv,
.alertAnnouncementsMain .rightCopyDiv {
  width: 15%;
  text-align: center;
  float: right;
  height: 100%;
  align-items: center;
  display: flex;
}
.removedOverlayTrigger.alertInnerDivList li .buttonDiv,
.alertAnnouncementsMain .rightCopyDiv .buttonDiv {
  margin: auto;
  position: relative;
}

.removedOverlayTrigger.alertInnerDivList li .buttonDiv b,
.alertAnnouncementsMain .buttonDiv b {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 5px;
}
.removedOverlayTrigger.alertInnerDivList li .buttonDiv button:hover,
.alertAnnouncementsMain .rightCopyDiv .buttonDiv button:hover {
  background: #a8b013;
}
.removedOverlayTrigger.alertInnerDivList li .buttonDiv button,
.alertAnnouncementsMain .rightCopyDiv .buttonDiv button {
  /* cursor: pointer; */
  width: 82px;
  border: 0;
  background: #33b4e9;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.contactUsMapDiv {
  width: 100%;
  height: 510px;
}
.oaklandPortalCamerasMapMain {
  border: 0;
}
.oaklandPortalCamerasMap {
  margin-bottom: 0;
  border: 0;
}
.oaklandPortalCamerasMap .borderLine {
  border: 1px solid #999;
}
.headerBannerMainDiv {
  position: relative;
  margin-top: 100px;
  height: 500px;
  width: 100%;
}

.image-row-banner {
  display: flex;
  background: #000000;
   justify-content: space-between;
}

.image-row-banner img {
  margin-top: 100px;
  width: 33.33%;
  height: auto;
  object-fit: unset;
}

.headerBannerMainDiv img.banner_img,
.headerBannerMainDiv img.vessel_img {
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: fill;
  object-position: bottom center;
}
.legendLineColorDiv div {
  display: inline-block;
}
.legendLineColorDiv {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: auto;
  background: #fff;
  border: 1px #ddd solid;
  padding: 10px;
  display: table;
  margin: 10px auto;
}
.lineBg {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: sub;
}
.blackBgColor {
  background: #000000;
}
.orangeBgColor {
  background: #AA561D;
}
.blueBgColor {
  background: #4f81ce;
}
.greenBgColor {
  background: #00ba16;
}
.securitySafetyMapModal h4 {
  display: none !important;
}
.tollMapPopUpDiv.securitySafetyMapModal {
  height: 100%;
}
.resultsFoundDiv b {
  font-size: 24px;
  color: #034973;
}
.resultsFoundDiv {
  width: 85%;
  font-size: 20px;
  font-weight: 600;
  clear: both;
  text-align: right;
  display: block;
  margin: 10px 0;
  float: right;
}
.securitySafetyMapModal span div div:first-child {
  text-align: center !important;
  margin: 0px auto;
  margin-bottom: 10px !important;
  font-size: 28px !important;
  font-weight: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #076d8e;
}
.exportBtnDiv .fa {
  margin-right: 5px;
}
.exportModalDiv_HTTT .modal-dialog {
  max-width: 400px !important;
}
.exportModalDiv_HTTT .modal-footer,
.exportModalDiv_HTTT .modal-header {
  border: 0;
  text-align: center;
}
.exportModalDiv_HTTT .exportFileDiv {
  width: 100%;
  float: none;
  text-align: center;
  margin: auto;
  display: flex;
  background: transparent;
}
.exportModalDiv_HTTT .exportFileDiv span {
  display: flex;
  float: left;
  padding: 0;
  margin: auto;
}
.exportModalDiv_HTTT .exportFileDiv span .btn-primary svg {
  font-size: 60px;
  height: 60px;
  width: 60px;
}
.exportModalDiv_HTTT .exportFileDiv span .btn-primary {
  margin: 0;
  padding: 0px;
  line-height: 100%;
  height: 60px;
  width: 60px;
  background-color: transparent !important;
  color: #39f !important;
  border: 0;
}
.searchOptDiv .serachOption label .infoIcon i.fa {
  font-size: 18px;
  top: 2px;
  position: relative;
  color: #39f;
}
.infoDescpModal.addWaitingModalPop .modal-footer {
  display: block;
  text-align: center;
}
.infoDescpModal.addWaitingModalPop .modal-footer {
  display: block;
  text-align: center;
}
.infoDescpModal.addWaitingModalPop .bookingAddWaitingBtn {
  width: 150px;
  margin-right: 10px;
}

.infoDescpModal .modal-footer {
  border: 0;
}
.infoDescpModal button {
  color: #fff !important;
  background-color: #0d58b4 !important;
  border-color: #0d58b4 !important;
  align-items: center;
  padding: 5px 10px !important;
  font-size: 16px !important;
  margin: auto;
  width: 100px;
  text-transform: uppercase;
  border: 0;
}
.driverregistrationOTPCodeDiv .otpInput {
  padding: 0 10px;
}

.addVideoLinkDiv button:hover {
  color: #48ab02 !important;
  font-weight: bold;
  padding: 0 !important;
  margin: 5px auto !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.addVideoLinkDiv button {
  padding: 0 !important;
  margin: 5px auto !important;
  background-color: transparent !important;
  color: #005495 !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.addVideoLinkDiv {
  border: 1px dashed #999;
  margin-bottom: 0 !important;
  height: 50px;
  border-radius: 10px;
}
.adminNewsUpdate .urlEditMainDiv .submitform {
  margin: 20px auto;
  display: inline;
  margin-left: 10px;
}
.playnowButton.btn {
  background-color: #973525 !important;
  border: 0;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 20px;
}
.playnowButtonModal .modal-footer button:focus,
.playnowButton.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.playnowButtonModal .modal-body .VideoInput_video {
  height: 320px;
  object-fit: cover;
}
.playnowButtonModal .modal-footer {
  border: 0;
  text-align: center;
}
.playnowButtonModal .modal-footer button {
  margin: auto;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  padding: 5px 20px;
}
.playnowButtonModal .modal-content {
  width: 600px;
}
.playnowButtonModal .modal-body {
  padding: 0;
  width: 600px;
}
.driverregistration-section.adminNewsUpdate .form-group {
  cursor: hidden !important;
}
.maxImgTextMsg {
  color: #999;
  display: inline;
}
.lastUpdateDiv .listOptionDiv select {
  padding: 7px;
}
.addVideoLinkDiv {
  padding: 5px;
}
.addVideoLinkDiv span {
  padding: 4px 10px;
  display: inline-block;
}
.addVideoLinkDiv button.removeVideoBtn {
  background: rgb(136, 40, 40) !important;
  color: #fff !important;
  border: 0;
  border-radius: 5px;
  font-size: 12px !important;
  padding: 2px 10px !important;
  margin-left: 10px !important;
}
.adminTableMain .fa-sort:before,
.adminTableListMain .fa-sort:before {
  padding: 0px 0 0 5px;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.userAlertsMainDiv .searchFilterDiv {
  background: #fff;
  padding: 20px 10px;
  margin: 10px 0 20px;
  border-radius: 10px;
  box-shadow: 0 0 25.4px 1.6px rgb(0 0 0 / 9%);
  text-align: center;
}
.userAlertsMainDiv .searchFilterDiv h2 {
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
}
.userAlertsMainDiv .userAlertsMainDiv ul li {
  margin: 0;
  border-radius: 0;
}
.userAlertsMainDiv .alertInnerDivList li span {
  width: 100% !important;
  display: inline-block;
  padding: 0px;
}
.userAlertsMainDiv .alertInnerDivList li span p {
  width: 100%;
  text-align: left;
  margin-bottom: 0;
  font-size: 20px;
}
.userAlertsMainDiv .alertInnerDivList li span p.sty2 {
  padding: 0;
}
.userAlertsMainDiv .alertInnerDivList li span p.sty3 {
  text-align: right;
  font-size: 18px;
}
.userAlertsMainDiv ul li {
  width: 100%;
  padding: 10px;
  color: #000;
  border-radius: 0px;
  box-shadow: 0 0 25.4px 1.6px rgb(0 0 0 / 9%);
  background: #fff;
  margin-bottom: 0px;
}
.sidebar-nav .nav-link {
  align-items: normal;
  white-space: break-spaces;
}
.sidebar-nav .nav-icon {
  position: relative;
  flex: 0 0 50px !important;
}
.searchSectionMain .headerBannerMainDiv {
  position: relative;
  margin-top: 130px;
  height: 200px;
}
.search-section,
.disclaimerMainDiv {
  margin-bottom: 10px;
  min-height: 50vh;
  height: auto;
}
.search-section .gsc-input {
  background: none !important;
}
.gatehours-section span p {
  text-align: center !important;
}
.fixedTopHeaderBar .searchIconMainDiv {
  width: 70px;
}
.fixedTopHeaderBar .blank_md8Div {
  width: 70%;
}
.newCalendarIcon.calendar_icon {
  position: relative !important;
  margin-top: -33px !important;
  float: right;
  right: 8px;
}
.DatePickerCustomDiv .calendar_icon {
  position: relative;
  margin-top: -33px;
  float: right;
  right: 8px;
}
.swal-modal.logout .swal-button-container:first-child{
  float: right;
}
.bookedSpaceSearchMain .lastUpdateDiv {
  margin-top: 0;
}
.bookingSpaceCalendarResultDiv h6 b {
  display: inline-block;
  font-size: 24px;
  vertical-align: baseline;
}
.searchNdTotalDataDiv {
  display: inline-block;
  width: 100%;
  border-bottom: 2px solid #999;
}
.searchNdTotalDataDiv .totalTxtDiv {
  float: left;
  width: 40%;
}
.searchNdTotalDataDiv .resultOptList {
  float: right;
  width: 60%;
}
.searchNdTotalDataDiv .searchFilterDiv {
  text-align: right;
  margin-bottom: 10px;
}
.searchNdTotalDataDiv .searchFilterDiv .serachOption {
  float: right;
  margin-left: 10px;
}
.searchNdTotalDataDiv .searchFilterDiv .serachOption button:disabled {
  background: #999;
  color: #666;
}
.searchNdTotalDataDiv .searchFilterDiv .serachOption button {
  border: 0;
  background: #095d9d;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
}
.searchNdTotalDataDiv .searchFilterDiv input {
  border: 1px solid #999;
  padding: 2px 5px;
  border-radius: 5px;
  width: 50%;
}
.bookingSpaceCalendarResultDiv h6 {
  text-align: left !important;
  padding-right: 20px;
  padding-bottom: 10px;
}
.bookedSpaceSearchMain .lastUpdateDiv .listOptionDiv button.searchBtn {
  font-size: 18px;
  margin-top: 29px;
}

.bookingSpaceCalendarResultDiv {
  margin-top: 20px;
}
.bookingSpaceCalendarResultDiv b {
  display: block;
}
.bookingSpaceCalendarResultDiv .bookingDateMainDiv {
  border-radius: 5px;
  background: #dcdcdc;
  display: block;
  width: 90%;
  text-align: center;
  padding: 10px;
  height: 120px;
  color: #fff;
}
.bookingSpaceCalendarResultDiv b.bookingDateTxt {
  font-size: 48px;
  font-weight: bold;
  line-height: 100%;
  height: 70px;
  display: block;
  text-align: center;
  margin: auto;
  padding: 10px;
}
.bookingSpaceCalendarResultDiv b.bookingMonthYearTxt {
  font-size: 20px;
  line-height: 100%;
  height: 50px;
  display: block;
  text-align: center;
  margin: auto;
  padding: 5px 0;
}
.NoInvoiceNoTxt {
  width: 99.5% !important;
  padding: 10px !important;
}
.bookingSpaceCalendarResultDiv .listBoxDiv {
  border-bottom: 2px solid #999;
}
.bookingSpaceCalendarResultDiv .totalSpacesBookedDiv {
  border-radius: 5px;
  background: #dcdcdc;
  display: inline-block;
  width: 90%;
  text-align: center;
  padding: 10px;
  height: 120px;
  color: #fff;
}
.bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
  font-size: 10px;
  line-height: 100%;
  height: 50px;
  display: block;
  text-align: center;
  margin: auto;
  padding: 5px 0;
}
.bookingSpaceCalendarResultDiv .totalSpacesBookedDiv span {
  font-size: 48px;
  font-weight: bold;
  line-height: 100%;
  height: 70px;
  display: block;
  text-align: center;
  margin: auto;
  padding: 10px;
}
.bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
  display: inline-block;
  border: 1px solid #999;
  width: 24%;
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;
  margin: 2px;
}
.bookingSpaceCalendarResultDiv .noborder {
  border: 0;
}
.bookingSpaceCalendarResultDiv .bookingNumbersListMainDiv b.titleDiv {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 2px;
}
.userReportMainDiv {
  display: block;
}
.historicalTTTMainDiv.userReportMainDiv .listOptionDiv .input-group {
  display: flex;
}
.userReportMainDiv .searchFilterMainDiv {
  margin-bottom: 20px;
}
.userReportMainDiv .tableOverFlow {
  background: #fff;
  display: inline-block;
}
.bookingSpaceCalendarResultDiv .bookingNumbersListMainDiv {
  width: 49%;
  margin-right: 5px;
  margin-top: 10px;
}
.bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
  width: 160px;
  font-size: 15px;
  margin-top: -10px;
}
.bookingSpaceCalendarResultDiv .totalSpacesBookedDiv:last-child b {
  padding: 0;
}
.userReportMainDiv .tableOverFlow thead td:first-child {
  width: 120px !important;
}
.userReportMainDiv .tableOverFlow thead td {
  font-weight: bold;
  background: #4b4b4b;
  color: #fff;
  width: 11%;
}
.userReportMainDiv .searchFilterMainDiv .col-md-6:last-child {
  width: 16%;
}
.userReportMainDiv .searchFilterMainDiv .col-md-6 {
  width: 41%;
}
.userReportMainDiv .tableOverFlow td {
  border: 1px solid #999;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  padding: 10px 5px;
}
.userReportMainDiv .calendar_icon {
  position: relative;
  margin-top: -33px;
}
.userReportMainDiv .totalTxtDiv {
  float: left;
  margin-top: 15px;
}
.userReportMainDiv .ExportUserReportsDiv {
  text-align: right;
}
.lastUpdateDiv .listOptionDiv button:hover img {
  animation: rotation 3s infinite 2s;
}
.rightContentMainDiv{
  margin-bottom: 10px;
}
.truckTurnTimeListOpt .historicalSearchList .card .row{
  box-shadow: 0 0 10px #ddd;
}
.terminalDateMonthYearTxt {
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    display: inline-block;
    margin-bottom: 5px;
    padding: 0px 10px !important;
    width: 100% !important;
    margin: 0px auto;
    background: #dddddd;
    color: #5a5a5a;
}

/*------Start LanguageString-Punjabi-------*/
.punjabiString .removedOverlayTrigger.alertInnerDivList li .buttonDiv b,
.punjabiString .alertAnnouncementsMain .buttonDiv b {
  font-size: 18px;
}
.punjabiString .removedOverlayTrigger.alertInnerDivList li .buttonDiv button,
.punjabiString .alertAnnouncementsMain .rightCopyDiv .buttonDiv button {
  width: 100px;
}
.punjabiString .gateinformation-section h4,
.punjabiString .time-underline .blogmin {
  font-size: 28px;
  letter-spacing: 1px;
}

.punjabiString .resultsFoundDiv,
.punjabiString .lastUpdateDiv h3,
.punjabiString .footer-sec .footer-head-underline {
  font-size: 24px;
}
.punjabiString .currentWaitingTimeDiv .tabOptionCWT .card-body,
.punjabiString .truckTurnTimeListOpt .historicalSearchList .infoListDiv label,
.punjabiString .lastUpdateDiv .listOptionDiv button,
.punjabiString .lastUpdateDiv h3 span,
.punjabiString .allAlertsMainDiv h5 {
  font-size: 22px;
}
.punjabiString .speedometerMainDiv h3,
.punjabiString .speedometerMainDiv h6,
.punjabiString .TTTNoteDiv li,
.punjabiString .currentWaitingTimeDiv .marine-container .load-more,
.punjabiString .gateinformation-section .contactDetailsDiv h6,
.punjabiString .footer-sec div .footer-links a,
.punjabiString .tools-section .toolshover .card1 h6,
.punjabiString .historicalTTTMainDiv .listOptionDiv label,
.punjabiString .lastUpdateDiv .listOptionDiv select,
.punjabiString .sendMsgBtn,
.punjabiString .navNewMenuListDiv .homenav,
.punjabiString .vo-bg span,
.punjabiString .vo-menu,
.punjabiString .reportTabsMainDiv .tab-content .downloadBtn,
.punjabiString .reportTabsMainDiv .nav-tabs .nav-link,
.punjabiString .resources-tolls .btn-primary,
.punjabiString .marineterminal-home .CurrentWaitingTimeTxt,
.punjabiString .navmob .navNewMenuListDiv .nav-link {
  font-size: 20px;
}

.punjabiString .highcharts-legend-item text {
  font-size: 20px !important;
}

.punjabiString .vesselLocationInfo label b,
.punjabiString .blacknav,
.punjabiString .viewall-clr,
.punjabiString footer .policy-bg,
.punjabiString .tools-section .toolshover .card1 .hoverToolDescp,
.punjabiString .reportTabsMainDiv .tab-content table thead td,
.punjabiString .tollMapPopUpDiv button,
.punjabiString .tollMapPopUpDiv p .text,
.punjabiString .tollMapPopUpDiv p label {
  font-size: 18px;
}
.punjabiString .tollMapPopUpDiv p span,
.punjabiString .vesselLocationInfo label,
.punjabiString .footer-sec .contactusDiv-footer .footer-links a {
  font-size: 16px;
}
.punjabiString .vesselLocationInfo label b {
  margin-top: 0px;
}
.punjabiString .tollMapPopUpDiv p,
.punjabiString .tollMapPopUpDiv p .text span {
  margin: 0 auto !important;
}
.punjabiString .tollMapPopUpDiv {
  height: 240px;
}
.punjabiString .fixedTopHeaderBar .col-md-9 {
  width: 73%;
}
.punjabiString .fixedTopHeaderBar .col-md-1 {
  width: 6%;
}
.punjabiString .fixedTopHeaderBar .searchIconMainDiv {
  width: 70px;
}
.punjabiString .fixedTopHeaderBar .col-md-1:last-child {
  width: 13%;
}
.punjabiString .f-head {
  font-size: 42px;
}
.punjabiString .formhead {
  font-size: 1.8rem;
}
.punjabiString .mobile-app-head {
  font-size: 42px;
  line-height: 60px;
}
.punjabiString .faqs-section a {
  font-size: 18px;
  margin-left: 10px;
}
.punjabiString .parkingSearchMain .searchOptDiv .serachOption label {
  font-size: 16px;
}
.punjabiString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
  font-size: 15px;
}
.punjabiString
  .bookingSpaceCalendarResultDiv
  .bookingNumbersListMainDiv
  b.titleDiv {
  font-size: 22px;
}
.punjabiString .historicalTTTMainDiv .lastUpdateDiv .listOptionDiv button {
  font-size: 22px;
  margin-top: 34px;
  width: 100px;
  text-align: center;
}

.punjabiString
  .bookedSpaceSearchMain
  .lastUpdateDiv
  .listOptionDiv
  button.searchBtn {
  font-size: 22px;
  margin-top: 36px;
  width: 100px;
  text-align: center;
}
.punjabiString .bookedSpaceSearchMain .lastUpdateDiv .listOptionDiv select {
  padding: 8px 5px;
}
.punjabiString
  .bookedSpaceSearchMain.historicalTTTMainDiv
  .react-datepicker-wrapper
  input {
  padding: 10px 5px;
}
.punjabiString .userprofileBtnDiv .btn,
.punjabiString .userprofileBtnDiv .btn:hover {
  font-size: 14px !important;
}

.punjabiString .bookedSpaceSearchMain .DatePickerCustomDiv .calendar_icon {
  margin-top: unset;
}

.DatePickerCustomDiv .react-datepicker__close-icon {
  margin-top: unset !important;
  background-color: #ffffff !important;
  border-left: none !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.DatePickerCustomDiv .react-datepicker__close-icon::after {
  height: 30px !important;
  width: 30px !important;
  font-size: unset !important;;
}



/*------End LanguageString-Punjabi-------*/

/*------Start LanguageString-Spanish-------*/
.spanishString .newRecurringDate.searchOptDiv .serachOption.equipmentLengthDiv {
  width: 22%;
}
.spanishString .searchOptDiv .serachOption {
  width: 25%;
  display: inline-block;
}
.spanishString .searchOptDiv .serachOption button {
  width: 130px;
}
.spanishString .exportFileDiv {
  width: 150px;
}
.spanishString .sidebar-nav .nav-link {
  align-items: normal;
  white-space: break-spaces;
  text-align: left;
}
.spanishString .sidebar-nav .nav-icon {
  top: 2px;
  position: relative;
  margin-left: -10px;
  flex: 0 0 40px;
}
.spanishString .gateinformation-section .contactDetailsDiv h6 {
  width: 90%;
  margin: auto;
  height: 50px;
}
.spanishString .fixedTopHeaderBar .col-md-9 {
  width: 75%;
}
.spanishString .fixedTopHeaderBar .col-md-1 {
  width: 7%;
}
.spanishString .fixedTopHeaderBar .col-md-1:last-child {
  width: 10%;
}
.spanishString .portlink-div {
  /* width: 230px; */
  width: -webkit-fill-available;
}
.spanishString .loginsection .form-group.px-5 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.spanishString .loginsection .form-group label.col-sm-2 {
  width: 30%;
}

.spanishString .loginsection .form-group .col-sm-10 {
  width: 70%;
}
.spanishString .tollMapPopUpDiv p label,
.spanishString .tollMapPopUpDiv p span,
.spanishString .tollMapPopUpDiv p .text {
  width: 50%;
}
.spanishString
  .nav-fixed
  .navNewMenuDiv.navmob
  .navNewMenuListDiv
  li:nth-child(4) {
  width: 135px;
  margin: 0 35px 0 25px;
}
.spanishString .tollMapPopUpDiv p {
  margin: 5px auto;
}
.spanishString .fixedTopHeaderBar .searchIconMainDiv {
  width: 70px;
}
.spanishString .newVesselTabMainDiv .center-underline h2,
.spanishString .newVesselTabMainDiv .titleTabOption h2 {
  padding: 0 0px 0 0;
}
.spanishString .newVesselTabMainDiv .col-lg-5 {
  width: 40%;
}
.spanishString .newVesselTabMainDiv .col-lg-4 {
  width: 40%;
}
.spanishString .newVesselTabMainDiv .col-lg-3 {
  width: 20%;
}
.spanishString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
  padding: 0;
  margin-top: -5px;
  width: 110px;
}
/*------End LanguageString-Spanish-------*/
/*-------Admin CSS------*/
.swal-modal.logout .swal-title {
  text-align: left;
}
.swal-modal.logout .swal-text {
  text-align: left;
  padding: 0 20px;
  color: #000;
  display: block;
}
.bookedspace .calendariconSpaceBooking {
  position: absolute !important;
  margin-top: -33px !important;
  float: right !important;
  right: 8px !important;
}
.adminSideMenu .badge {
  height: 100%;
}
.exportFileDiv {
  width: 120px;
  float: right;
  display: flex;
  background: #39f;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}
.exportFileDiv label {
  display: inline;
  text-align: center;
  float: left;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 0px solid #333;
  line-height: 22px;
  padding-top: 3px;
  width: 100%;
}
.exportFileDiv span {
  display: inline;
  float: left;
  padding: 0;
  margin-left: 2px;
}
.exportBtnDiv:focus,
.exportFileDiv span button.btn-primary:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.exportFileDiv span .btn-primary:hover {
  color: rgb(194, 95, 8) !important;
}

.exportFileDiv span .btn-primary {
  margin: 0;
  padding: 0px;
  line-height: 100%;
  height: 28px;
  width: 24px;
  background-color: transparent !important;
  color: rgb(255, 248, 51) !important;
  border: 0;
}
.exportFileDiv span .btn-primary svg {
  font-size: 20px;
  height: 24px;
  width: 24px;
}
.innerDashboradMainDiv .col.boxMainDiv,
.truckDriverRosterDiv .col.boxMainDiv {
  width: 33.3%;
  display: block;
  float: left;
}
.settingProfileMain .userDetailsList .col-sm-8 span label {
  margin-right: 15px;
  display: inline;
}
.settingProfileMain .viewUserDetailPage .col-sm-4 {
  height: 100% !important;
  padding-right: 10px !important;
  margin-bottom: 0px;
}
.settingProfileMain .submitBtn {
  margin: auto;
}
.settingProfileMain .viewUserDetailPage .col-sm-12 .row {
  margin-bottom: 0;
}
.titleHeaderDiv .card-header {
  font-size: 24px;
}
.warningIntsructionBokking {
  display: inline-block;
  background: #043691;
  color: #fff;
  padding: 10px;
  font-size: 16px;
}

.settingProfileMain .userDetailsList h4 {
  width: 100%;
  background: #dcdcdc;
  font-size: 20px;
  padding: 5px !important;
  font-weight: 600;
}
.noRecordFoundMainDiv {
  font-size: 20px;
  font-weight: bold;
  background: #fff;
  padding: 20px;
  margin: 10px auto;
  text-align: center;
}
.bookedParkingMainDiv .titleDespDiv p {
  margin: 0;
}
.bookedParkingMainDiv .recordDataListMain ul li {
  margin-top: 0;
}
.bookedParkingMainDiv .titleDespDiv {
  background: #fff;
  font-size: 16px;
  text-align: left;
  padding: 5px 20px;
}
.notFoundMainDiv {
  background: #fff;
}
.lmcProfileDivBtn button {
  margin-right: 10px !important;
  display: inline-block !important;
}
.alertMsgTxtDriverList p {
  font-size: 22px;
  text-align: center;
  margin: 20px auto;
}
.bookedParkingMainDiv .waitingListParking .parkingTitleDiv .bookingTypeTxt {
  width: 70%;
  float: left;
  display: inline-block;
}
.bookedParkingMainDiv .waitingListParking .parkingTitleDiv .statustxt {
  width: 30%;
  text-align: right;
  display: inline-block;
}
.bookedParkingMainDiv .finalPaymentParking .btnMainDiv button,
.waitingListParking .statustxt .cancelBtnDiv button {
  border-radius: 5px;
  box-shadow: 0 0 25.4px 1.6px rgb(0 0 0 / 9%);
  background-color: #1c9ae2;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  margin-left: 10px;
  width: auto;
  display: inline-block;
  position: relative;
  text-transform: capitalize;
  font-size: 20px;
}
.bookedParkingMainDiv .waitingListParking.activityListDiv ul li .boxCard .parkingImgDiv {
  height: 400px !important;
  width: 10% !important;
}
.bookedParkingMainDiv .waitingListParking ul li .boxCard .parkingImgDiv {
  height: 250px !important;
  width: 10% !important;
}
.bookedParkingMainDiv .finalPaymentParking ul li .boxCard .parkingInfoDiv {
  width: 100% !important;
}
.finalPaymentParking .btnMainDiv {
  width: 100%;
  text-align: center;
}
.bookedParkingMainDiv .finalPaymentParking ul li .parkingDetailMainDiv ul li,
.bookedParkingMainDiv .waitingListParking ul li .parkingDetailMainDiv ul li {
  width: 25%;
}
.adminCloseBtn.modal-header,
.driverMngtModal .modal-header {
  padding: 5px 10px;
}
.driverMngtModal .modal-body {
  padding: 0 10px;
}
.driverMngtModal .modal-footer {
  border-top: 0;
  padding: 0;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}
.react-datepicker__navigation {
  align-items: center;
  background: none !important;
  display: flex !important;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute !important;
  top: 2px !important;
  padding: 0 !important;
  border: none !important;
  z-index: 1;
  height: 42px;
  width: 32px !important;
  text-indent: -999em;
  overflow: hidden;
  box-shadow: none !important;
}
.react-datepicker__navigation-icon::before {
  width: 12px !important;
  height: 12px !important;
  top: 12px !important;
}
.driverInventoryMainDiv .searchFilterDiv {
  text-align: right;
  margin-bottom: 10px;
}
.driverInventoryMainDiv .searchFilterDiv input {
  border: 1px solid #999;
  padding: 2px 5px;
  border-radius: 5px;
  width: 350px;
}
.driverInventoryMainDiv .searchFilterDiv .serachOption {
  float: right;
  margin-left: 10px;
}
.driverInventoryMainDiv .searchFilterDiv .serachOption button:disabled {
  background: #999;
  color: #666;
}
.driverInventoryMainDiv .searchFilterDiv .serachOption button {
  border: 0;
  background: rgb(9, 93, 157);
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
}
.newRecurringDate.searchOptDiv .serachOption.numberStallsDiv {
  width: 12%;
}
.newRecurringDate.searchOptDiv .serachOption.equipmentLengthDiv {
  width: 24%;
}

.newRecurringDate.searchOptDiv .serachOption.equipmentLengthDiv select {
  font-size: 14px;
}
.newRecurringDate.searchOptDiv .serachOption {
  width: 18%;
  position: relative;
}
/*.newRecurringDate.searchOptDiv .serachOption:nth-child(1),
.newRecurringDate.searchOptDiv .serachOption:nth-last-child(2) {
  top: 22px;
}*/
.bookingDetailModal .close {
  font-size: 44px;
  line-height: 34px;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}
.bookingDetailModal .modal-body {
  padding: 0px;
}
.bookingDetailModal .modal-body .dataResultMain,
.bookingDetailModal .modal-body .recordDataListMain {
  margin: 0;
}
.bookingDetailModal
  .modal-body
  .bookedParkingMainDiv
  .recordDataListMain
  ul
  li {
  color: #000;
  border-radius: 0px;
  box-shadow: 0 0 0;
  background: #fff;
}
.bookingDetailModal
  .modal-body
  .bookedParkingMainDiv
  .recordDataListMain
  ul
  li
  .boxCard
  .parkingInfoDiv {
  width: 100%;
}
.bookingDetailModal
  .modal-body
  .bookedParkingMainDiv
  .parkingDetailMainDiv
  ul
  li {
  border-bottom: 1px solid #333;
  width: 24.8%;
}
.viewUserDetailPage .col-sm-3,
.viewUserDetailPage .col-sm-4 {
  height: 100% !important;
  padding-right: 10px !important;
  margin-bottom: 15px;
}
.portMessageSignsMain .labelTxt {
  display: inline-block;
  font-weight: 500;
  width: 100%;
  margin-top: 5px;
}
.userPicDiv .profilePicMainDiv {
  display: block;
  border: 1px #dcdcdc solid;
  width: 80%;
  margin: auto;
  height: 180px;
  vertical-align: middle;
  position: relative;
}
.profilePicMainDiv .img-fluid {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
.userInfoDetailDashboard .userPicDiv .profileTxtIcon {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  transform: translate(0%, 0%);
  width: 45%;
  height: 55%;
  font-size: 55px;
  line-height: 85px;
}
.userPicDiv .profilePicMainDiv .profileTxtIcon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  font-size: 55px;
  line-height: 85px;
}
.uploadPictureBtn {
  border: 0px;
  background: #034973;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 10px auto;
  cursor: pointer;
}
.dropzone {
  border-color: #333 !important;
  width: 95%;
}
.adminNewsUpdate .editor,
.adminNewsUpdate .form-control,
.adminNewsUpdate .form-group select.form-control-lg {
  border-color: #747474 !important;
}
.dropzone p {
  color: #333;
}
.createNewTollMain .descpPriceRateDiv .headingTxt {
  font-size: 18px !important;
  padding-left: 10px;
  font-weight: bold;
}
.driverregistration-section .createNewsGroupDiv .col-lg-6.col-sm-12:nth-child(even) .formlabeltxt {
  width: 100% !important;
}
.adminNewsUpdate .createNewsGroupDiv {
  width: 97%;
  display: inline-block;
}
.adminNewsUpdate .createNewsGroupDiv .blockDiv {
  width: 50%;
  float: left;
}

.adminNewsUpdate .createNewsGroupDiv .blockDivCopy {
  float: left;
}

.createNewTollMain .descpPriceRateDiv {
  background: #efefef;
  width: 95%;
  display: inline-block;
  border-radius: 5px;
}
.createNewTollMain .descpPriceRateDiv .blockDiv {
  width: 98%;
  padding-left: 10px;
}
.createNewTollMain .descpPriceRateDiv .blockDiv .form-group {
  width: 100%;
}
.createNewTollMain .descpPriceRateDiv .priceBlockDiv {
  width: 50%;
  display: inline-block;
  float: left;
  padding-left: 10px;
}
.adminTableListMain td,
.adminTableListMain th,
.adminTableMain td,
.adminTableMain th {
  vertical-align: middle;
  word-break: break-all;
}
.adminTableMain .emailIDTxt {
  word-break: break-all;
}
.adminNewUserMain .blockDiv {
  display: block;
  width: 100%;
}
.adminUpdatedUserMain {
  background: #fff;
}
.actionBtnMainDiv span {
  padding: 1px !important;
}
.adminUpdatedUserMain
  .driverregistration-section
  .col-lg-6.col-sm-12
  .formlabeltxt {
  display: block;
  width: 100% !important;
}
.adminUpdatedUserMain .driverregistration-section .inputDiv {
  display: block;
  width: 95% !important;
}
.adminNewsUpdate {
  padding-bottom: 30px;
}
.adminNewsUpdate .submitform {
  margin: 20px auto;
  display: block;
}
.updateProfileMainDiv .blockDiv {
  width: 50%;
  display: inline-block;
  float: left;
}
.updateProfileMainDiv .blockDiv .formlabeltxt {
  width: 100% !important;
  display: block;
}
.updateProfileMainDiv .blockDiv .inputDiv {
  width: 95% !important;
}
/* Chrome, Safari, Edge, Opera */
input.inputNumber::-webkit-outer-spin-button,
input.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.inputNumber[type="number"] {
  -moz-appearance: textfield;
}

.inputPwdDiv {
  position: relative;
}
.statusBtn {
  border-radius: 5px;
  border: 0px solid #333;
  color: #fff;
  padding: 5px 10px;
  font-weight: 600;
}
.redBtnColor {
  background: rgb(167, 44, 44);
}
.greenBtnColor {
  background: rgb(44, 167, 116);
}
.yellowBtnColor {
  background: rgb(179, 165, 46);
}
.blueBtnColor {
  background: rgb(44, 77, 167);
}
.aquaBtnColor {
  background: rgb(1, 104, 104);
}
.inputPwdDiv .fa {
  position: absolute;
  right: 10px;
  top: 0;
  margin-top: 12px;
}
.adminLmcBtn .saveBtn {
  background-color: #0d58b4;
  border: none;
  color: #fff;
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin: 40px auto;
  display: block;
  border-radius: 10px;
  text-transform: capitalize;
}
.dashboardInfoDetails .userPicDiv {
  width: 100px;
  float: left;
  display: inline-block;
}

.userprofileBtnDiv .btn,
.userprofileBtnDiv .btn:hover {
  color: #fff !important;
  background-color: #0d58b4 !important;
  border-color: #0d58b4 !important;
  align-items: center;
  padding: 5px 10px !important;
  font-size: 12px !important;
  margin-right: 10px;
  margin-top: 10px;
}
.descriptioneNewsAndUpdate p {
  display: contents;
}
.adminTableMain .titleTxt,
.userOtherData .mobileTxt {
  text-transform: capitalize;
}
.userOtherData .companyNameTxt {
  text-transform: capitalize;
}

.userOtherData span {
  margin-right: 10px;
}
.adminLMCUploadDiv {
  position: relative;
}

.adminLMCUploadDiv .uploadCloseBtn {
  position: absolute;
  top: 5px;
  right: 4px;
}
.adminLMCUploadDiv .uploadCloseBtn,
.adminCloseBtn .close {
  height: 30px;
  width: 30px;
  text-align: center;
  border: 0;
  background: #8f3c3c;
  color: #fff;
  padding: 0 0 0px 1px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 24px;
}
.form-group,
.input-group,
select,
input {
  cursor: pointer !important;
}
.editor {
  margin: 0px auto !important;
}
.showDateTime {
  margin-left: 0px !important;
}
.marineTerminalAdminMain .form-group.editorInputBox {
  width: 98%;
}
.form-control.showDateTime:disabled {
  width: 99.6% !important;
  height: auto;
  margin: 1px;
}
b.hideTypeDate {
  width: 90%;
  background: transparent;
  display: inline-block;
  height: 100%;
  z-index: 999;
  position: absolute;
  float: left;
}
.dashboardInfoDetails .userPicDiv img {
  border: 1px solid #076d8e;
  background: #fff;
  border-radius: 50px;
  width: 90px;
  height: 90px;
  display: inline-block;
  text-align: center;
  padding: 0;
  object-fit: contain;
  object-position: center;
}
.rightSideNavDiv .avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50em;
  object-fit: contain;
  border: 1px solid #bdbdbd;
}
.dashboardInfoDetails .userPicDiv .profileTxtIcon {
  text-transform: uppercase;
  border: 1px solid #076d8e;
  background: #076d8e;
  color: #fff !important;
  border-radius: 50px;
  font-size: 50px;
  width: 90px;
  height: 90px;
  display: inline-block;
  text-align: center;
  line-height: 85px;
  padding: 0;
}
.dashboardInfoDetails .userDataInfo {
  display: inline-block;
  width: 91.6%;
  text-align: left;
}
 
.boxMainDiv .card {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.profileTxtIcon {
  text-transform: uppercase;
  border: 1px solid #076d8e;
  background: #076d8e;
  color: #fff !important;
  padding: 3px 10px;
  border-radius: 50%;
  display: inline-block;
}
.ImgViewMainDiv {
  width: 100% !important;
  height: 200px !important;
  text-align: center !important;
  position: relative;
}
.ImgViewMainDiv .viewInnerDiv {
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImgViewMainDiv .viewInnerDiv img {
  height: 100%;
}
.ImgViewMainDiv .uploadCloseBtn {
  position: absolute;
  right: 5px;
  height: 30px;
  width: 30px;
  border: 0;
  background: #9d4e4e;
  color: #fff;
}
.tableMainDiv .titleTxt,
.detailsMainPage .titleTxt {
  text-transform: capitalize;
}
.detailsMainPage {
  padding: 0px !important;
}
.detailsMainPage .backBtn {
  border: 0;
  background: #0068b5;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
}
.detailsMainPage .backBtn i {
  padding-right: 5px;
}
.detailsMainPage .userPicDiv {
  display: block;
  float: left;
}
.detailsMainPage .userDetailsList .col-sm-3 {
  height: 40px;
}
.tileDivBox .card-body div div:first-child {
  font-size: 50px !important;
  font-weight: 900 !important;
  line-height: 110%;
}
.tileDivBox .boxMainDiv .card-body {
  height: 130px;
}
.tileDivBox .card-body div div:last-child {
  font-size: 22px !important;
  font-weight: bold !important;
  margin: -5px 0 10px 0;
  /*line-height: normal;
  height: 120px;*/
  line-height: 110%;
  vertical-align: sub;
  display: flex;
  width: 100%;
  min-height: 60px;
  height: auto;
  align-items: center;
}
.sidebar.show a.active,
.sidebar.show a:hover {
  color: #b0e3f4 !important;
}
.sidebar.show a.active,
.sidebar.show a:hover {
  color: #f9b115 !important;
}
.sidebar.show a.active::after {
  content: "";
  height: 5px;
  width: 100%;
  background-color: #f9b115;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.tabsNavMenuLink .nav-link {
  color: #333 !important;
}
.tabsNavMenuLink .nav-link.active {
  color: #076d8e !important;
}
#controlled-tab-example-tabpane-profile,
#controlled-tab-example-tabpane-edit {
  background: #fff;
}
.boxMainDiv {
  flex: 25% !important;
}
.sidebar .sidebar-brand {
  background: #fff;
  border-right: 0px solid #3c4b64;
  width: 99.8%;
}
.sidebar .sidebar-brand img {
  width: 62px;
}

.adminNewsUpdate textarea {
  width: 100% !important;
}
.adminNewsUpdate .textAreaClass {
  width: 100% !important;
  height: 200px;
}

.adminNewsUpdate .formlabeltxt {
  width: 100% !important;
}
.adminCreateAdmin .col-form-label {
  width: 100% !important;
  display: block;
}
.adminCreateAdmin .form-group div {
  width: 100% !important;
  display: block;
}
.adminCreateAdmin .container {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
  width: 93%;
}
.adminNewsUpdate .marineGateInfoDiv .form-group {
  width: 97.5%;
}
.adminCreateAdmin .form-group,
.adminNewsUpdate .form-group,
.adminNewsUpdate .form-group .col-sm-6.col-lg-6 {
  width: 95%;
}
.nav-tabs a.active::after {
  content: "";
  height: 0.125rem;
  width: 100%;
  background-color: #076d8e;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
/*Parking Div*/
.bookedParkingMainDiv .parkingSearchMain {
  min-height: 200px;
}
.bookedParkingMainDiv .recordDataListMain {
  display: block;
}
.bookedParkingMainDiv .recordDataListMain ul li {
  width: 100%;
  padding: 10px;
  position: relative;
}
.parkingTitleDiv .statustxt .statusBtn.activeBtnColor {
  color: #0c9b1c !important;
  background: transparent !important;
}
.parkingTitleDiv .statustxt .statusBtn.cancelBtnColor{
  color: #f96565 !important;
  background: transparent !important;
}
.activeBtnColor {
  background: #bff071 !important;
  color: #000000 !important;
}
.cancelBtnColor {
  background: #fe8f8f !important;
  color: #000000 !important;
}
.completedBtnColor {
  background: #d5d5d5 !important;
  color: #000000 !important;
}
.errorBtnColor {
  background: #bcbcbc !important;
  color: #000000 !important;
}
.activetxtColor {
  color: #bff071 !important;
}
.canceltxtColor {
  color: #fe8f8f !important;
}
.completedtxtColor {
  color: #000 !important;
}
.errortxtColor {
  color: #bcbcbc !important;
}
.notActivetxtColor {
  color: #bfc324 !important;
}
.bookedParkingMainDiv .searchFilterDiv {
  background: #fff;
  padding: 20px 10px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 0 25.4px 1.6px rgba(0, 0, 0, 0.09);
}

.bookedParkingMainDiv.parkingNewMainDiv .searchFilterDiv input {
  width: 68%;
}
.bookedParkingMainDiv .searchFilterDiv input {
  width: 85%;
  float: left;
  padding: 3px 10px;
  line-height: 30px;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  background-color: #f9f9f9;
}
.bookedParkingMainDiv .searchFilterDiv select {
  margin-left: 10px;
  padding: 6px 10px;
  line-height: 30px;
  width: 15%;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  background-color: #f9f9f9;
  float: right;
}

.bookedParkingMainDiv .searchFilterDiv .serachOption {
  text-align: right;
  display: inline-block;
  width: 15%;
}
.bookedParkingMainDiv .searchFilterDiv .serachOption button {
  border-radius: 5px;
  box-shadow: 0 0 25.4px 1.6px rgb(0 0 0 / 9%);
  background-color: #1c9ae2;
  border: 0;
  color: #fff;
  padding: 7px 10px;
  width: 90%;
  display: inline-block;
  position: relative;
  top: 0px;
}
.bookedParkingMainDiv
  .recordDataListMain
  ul
  li
  .boxCard
  .parkingImgDiv.existingReservationsDiv {
  height: 220px;
}
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul
  li
  .parkingDetailList
  .statusTxtDiv
  span {
  font-weight: bold !important;
}
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul
  li
  .parkingDetailList
  .activetxtColor {
  color: #30c700 !important;
}
.bookedParkingMainDiv .recordDataListMain ul li .boxCard .parkingImgDiv {
  position: relative;
  height: 300px;
  width: 12%;
  float: left;
}
.bookedParkingMainDiv
  .recordDataListMain.parkingDetailsMainDiv
  ul
  li
  .boxCard
  .parkingImgDiv {
  height: 500px;
}

.bookedParkingMainDiv .recordDataListMain ul li .boxCard .parkingImgDiv img {
  border-radius: 10px;
}
.bookedParkingMainDiv .recordDataListMain ul li .boxCard .parkingInfoDiv {
  padding: 0px;
  float: right;
  width: 88%;
  position: relative;
}
.bookedParkingMainDiv .searchFilterDiv .titleHeadingTxt {
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
}
.bookedParkingMainDiv .recordDataListMain ul li {
  color: #000;
  border-radius: 10px;
  box-shadow: 0 0 25.4px 1.6px rgba(0, 0, 0, 0.09);
  background: #fff;
  margin-bottom: 10px;
}
.bookedParkingMainDiv .recordDataListMain ul li .boxCard {
  width: 100%;
  border: 0;
}
.parkingTitleDiv {
  font-size: 18px;
  color: #000;
  position: relative;
  z-index: 9;
}
.shareBtnDivDetails {
  display: inline-block;
}
.parkingNewMainDiv .bookParkingComponentDivUL {
  position: relative;
}
.parkingNewMainDiv .shareButtonDiv {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
}
.parkingNewMainDiv .shareButtonDiv button {
  border: 0;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #0d58b4;
  border-color: #0d58b4;
  align-items: center;
  z-index: 999;
  position: relative;
}

.bookedParkingMainDiv
  .recordDataListMain
  ul
  li
  .boxCard
  .parkingInfoDiv
  .parkingTitleDiv
  .parkingTitleTxt {
  border: 0;
}
.parkingTitleDiv .parkingTitleTxt label,
.parkingTitleDiv .parkingTitleTxt span {
  font-size: 24px;
  color: #000;
  padding: 2px 10px;
}
.parkingTitleDiv .bookingTypeTxt label,
.parkingTitleDiv .statustxt label {
  color: #000;
  padding: 2px 10px;
}
.parkingTitleDiv .bookingTypeTxt span,
.parkingTitleDiv .statustxt span {
  color: #0c9b1c;
}
.bookedParkingMainDiv .parkingDetailMainDiv {
  padding: 10px;
}
.bookedParkingMainDiv .parkingDetailMainDiv ul {
  list-style: none;
  padding: 0px;
  height: auto;
}

.bookedParkingMainDiv .existingReservationsMainDiv ul.listBookedParkingDiv li {
  width: 25% !important;
}
.bookedParkingMainDiv .parkingPaymentMainDiv ul.listBookedParkingDiv li {
  width: 25% !important;
  height: 70px !important;
}

.bookedParkingMainDiv .securityDepositDetail ul.listBookedParkingDiv li {
  width: 25% !important;
  height: 70px !important;
}
.bookedParkingMainDiv
  .recordDataListMain.parkingPaymentMainDiv
  ul
  li
  .boxCard
  .parkingImgDiv {
  height: 400px;
}

.bookedParkingMainDiv
  .recordDataListMain.securityDepositDetail
  ul
  li
  .boxCard
  .parkingImgDiv {
  height: 400px;
}

.bookedParkingMainDiv
  .recordDataListMain.parkingPaymentMainDiv
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(1) {
  width: 50% !important;
}

.bookedParkingMainDiv
  .recordDataListMain.securityDepositDetail
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(1) {
  width: 75% !important;
}


.bookedParkingMainDiv
  .recordDataListMain.existingReservationsMainDiv
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(1) {
  width: 100% !important;
  height: 60px;
}
.bookedParkingMainDiv
  .recordDataListMain.existingReservationsMainDiv
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(5),
.bookedParkingMainDiv
  .recordDataListMain.existingReservationsMainDiv
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(4),
.bookedParkingMainDiv
  .recordDataListMain.existingReservationsMainDiv
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3),
.bookedParkingMainDiv
  .recordDataListMain.existingReservationsMainDiv
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(2) {
  height: 80px;
  margin: 10px 0;
}
.bookedParkingMainDiv .parkingDetailMainDiv ul li {
  border-bottom: 1px solid #333;
  width: 19.5%;
  height: 60px;
  border-radius: 0;
  background: transparent;
  box-shadow: 0 0 0;
  color: #000;
  text-align: left;
  padding: 0;
  margin: 10px 0;
}

.parkingInfoDiv .parkingTitleDiv span.innerDiv {
  clear: both;
  width: 100%;
  display: inline-block;
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv .parkingTitleTxt {
  width: 50%;
  float: left;
  display: inline-block;
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv button {
  float: right;
  display: inline-block;
  border: 0;
  padding: 2px 10px;
  color: #fff;
  background: rgb(5, 31, 109);
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt .statusBtn {
  border: 0;
  padding: 5px 10px;
  color: #333;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt .statusBtn.active {
  background: rgb(131, 177, 79);
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt .statusBtn.cancel {
  background: rgb(201, 92, 79);
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt,
.parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt {
  width: 50%;
  display: inline-block;
}
.parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt {
  text-align: right;
}
/*
.bookedParkingMainDiv .parkingDetailMainDiv ul li:nth-last-child(3){
border-bottom: 0;
}*/

.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3),
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(2),
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:last-child {
  width: 97%;
  font-size: 14px;
  margin: 5px 0;
  height: auto;
  padding-bottom: 10px;
}

.bookedParkingMainDiv
  .securityDepositDetail
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3),
.bookedParkingMainDiv
  .securityDepositDetail
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(2),
.bookedParkingMainDiv
  .securityDepositDetail
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:last-child {
  margin: 10px 0 !important;
}

.bookedParkingMainDiv
  .recordDataListMain.parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3)
  label,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(2)
  label,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:last-child
  label,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3)
  span,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(2)
  span,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:last-child
  span {
  font-size: 14px !important;
}
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailsMainDiv
  ul.listBookedParkingDiv
  li:last-child {
  border: 0;
}
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li
  .parkingDetailList {
  width: 95%;
}
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3)
  .parkingDetailList
  label,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul
  li
  .parkingDetailList
  label {
  display: block;
  font-weight: 500;
  font-size: 14px;
}
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(3)
  .parkingDetailList
  span,
.bookedParkingMainDiv
  .recordDataListMain
  .parkingDetailMainDiv
  ul
  li
  .parkingDetailList
  span {
  font-size: 12px;
  color: #6a6a6a;
  display: block;
}
.bookedParkingMainDiv
  .recordDataListMain.finalPaymentParking
  .parkingDetailMainDiv
  ul
  li
  .parkingDetailList
  span {
  padding-right: 20px;
}

.parkingDetailsMainDiv .btnMainDiv {
  text-align: center;
  display: block;
  margin: 10px auto;
}
.parkingDetailsMainDiv .btnMainDiv button {
  border: 0;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #0d58b4;
  border-color: #0d58b4;
  align-items: center;
}
.parkingTitleDiv .parkingTitleTxt label,
.parkingTitleDiv .bookingTypeTxt label,
.parkingTitleDiv .statustxt label {
  font-weight: 500;
}
.textDangerAlertMsgDiv .text-danger {
  background: #dcdcdc;
  border-radius: 5px;
  height: auto;
  width: 100%;
  padding: 10px;
}
.bookedParkingMainDiv .recordDataListMain ul {
  list-style: none;
  padding: 0;
  height: auto;
  overflow-y: auto;
}
.parkingSearchMain {
  background-image: url("../src/assets/parkingBgImg.png");
  background-size: cover;
  background-position: center;
  height: auto;
  min-height: 36rem;
  position: relative;
  border-radius: 10px;
}

.searchOptDiv {
  width: 98%;
  position: absolute;
  background: #fff;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0 0 25.4px 1.6px rgba(0, 0, 0, 0.09);
  padding: 30px 10px;
}
.searchOptDiv .serachOption {
  width: 15.4%;
  display: inline-block;
}

.searchOptDiv .serachOption:last-child {
  width: 5%;
}
.searchOptDiv .serachOption label {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.searchOptDiv .serachOption input,
.searchOptDiv .serachOption select {
  border: 0;
  border-bottom: 1px solid #414040;
  background: transparent;
  width: 90%;
}
.searchFilterDiv .serachOption button i.fa,
.searchOptDiv .serachOption button i.fa {
  padding-right: 4px;
}
.searchOptDiv .serachOption button {
  border-radius: 5px;
  box-shadow: 0 0 25.4px 1.6px rgba(0, 0, 0, 0.09);
  background-color: #1c9ae2;
  border: 0;
  color: #fff;
  padding: 5px 10px;
  width: 90px;
  display: inline-block;
  position: relative;
  top: -10px;
}

.dataResultMain {
  display: inline-block;
  margin: 10px 0;
  width: 100%;
}

.dataResultMain .resultOptList {
  display: inline-block;
  float: left;
}
.dataResultMain .mapViewDiv {
  display: inline-block;
  float: left;
}

.resultOptList .resultShowListOpt {
  margin-top: 5px;
}
.resultOptList .resultShowListOpt .showResultTxt {
  font-size: 12px;
  display: inline-block;
  width: 37%;
  float: left;
  margin-top: 5px;
  font-weight: bold;
}
.resultOptList .resultShowListOpt .selectSortOpt {
  border-bottom: 1px solid #414040;
  display: inline-block;
  width: 30%;
  float: right;
  font-size: 14px;
}

.resultOptList .resultShowListOpt .selectSortOpt select {
  border: 0;
  background: transparent;
  float: right;
}
.resultOptList .resultShowListOpt .sortingIconDiv {
  display: inline-block;
  width: 90px;
  text-align: right;
  margin-right: 20px;
  margin-top: -5px;
  float: right;
}

.resultOptList .resultShowListOpt .sortingIconDiv img {
  width: 32px;
}

.recordDataListMain {
  width: 100%;
  clear: both;
  margin-top: 10px;
  display: inline-block;
}
.recordDataListMain ul {
  list-style: none;
  padding: 0;
  height: 650px;
  overflow-y: auto;
}
.recordDataListMain ul li {
  list-style: none;
  padding: 0;
  width: 48%;
  float: left;
  margin-right: 2px;
  margin-top: 15px;
}

.recordDataListMain.parkingsingleview ul li {
  list-style: none;
  padding: 0;
  width: 100% !important;
  float: left;
  margin-right: 0px;
  margin-top: 15px;
  margin: 10px auto;
}
.recordDataListMain ul li:nth-child(even) {
  margin-right: 0;
}
.recordDataListMain ul li .boxCard {
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  width: 98%;
  text-align: left;
}

.recordDataListMain ul li .boxCard .parkingImgDiv {
  position: relative;
  height: 125px;
}
.recordDataListMain ul li .boxCard .parkingImgDiv .instantApprovalBtn {
  background-color: #0054d7;
  color: #fff;
}
.recordDataListMain ul li .boxCard .parkingImgDiv button {
  position: absolute;
  border-radius: 3px;
  background-color: #fc0;
  color: #000;
  border: 0;
  font-size: 11px;
  padding: 3px 5px;
  margin: 10px;
}

.recordDataListMain ul li .boxCard .parkingImgDiv img {
  width: 100%;
  object-fit: cover;
  object-position: center left;
  height: 100%;
}

.recordDataListMain ul li .boxCard .parkingInfoDiv h4 {
  font-size: 14px;
  padding: 5px;
  margin: 0;
  background: #cfd1d1;
}

.recordDataListMain ul li .boxCard .parkingInfoDiv {
  padding: 0px;
}
.recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo .blockBox {
  display: inline-block;
  width: 49.5%;
}
.recordDataListMain
  ul
  li
  .boxCard
  .parkingInfoDiv
  .paymentInfo
  .blockBox:last-child {
  margin-left: 1%;
  float: right;
}

.recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo label {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0px 5px;
  background: #6c7374;
  color: #fff;
}

.recordDataListMain
  ul
  li
  .boxCard
  .parkingInfoDiv
  .paymentInfo
  .blockBox:last-child {
  text-align: right;
}
.recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo b {
  display: block;
  font-size: 14px;
  padding: 0px 5px;
  background: #03b2dd;
}

.mapViewDiv .iframeMapDiv img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.positionRelative {
  position: relative;
}
.positionRelative .fa {
  top: 0 !important;
  margin-top: 15px !important;
}
.overFlowAutoDiv {
  overflow: auto;
}
.marineImgList {
  width: 100px;
}

.cameraImgList {
  width: 200px;
}
.profileDivDDown.nav-item .btn-primary {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.bookedParkingMainDiv .searchFilterDiv select:focus,
.profileDivDDown.nav-item .btn-primary:focus {
  outline: none !important;
  box-shadow: none !important;
}
.datePickerDiv {
  position: relative;
  z-index: 9;
  display: inline-block;
}
.datePickerDiv .datePickerInput::after {
  content: "\f073";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  z-index: 99;
}
.datePickerDiv .fa {
  position: absolute;
  right: 10px;
  top: 20%;
}
.form-control-lg {
  padding-left: 5px !important;
}
.headerBannerMainDiv {
  position: relative;
}
.searchHereDiv {
  padding-left: 15px !important;
  display: inline-block;
}
.headerBannerMainDiv b {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 40px;
  text-transform: uppercase;
  color: #fff;
  background: #000000b3;
  padding: 10px;
  border-radius: 0;
  box-shadow: 0 0 18px #c9c9c9;
  width: 100%;
  text-align: center;
}
.headerBannerMainDiv b:after {
  content: "";
  height: 5px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  top: 70px;
}
.gm-style-mtc ul {
  width: 100px;
}
.historicalTTTMainDiv .listOptionDiv .input-group {
  display: block;
}
/*-------Closed Admin CSS------*/
.mobileSearchIcon {
  color: #000;
  display: none;
  text-align: center;
  width: 70px;
  height: 40px;
}
.mobileSearchIcon i.fa {
  font-size: 26px;
  position: relative;
  top: 5px;
}
.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link:hover,
.sidebar.show a.active,
.sidebar.show a:hover {
  color: #f9b115 !important;
}
.sidebar-nav .nav-link.active::after {
  background-color: #f9b115 !important;
  content: "";
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  background-color: rgb(44 44 44 / 85%) !important;
  z-index: 999999999 !important;
  font-weight: bold !important;
}
.CompanyEstablishedDateInputDiv .calendar_icon {
  top: -7px;
  font-size: 21px;
}
.CompanyEstablishedDateInputDiv .react-datepicker button {
  margin: 0px;
}
.CompanyEstablishedDateInputDiv .react-datepicker__triangle {
  transform: translate3d(30px, 0px, 0px) !important;
}

.alertsMainDivPage
  .card.parentDivCard
  .collapse.show
  ul.newAlertVesselDiv
  li
  p.sty2,
.alertsMainDivPage
  .card.parentDivCard
  .collapse.show
  ul.newAlertVesselDiv
  li
  p.sty3 {
  font-size: 18px;
  padding-left: 0;
}
.hideReplyIcon{
  width: 20px;
  filter: opacity(0.5);
}
.adminCloseBtn.replyMailCloseBtn.modal-header{
    border-bottom:0;
    text-align: right;
    display: block;
}
.replyMailBodyDiv.modal-body{
  padding-top: 0;
  padding-bottom: 0;
}
.replyMailBodyDiv textarea{
  margin: 10px auto;
  height: 100px;
}
.replyMailBodyDiv b{
  padding: 5px 10px;
  border: 1px dashed #333;
  border-radius: 10px;
  margin: 10px;
  background: #e2e2e2;
}
.replyMailFooterDiv.modal-footer{
  border-top: 0;
  padding-top: 0;
}

/*----------Media Query------------*/

@media screen and (min-width: 992px) {
  .navcustomclass {
    position: relative;
    z-index: 2005 !important;
    width: 100%;
    top: 0px;
    padding-right: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
  }
  #myNav.overlay-toggel {
    display: none;
  }
  .gatehours-section span img {
    height: 50% !important;
    width: 50% !important;
  }
}
@media screen and (max-width: 1500px) {
  .fixedTopHeaderBar .blank_md8Div {
    width: 69%;
  }
  .resultOptList .resultShowListOpt .selectSortOpt {
    width: 32%;
    font-size: 13px;
    line-height: 24px;
  }
  .resultOptList .resultShowListOpt .selectSortOpt select {
    margin-top: 4px;
  }
}
@media screen and (max-width: 1400px) {
  .home-tools-section .newsInnerBoxDiv .imgDivBox {
    height: 165px;
  }
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(1),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(5),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(4),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(3),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(2) {
    height: 90px;
  }
  /*------Start LanguageString-Punjabi-------*/
  .punjabiString .fixedTopHeaderBar .blank_md8Div {
    width: 64%;
  }
  .punjabiString .fixedTopHeaderBar .col-md-9 {
    width: 71%;
  }
  .punjabiString .fixedTopHeaderBar .col-md-1 {
    width: 7%;
  }
  .punjabiString .fixedTopHeaderBar .col-md-1:last-child {
    width: 15%;
  }

  /*------End LanguageString-Punjabi-------*/

  /*------Start LanguageString-Spanish-------*/
  .spanishString .newVesselTabMainDiv .center-underline h2,
  .spanishString .newVesselTabMainDiv .titleTabOption h2 {
    padding: 0 20px 0 0;
  }
  .spanishString .newRecurringDate.searchOptDiv .serachOption {
    width: 25%;
  }
  .spanishString
    .newTrafficCameraTabMainDiv.trafficCameraMainDiv
    .col-5.titleTabOption {
    width: 38% !important;
  }
  .spanishString
    .newTrafficCameraTabMainDiv.trafficCameraMainDiv
    .col-4.titleTabOption {
    width: 38% !important;
  }
  .spanishString
    .newTrafficCameraTabMainDiv.trafficCameraMainDiv
    .col-3.titleTabOption {
    width: 24% !important;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-5.titleTabOption {
    width: 38% !important;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-4.titleTabOption {
    width: 38% !important;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-3.titleTabOption {
    width: 24% !important;
  }
  .spanishString .newVesselTabMainDiv.newVesselTabMainDiv .titleTabOption h2 b {
    width: 85%;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-3.titleTabOption
    h2
    b {
    width: 70%;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .titleTabOption
    h2
    span {
    top: -35px;
    position: relative;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-3.titleTabOption
    h2
    span {
    top: 0;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .titleTabOption
    h2:after {
    bottom: -100%;
  }
  .spanishString .alertsMainDivPage .card.parentDivCard .lastUpdateDiv h3 {
    width: 70%;
    text-align: left;
  }
  .spanishString
    .alertsMainDivPage
    .card.parentDivCard
    .lastUpdateDiv
    .listOptionDiv {
    width: 30%;
  }
  .spanishString
    .railCrossinglRoutesMainDiv
    .tabVesselDiv
    .titleTabOption:first-child {
    width: 50%;
  }
  .spanishString
    .railCrossinglRoutesMainDiv
    .tabVesselDiv
    .titleTabOption:last-child {
    width: 50%;
  }
  .spanishString
    .vesselMainPage
    .vesselTableMainDiv
    .titleTabOption:first-child {
    width: 50%;
  }
  .spanishString .vesselMainPage .vesselTableMainDiv .titleTabOption {
    width: 30%;
  }
  .spanishString
    .vesselMainPage
    .vesselTableMainDiv
    .titleTabOption:last-child {
    width: 20%;
  }
  .spanishString .CurrentWaitingTimeTxt {
    width: 70%;
    margin: auto;
  }
  .spanishString .marine-card-icon {
    bottom: 125px;
  }
  .spanishString .nav-fixed .navNewMenuDiv .navNewMenuListDiv li .nav-link {
    padding: 10px;
  }
  .spanishString .fixedTopHeaderBar .col-md-9 {
    width: 65%;
  }
  .spanishString .fixedTopHeaderBar .col-md-1 {
    width: 7%;
  }
  .spanishString .fixedTopHeaderBar .searchIconMainDiv {
    width: 70px;
  }
  .spanishString .fixedTopHeaderBar .col-md-1:last-child {
    width: 10%;
  }
  /*------End LanguageString-Spanish-------*/

  .news-video-div {
    height: 350px;
  }
  .dashboardInfoDetails .userDataInfo {
    display: inline-block;
    width: 85%;
    text-align: left;
  }
  .searchOptDiv .serachOption {
    width: 15%;
    display: inline-block;
  }
  .resultOptList .resultShowListOpt .selectSortOpt {
    width: 40%;
    float: left;
    font-size: 14px;
  }
  .resultOptList .resultShowListOpt .sortingIconDiv {
    width: 50%;
    margin-right: 10px;
    margin-top: 10px;
    float: right;
  }
  .resultOptList .resultShowListOpt .showResultTxt {
    width: 45%;
  }
  .searchOptDiv .serachOption:last-child {
    width: 5%;
  }
  .searchOptDiv .serachOption label {
    font-size: 12px;
  }
  input[type="checkbox"] {
    width: 20px;
  }
  .mobileOTPDiv .mobileInputDiv {
    width: 70% !important;
  }
  .mobileOTPDiv button {
    width: 30%;
  }
  .nav-fixed .navNewMenuDiv.navmob .navNewMenuListDiv li:nth-child(4) {
    width: 135px;
    margin: 0 35px 0 0;
  }

  .loginsection .form-section form {
    padding: 50px 20px 20px 20px !important;
  }
  .loginsection .form-section form .form-group {
    padding: 15px 10px !important;
  }
  .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li:last-child {
    width: auto;
  }
  .navNewMenuDiv .navNewMenuListDiv li:last-child {
    width: auto;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li:last-child {
    width: auto;
  }
  .navNewMenuDiv .navNewMenuListDiv li {
    line-height: 25px;
    width: 10%;
    text-align: center;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li {
    width: 12%;
  }
  .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 220px;
    margin: 0 40px 0 40px;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 135px;
    margin: 0 35px 0 25px;
  }
  .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li .customization_text2 {
    bottom: -20px;
    height: 140px;
    width: 160px;
  }
  .faqs-section .col-lg-8.col-xl-9 {
    width: 70%;
  }
  .resources-div {
    width: 220px;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt {
    width: 35%;
  }
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 39%;
  }

  .driverregistration-section .form-group .col-sm-6 {
    width: 60%;
  }
  .loginsection .form-section form .forgotBtn {
    margin: 5px 0;
    padding: 0 10px !important;
  }

  .marine-gallery-img {
    height: 225px;
  }
  .confirmPwdErrorDiv {
    width: 87%;
  }
  .trafficCameraMainDiv h2 {
    font-size: 26px !important;
  }
  .truckTurnTimeListOpt ul li .infoListDiv .fixedTxt {
    width: 95%;
  }
  .blogImg {
    height: 150px;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }
  #card_body {
    border-bottom-left-radius: 30px !important;
  }
  .marineterminal-home a:hover .marineimg-div:before {
    height: 150px;
  }
  .marineimg-div .imgDivBox {
    height: 150px;
  }
  .contactusDiv-footer .p-3 {
    padding: 8px !important;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1380px) {
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    width: 140px;
    font-size: 15px;
    margin-top: -5px;
  }
  header {
    height: 80vh;
  }
  .searchOptDiv .serachOption label {
    font-size: 11px;
  }
  .searchOptDiv .serachOption {
    width: 25%;
  }

  .tileDivBox .boxMainDiv .card-body {
    padding: 10px 5px;
  }
  .spanishString
    .historicalTTTMainDiv.bookedSpaceSearchMain
    .listOptionDiv
    .col-md-6:last-child {
    width: 100%;
  }
  .spanishString
    .bookedSpaceSearchMain
    .lastUpdateDiv
    .listOptionDiv
    button.searchBtn {
    float: none;
  }
  .spanishString
    .historicalTTTMainDiv.bookedSpaceSearchMain
    .listOptionDiv
    .col-md-6 {
    width: 33.3%;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv select {
    margin-left: 0;
    padding: 6px 10px;
    width: 155px;
  }
  .spanishString
    .bookedParkingMainDiv.parkingNewMainDiv
    .searchFilterDiv
    input {
    width: 65%;
  }
}
@media screen and (max-width: 1200px) {
  .contactusDiv-footer .p-3 {
    padding: 5px !important;
  }

  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    width: 130px;
    font-size: 10px;
    margin-top: -5px;
  }
  .bookedParkingMainDiv .parkingPaymentMainDiv ul.listBookedParkingDiv li {
    width: 33.3% !important;
    height: 70px !important;
    margin: 10px 0 !important;
  }

  .bookedParkingMainDiv .securityDepositDetail ul.listBookedParkingDiv li {
    width: 33.3% !important;
    height: 70px !important;
    margin: 10px 0 !important;
  }


  .bookedParkingMainDiv
    .recordDataListMain.parkingPaymentMainDiv
    ul
    li
    .boxCard
    .parkingImgDiv {
    height: 500px !important;
  }

  .bookedParkingMainDiv
  .recordDataListMain.securityDepositDetail
  ul
  li
  .boxCard
  .parkingImgDiv {
  height: 500px !important;
}


  .bookedParkingMainDiv
    .recordDataListMain.parkingPaymentMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(1) {
    width: 66.6% !important;
  }

  .bookedParkingMainDiv
  .recordDataListMain.securityDepositDetail
  .parkingDetailMainDiv
  ul.listBookedParkingDiv
  li:nth-last-child(1) {
  width: 66.6% !important;
}

  .bookedSpaceSearchMain .lastUpdateDiv .listOptionDiv button.searchBtn {
    font-size: 18px;
    margin-top: 29px;
    padding: 0px 10px !important;
  }
  .bookingSpaceCalendarResultDiv .col-md-3 {
    width: 25%;
  }
  .bookingSpaceCalendarResultDiv .col-md-6 {
    width: 100%;
    margin-top: 10px;
  }
  .bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
    width: 24.4%;
  }
  .fixedTopHeaderBar .blank_md8Div {
    width: 68%;
  }
  .newRecurringDate.searchOptDiv .serachOption,
  .newRecurringDate.searchOptDiv .serachOption.numberStallsDiv,
  .newRecurringDate.searchOptDiv .serachOption.equipmentLengthDiv {
    width: 33%;
  }
  .bookedParkingMainDiv .finalPaymentParking .parkingDetailMainDiv ul li {
    height: 70px;
  }
  .parkingDetailsNewMain .parkingTitleDiv .parkingTitleTxt label,
  .parkingDetailsNewMain .parkingTitleDiv .parkingTitleTxt span,
  .parkingDetailsNewMain .parkingTitleDiv .parkingTitleTxt label,
  .parkingDetailsNewMain .parkingTitleDiv .bookingTypeTxt label,
  .parkingDetailsNewMain .parkingTitleDiv .statustxt label,
  .parkingDetailsNewMain .parkingTitleDiv .bookingTypeTxt span,
  .parkingDetailsNewMain .parkingTitleDiv .statustxt span,
  .parkingNewMainDiv .parkingTitleDiv .parkingTitleTxt label,
  .parkingNewMainDiv .parkingTitleDiv .parkingTitleTxt span,
  .parkingNewMainDiv .parkingTitleDiv .parkingTitleTxt label,
  .parkingNewMainDiv .parkingTitleDiv .bookingTypeTxt label,
  .parkingNewMainDiv .parkingTitleDiv .statustxt label,
  .parkingNewMainDiv .parkingTitleDiv .bookingTypeTxt span,
  .parkingNewMainDiv .parkingTitleDiv .statustxt span {
    display: inline-block;
  }
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .statustxt {
    width: 30%;
  }
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .parkingTitleTxt {
    width: 70%;
  }
  .bookedParkingMainDiv.parkingNewMainDiv .searchFilterDiv input {
    width: 58%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv .serachOption,
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    width: 20%;
  }
  /*------Start LanguageString-Punjabi-------*/
  .punjabiString .fixedTopHeaderBar .blank_md8Div {
    width: 59%;
  }
  .punjabiString .fixedTopHeaderBar .col-md-9 {
    width: 65%;
  }
  .punjabiString .fixedTopHeaderBar .col-md-1 {
    width: 7%;
  }
  .punjabiString .fixedTopHeaderBar .col-md-1:last-child {
    width: 200px;
  }
  .punjabiString
    .bookedSpaceSearchMain
    .lastUpdateDiv
    .listOptionDiv
    button.searchBtn {
    font-size: 22px;
    margin-top: 34px;
    padding: 0px 10px !important;
  }
  /*------End LanguageString-Punjabi-------*/

  /*------Start LanguageString-Spanish-------*/
  .spanishString
    .bookedParkingMainDiv.parkingNewMainDiv
    .searchFilterDiv
    input {
    width: 55%;
  }
  .spanishString .bookedParkingMainDiv .finalPaymentParking .btnMainDiv button,
  .spanishString .waitingListParking .statustxt .cancelBtnDiv button {
    font-size: 18px;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv input {
    width: 80%;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv .serachOption {
    width: 20%;
  }
  .spanishString
    .bookedParkingMainDiv
    .waitingListParking
    .parkingTitleDiv
    .bookingTypeTxt {
    width: 60%;
  }
  .spanishString
    .bookedParkingMainDiv
    .waitingListParking
    .parkingTitleDiv
    .statustxt {
    width: 40%;
  }
  .spanishString .searchNdTotalDataDiv .totalTxtDiv,
  .spanishString .searchNdTotalDataDiv .resultOptList {
    width: 50%;
  }
  .spanishString .historicalTTTMainDiv .listOptionDiv label {
    display: flex;
    align-items: center;
    height: 50px;
  }
  .spanishString
    .newRecurringDate.searchOptDiv
    .serachOption.equipmentLengthDiv,
  .spanishString .newRecurringDate.searchOptDiv .serachOption {
    width: 33.3%;
  }
  .spanishString .searchOptDiv .serachOption {
    width: 33.3%;
    display: inline-block;
  }
  .spanishString .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li {
    width: 12%;
  }
  .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 12%;
    margin: 0 35px 0 35px;
    padding: 0px;
  }
  .spanishString
    .railCrossinglRoutesMainDiv
    .tabVesselDiv
    .titleTabOption:first-child {
    width: 60%;
  }
  .spanishString
    .railCrossinglRoutesMainDiv
    .tabVesselDiv
    .titleTabOption:last-child {
    width: 40%;
  }
  .spanishString
    .vesselMainPage.securitySafetyMain.CamerasInfoDiv
    .titleTabOption:first-child {
    width: 45%;
  }
  .spanishString
    .vesselMainPage.securitySafetyMain.CamerasInfoDiv
    .titleTabOption:last-child {
    width: 55%;
  }
  .spanishString
    .vesselMainPage.securitySafetyMain
    .titleTabOption:first-child {
    width: 40%;
  }
  .spanishString .vesselMainPage.securitySafetyMain .titleTabOption {
    width: 40%;
  }
  .spanishString .vesselMainPage.securitySafetyMain .titleTabOption:last-child {
    width: 20%;
  }
  .spanishString .vesselTableMainDiv .titleTabOption .center-underline h2,
  .spanishString .vesselTableMainDiv .titleTabOption h2 {
    font-size: 28px;
  }
  .spanishString .loginsection .col-lg-3 {
    display: none;
  }
  .spanishString .loginsection .col-lg-6.form-section {
    width: 70%;
    margin: auto;
    display: flex;
  }
  .spanishString .loginsection .form-section form .form-group .col-sm-10 {
    width: 70%;
  }
  .spanishString .portlink-div {
    /* width: 170px; */
    width: -webkit-fill-available;
  }
  .spanishString .CurrentWaitingTimeTxt {
    width: 90%;
    margin: auto;
  }
  .spanishString .marine-card-icon {
    bottom: 105px;
  }
  .spanishString .nav-fixed .navNewMenuDiv .navNewMenuListDiv li .nav-link {
    padding: 10px 0;
  }
  .spanishString .nav-fixed .navNewMenuDiv .navNewMenuListDiv li {
    width: 12%;
  }

  /*------End LanguageString-Spanish-------*/
  .innerDashboradMainDiv .col.boxMainDiv,
  .truckDriverRosterDiv .col.boxMainDiv {
    width: 50%;
  }
  .parkingDetailMainDiv ul {
    width: 100%;
  }
  .truckTurnTimeListOpt .historicalSearchList .infoListDiv {
    width: 33%;
  }
  .truckTurnTimeListOpt .historicalSearchList .infoListDiv label {
    font-size: 20px;
    width: 100%;
  }
  .truckTurnTimeListOpt .historicalSearchList .infoListDiv span {
    font-size: 16px;
  }
  .parkingTitleDiv .parkingTitleTxt label,
  .parkingTitleDiv .parkingTitleTxt span {
    display: inline-block;
    line-height: 24px;
  }
  .parkingTitleDiv .parkingTitleTxt label,
  .parkingTitleDiv .bookingTypeTxt label,
  .parkingTitleDiv .statustxt label,
  .parkingTitleDiv .bookingTypeTxt span,
  .parkingTitleDiv .statustxt span {
    display: inline-block;
  }
  .parkingTitleDiv .bookingTypeTxt span {
    padding-left: 0px;
  }
  .bookedParkingMainDiv
    .recordDataListMain.parkingDetailsMainDiv
    ul
    li
    .boxCard
    .parkingImgDiv {
    height: 660px;
  }
  .bannerLogoNoticeDiv .noticeDespDiv {
    width: 80%;
  }
  .vesselinportclass b.thTxt,
  .vesselestimationdiv b.thTxt {
    width: 100px;
    display: inline-block;
    position: relative;
    font-weight: normal;
    line-height: 20px;
  }
  .vesselinportclass b.thTxt .fa,
  .vesselestimationdiv b.thTxt .fa {
    position: absolute;
    right: -35%;
    bottom: 10%;
  }
  .overFlowAutoDiv table {
    width: 1200px;
  }
  .news-video-div {
    height: 90%;
  }
  .marineCameraModal .modal-content {
    height: 480px;
    margin-top: 60px;
  }
  .marineterminal-home a:hover .marineimg-div:before {
    height: 250px;
  }
  .blogImg {
    height: 250px;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }
  #card_body {
    border-bottom-left-radius: 30px !important;
  }
  .marineimg-div .imgDivBox {
    height: 250px;
  }
  .actionBtnMainDiv span {
    padding: 1px !important;
    display: block;
  }
  .bookedParkingMainDiv .parkingDetailMainDiv ul li {
    width: 33%;
  }
  .bookedParkingMainDiv .recordDataListMain ul li .boxCard .parkingImgDiv {
    height: 440px;
  }
  .searchOptDiv .serachOption input,
  .searchOptDiv .serachOption select {
    font-size: 14px;
  }
  .searchOptDiv .serachOption label {
    font-size: 12px;
  }
  .searchOptDiv .serachOption {
    width: 33%;
  }
  .resultOptList .resultShowListOpt .selectSortOpt {
    width: 125px;
  }
  .resultOptList .resultShowListOpt .showResultTxt {
    width: 100%;
  }
  .resultOptList .resultShowListOpt .sortingIconDiv {
    margin-top: -5px;
    width: 100px;
  }
  .recordDataListMain ul li .boxCard .parkingInfoDiv h4 {
    font-size: 12px;
    height: 35px;
  }
  .recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo label {
    font-size: 12px;
  }
  .recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo b {
    font-size: 10px;
  }
  .searchOptDiv .serachOption:last-child {
    width: 100% !important;
    text-align: center;
  }
  .searchOptDiv .serachOption button {
    top: 20px;
  }
  .boxMainDiv {
    flex: 48% !important;
  }
  .tileDivBox .card-body div:last-child {
    width: 100%;
    font-size: 20px !important;
  }
  .marine-container .marine-card .camera-txt {
    height: 120px;
  }

  .truckTurnTimeListOpt ul li .infoListDiv {
    width: 25%;
    margin-right: 0%;
    margin-left: 0%;
  }

  .truckTurnTimeListOpt ul li .infoListDiv .fixedTxt {
    width: 100%;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 {
    font-size: 125px;
    padding: 0px 0 0 0;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 img {
    margin: 0 0 0 100px;
  }
  .currentTempStatusDiv ul li b.timeTxt {
    font-size: 18px;
  }
  .marine-gallery-img {
    height: 200px;
  }
  .driverregistration-popup.cameraModalDiv {
    width: 500px;
  }
  .marineterminal-head-section .terminal-name {
    font-size: 40px;
    padding: 20px 0 0 0;
    display: none !important;
  }
  .marineterminal-head-section .text-end.terminalImgDiv {
    text-align: left !important;
    padding-left: 5%;
  }
  .marineterminal-head-section .terminalImgDiv img {
    width: 250px;
  }
  header {
    height: 75vh;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 90px;
    margin: 0 60px 0 34px;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li .customization_text {
    bottom: -20px;
    height: 130px;
    width: 150px;
  }
  .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li .customization_text2 {
    bottom: -20px;
    height: 130px;
    width: 140px;
  }
  .nav-fixed .navNewMenuDiv .customization_text2 img {
    width: 110px;
    height: auto;
    margin-top: 20px;
  }
  .navNewMenuDiv .navNewMenuListDiv li .customization_text {
    bottom: -35px;
    height: 180px;
    width: 180px;
  }
  .navNewMenuDiv .navNewMenuListDiv li {
    padding-top: 50px;
  }
  .homenav .customization_text img {
    width: 140px;
    height: auto;
    margin-top: 35px;
  }
  .loginsection .form-section form .form-group {
    display: inline-block;
    width: 100%;
  }
  .loginsection .form-section form .form-group .col-sm-10 {
    width: 80%;
    float: right;
    display: inline-block;
  }
  .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 105px;
    margin: 0 35px 0 0px;
    padding: 0px 0 0 0px;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li {
    width: 11%;
    padding-top: 0px;
  }
  .nav-fixed .navmob.navNewMenuDiv .navNewMenuListDiv li {
    width: 12%;
    padding-top: 0px;
  }
  .navNewMenuDiv .navNewMenuListDiv li:last-child {
    width: auto;
  }
  .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 160px;
    margin: 0 30px 0 40px;
  }
  .navNewMenuDiv .navNewMenuListDiv li {
    line-height: 25px;
    width: 10%;
    text-align: left;
    padding-top: 50px;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt {
    width: 35%;
  }
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 44%;
  }

  .driverregistration-section .form-group .col-sm-6 {
    width: 55%;
  }
  .contactusDiv .rightDivInput {
    width: 47%;
  }
  .contactusDiv .rightDivInput {
    margin: 15px 0px 0px -2px;
  }
  .resources-div {
    width: 200px;
  }
  .legalnotice-div {
    width: 180px;
  }
  .portlink-div {
    /* width: 170px; */
    width: -webkit-fill-available;
  }
  .news-head {
    width: 100% !important;
    height: auto !important;
    display: inline-block;
  }
  .txt-overflow {
    width: 100% !important;
  }
  .faqs-section .col-lg-8.col-xl-9 {
    width: 65%;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 95%;
  }
  .faqs-section .titleTxtDiv {
    width: auto;
  }
  .faqs-section .viewBtnDiv {
    float: right;
    width: 100px;
    position: absolute;
    right: 0;
    margin: 40px 0;
  }
  .faqs-section .row.postionRelative {
    position: relative;
  }
  .tools-row1 {
    height: 240px;
    margin-top: 0px !important;
  }
  .center-underline h2,
  .titleTabOption h2 {
    padding: 0 10px 0 0;
  }
  .speedometerMainDiv .currentwaittime-head label {
    display: block;
  }
  .tableOverFlow {
    width: 100%;
    display: block;
    overflow: auto;
  }
  .weatherForecastDailyDiv ul li span .txtDescp {
    font-size: 24px;
    line-height: 26px;
  }
  input[type="checkbox"] {
    width: 25px;
  }

  .extraLi{
    display: none;
  }
}
@media screen and (max-width: 1050px) {
  .userReportMainDiv .searchFilterMainDiv .col-md-6:last-child {
    width: 17%;
  }
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv {
    padding: 10px 5px;
  }
  .userReportMainDiv .tableOverFlow thead td {
    width: 10.5%;
  }
  .punjabiString
    .bookedSpaceSearchMain
    .lastUpdateDiv
    .listOptionDiv
    button.searchBtn {
    font-size: 20px;
    margin-top: 36px;
    padding: 0px 10px !important;
  }
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(5),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li {
    width: 33.3% !important;
    height: 70px;
  }

  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(4),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(3),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(2) {
    height: 100px;
  }
  .bookedSpaceSearchMain .lastUpdateDiv .listOptionDiv button.searchBtn {
    font-size: 18px;
    margin-top: 29px;
    padding: 0px 7px !important;
  }
  .bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
    width: 24.3%;
  }
  .punjabiString .fixedTopHeaderBar .blank_md8Div {
    width: 55%;
  }
  .fixedTopHeaderBar .blank_md8Div {
    width: 67%;
  }
  .bookedParkingMainDiv .finalPaymentParking .parkingDetailMainDiv ul li {
    height: 80px;
  }
  .marineTerminalAdminMain .form-group.editorInputBox,
  .adminNewsUpdate .marineGateInfoDiv .form-group {
    width: 95%;
  }
  .recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo label {
    font-size: 11px;
  }
  .recordDataListMain ul li .boxCard .parkingInfoDiv .paymentInfo b {
    font-size: 10px;
  }
  .parkingTitleDiv .parkingTitleTxt label,
  .parkingTitleDiv .parkingTitleTxt span {
    font-size: 20px;
  }
  .dashboardInfoDetails .userDataInfo {
    width: 80%;
  }
  .updateProfileMainDiv .blockDiv {
    width: 100%;
    display: inline-block;
    float: left;
  }
  .marineTerminalAdminMain .adminNewsUpdate .col-lg-6 {
    width: 100% !important;
  }
  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li {
    width: 10%;
  }
  .faqs-section .divBoxNumber {
    width: 140px;
    padding: 0;
    margin: auto;
  }
  .faqs-section .divBoxNumber .faqs-num {
    line-height: 100%;
  }
  .faqs-section .col-lg-8.col-xl-9 {
    width: 100%;
  }
  .resources-div {
    width: 210px;
  }

  .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    width: 160px;
    margin: 0 30px 0 50px;
    padding-top: 50px;
  }
  .navNewMenuDiv .navNewMenuListDiv .homenav .customization_text img {
    width: 140px;
    height: auto;
    margin-top: 35px;
  }
  .navNewMenuDiv .navNewMenuListDiv li .customization_text {
    bottom: -35px;
    height: 180px;
    width: 180px;
  }
  .navNewMenuDiv .navNewMenuListDiv li {
    width: 9.5%;
    text-align: center;
  }
  .nav-fixed .navNewMenuDiv .customization_text2 img {
    width: 110px !important;
    margin-top: 20px !important;
  }

  .nav-fixed .navNewMenuDiv .navNewMenuListDiv li:nth-child(4) {
    padding-top: 10px;
    width: 90px;
    margin: 0 60px 0 40px;
  }
  .bookingDetailModal
    .modal-body
    .bookedParkingMainDiv
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .parkingTitleTxt,
  .bookingDetailModal
    .modal-body
    .bookedParkingMainDiv
    .recordDataListMain
    ul
    li {
    width: 100%;
  }
  .bookingDetailModal
    .modal-body
    .bookedParkingMainDiv
    .parkingDetailMainDiv
    ul
    li {
    width: 33% !important;
  }
  .bookingDetailModal
    .modal-body
    .bookedParkingMainDiv
    .parkingDetailMainDiv
    ul
    li:nth-child(even) {
    float: left;
  }
  .dataResultMain .resultOptList,
  .dataResultMain .mapViewDiv {
    width: 100%;
    float: none;
    display: block;
  }
  .dataResultMain .resultOptList .recordDataListMain ul.ParkingSearchListMain {
    height: auto;
  }
  .dataResultMain
    .resultOptList
    .recordDataListMain
    ul.ParkingSearchListMain
    li.ParkingSearchListOpt {
    width: 49%;
  }
  .dataResultMain
    .resultOptList
    .recordDataListMain
    ul.ParkingSearchListMain
    li.ParkingSearchListOpt:nth-child(even)
    .boxCard {
    float: right;
  }
  .dataResultMain
    .resultOptList
    .recordDataListMain
    ul.ParkingSearchListMain
    li.ParkingSearchListOpt:nth-child(even) {
    margin-right: 0;
    float: right;
  }
  .newRecurringDate.searchOptDiv .serachOption {
    width: 33%;
  }
  .recordDataListMain ul li .boxCard {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .headerBannerMainDiv .banner_img {
    margin-top: 0px !important;
  }
  .bookingSpaceCalendarResultDiv .col-md-3 {
    width: 50%;
    margin: 10px 0;
  }
  .breadCumNewDiv {
    margin-top: 60px;
  }
  .newsDetailsMainDiv .news-content ol,
  .newsDetailsMainDiv .news-content ul {
    display: block;
  }
  .bookingSpaceCalendarResultDiv h6 {
    text-align: center;
  }
  .historicalTTTMainDiv.bookedSpaceSearchMain .listOptionDiv button {
    float: none;
  }
  .historicalTTTMainDiv.bookedSpaceSearchMain
    .listOptionDiv
    .col-md-6:last-child {
    width: 100%;
  }
  .historicalTTTMainDiv.bookedSpaceSearchMain .listOptionDiv .col-md-6 {
    width: 33%;
    margin-top: 10px;
  }
  .userReportMainDiv .searchFilterMainDiv .listOptionDiv .col-md-6 {
    width: 50%;
  }
  .bookedSpaceSearchMain .lastUpdateDiv .listOptionDiv button.searchBtn {
    margin-top: 0px;
    padding: 0px 12px !important;
  }
  .bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
    width: 24%;
  }
  .mobileSearchIcon {
    display: inline-block;
  }
  .newRecurringDate.searchOptDiv .serachOption,
  .newRecurringDate.searchOptDiv .serachOption.numberStallsDiv,
  .newRecurringDate.searchOptDiv .serachOption.equipmentLengthDiv,
  .searchOptDiv .serachOption,
  .searchOptDiv .serachOption.numberStallsDiv,
  .searchOptDiv .serachOption.equipmentLengthDiv {
    width: 50%;
    height: 60px;
  }
  .bookedParkingMainDiv .finalPaymentParking ul li .parkingDetailMainDiv ul li {
    width: 33.3%;
  }
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .statustxt {
    width: 35%;
  }
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .parkingTitleTxt {
    width: 65%;
  }
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .bookingTypeTxt
    label,
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .bookingTypeTxt
    span,
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .statustxt
    label,
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .statustxt
    span {
    display: inline-block;
  }
  .parkingTitleDiv .parkingTitleTxt span,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt span {
    padding-left: 0 !important;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv input {
    width: 58%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv .serachOption,
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    width: 20%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv .serachOption button,
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    padding: 7px 5px;
  }
  .searchSectionMain .headerBannerMainDiv {
    position: relative;
    margin-top: 15px;
    height: 150px;
  }
  .headerBannerMainDiv b {
    top: 82%;
  }
  .removedOverlayTrigger.alertInnerDivList li span,
  .alertAnnouncementsMain .leftDescpDiv {
    width: 80%;
  }
  .removedOverlayTrigger.alertInnerDivList li .buttonDiv,
  .alertAnnouncementsMain .rightCopyDiv {
    width: 20%;
  }
  .adminNewsUpdate .createNewsGroupDiv .blockDiv .formlabeltxt,
  .driverregistration-section
    .createNewsGroupDiv
    .col-lg-6.col-sm-12:nth-child(odd)
    .formlabeltxt {
    width: 100% !important;
  }
  .vesselMapTab.osowInfoDiv .human-resources-section {
    margin-top: 0;
  }
  /*------Start LanguageString-Punjabi-------*/
  .punjabiString .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 40%;
  }
  .punjabiString .currentWaitingTimeDiv .tabOptionCWT {
    width: 25%;
  }
  .punjabiString .tools-section .toolshover .card1 h6 {
    font-size: 18px;
  }
  .punjabiString .tools-section .toolshover .card1 .hoverToolDescp {
    font-size: 16px;
  }
  .punjabiString
    .bookedSpaceSearchMain
    .lastUpdateDiv
    .listOptionDiv
    button.searchBtn {
    font-size: 20px;
    margin-top: 15px;
    padding: 0px 10px !important;
  }
  /*------End LanguageString-Punjabi-------*/
  /*------Start LanguageString-Spanish-------*/
  .spanishString
    .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    .serachOption {
    width: 120px;
  }
  .spanishString
    .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    select {
    width: 145px;
  }
  .spanishString
    .bookedParkingMainDiv.parkingNewMainDiv
    .searchFilterDiv
    input {
    width: 45%;
  }
  .spanishString
    .bookedParkingMainDiv
    .waitingListParking
    .parkingTitleDiv
    .bookingTypeTxt,
  .spanishString
    .bookedParkingMainDiv
    .waitingListParking
    .parkingTitleDiv
    .statustxt {
    width: 100%;
    text-align: left;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv input {
    width: 75%;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv .serachOption {
    width: 25%;
  }
  .spanishString .searchNdTotalDataDiv .totalTxtDiv,
  .spanishString .searchNdTotalDataDiv .resultOptList {
    width: 100%;
    text-align: center;
  }
  .spanishString .bookingSpaceCalendarResultDiv .searchNdTotalDataDiv h6,
  .spanishString
    .bookingSpaceCalendarResultDiv
    .searchNdTotalDataDiv
    .searchFilterDiv {
    text-align: center !important;
  }
  .spanishString
    .bookingSpaceCalendarResultDiv
    .searchNdTotalDataDiv
    .searchFilterDiv
    .serachOption {
    float: none;
    margin-left: 10px;
    position: relative;
    margin-top: 10px;
  }
  .spanishString
    .newRecurringDate.searchOptDiv
    .serachOption.equipmentLengthDiv,
  .spanishString .newRecurringDate.searchOptDiv .serachOption {
    width: 50%;
  }
  .spanishString .searchOptDiv .serachOption {
    width: 50%;
    display: inline-block;
  }
  .spanishString .newVesselTabMainDiv.newVesselTabMainDiv {
    margin-bottom: 20px;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .titleTabOption
    h2
    span {
    float: none;
    top: 0;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .titleTabOption
    h2:after {
    bottom: -70%;
  }
  .spanishString .newVesselTabMainDiv .center-underline h2,
  .spanishString .newVesselTabMainDiv .titleTabOption h2 {
    padding: 10px 20px 0 0;
    text-align: center;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-3.titleTabOption
    h2
    b,
  .spanishString .newVesselTabMainDiv.newVesselTabMainDiv .titleTabOption h2 b {
    width: 95%;
    text-align: center;
  }
  .spanishString .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 40%;
  }
  .spanishString .currentWaitingTimeDiv .tabOptionCWT {
    width: 25%;
  }
  .spanishString .reportTabsMainDiv .nav-tabs li.nav-item {
    width: 33%;
  }
  .spanishString .alertsMainDivPage .card.parentDivCard .lastUpdateDiv h3 span {
    display: block;
  }
  .spanishString .historicalTTTMainDiv .listOptionDiv .col-md-6 {
    width: 33%;
  }
  .spanishString .historicalTTTMainDiv .listOptionDiv .col-md-6:last-child {
    width: 100%;
  }
  .spanishString .historicalTTTMainDiv .listOptionDiv button {
    float: none;
  }
  .spanishString
    .truckTurnTimeListOpt
    .historicalSearchList
    .infoListDiv
    label {
    height: 60px;
  }
  .spanishString
    .vesselMainPage
    .vesselTableMainDiv
    .titleTabOption:first-child {
    width: 45%;
  }
  .spanishString .vesselMainPage .vesselTableMainDiv .titleTabOption {
    width: 30%;
  }
  .spanishString
    .vesselMainPage
    .vesselTableMainDiv
    .titleTabOption:last-child {
    width: 25%;
  }
  .spanishString .selectUserTypeMainDiv .driverTitleDiv h2 {
    height: 150px;
  }
  .spanishString .loginsection .col-lg-6.form-section {
    width: 90%;
  }
  /*------ End LanguageString-Spanish-------*/

  .truckDriverRosterDiv .col.boxMainDiv {
    width: 50%;
  }
  .truckTurnTimeListOpt .historicalSearchList .infoListDiv {
    width: 50%;
  }
  .historicalTTTMainDiv .listOptionDiv button {
    padding: 0px 5px !important;
    line-height: 35px;
    margin-top: 30px;
  }
  .marineterminal-head-section .terminalImgDiv {
    margin-top: 50px;
  }
  .headerBannerMainDiv {
    margin-top: 0px;
    height: 400px;
  }
  .human-resources-section {
    margin-top: 80px;
  }
  .contactUsMapDiv {
    height: 400px;
  }
  .alertAnnouncementsMain {
    padding: 0 !important;
  }
  .bookedParkingMainDiv .waitingListParking .parkingTitleDiv .bookingTypeTxt,
  .bookedParkingMainDiv .waitingListParking .parkingTitleDiv .statustxt {
    width: 100%;
    text-align: left;
  }
  .waitingListParking .statustxt .cancelBtnDiv button {
    font-size: 18px;
  }
  .bookedParkingMainDiv .waitingListParking ul li .parkingDetailMainDiv ul li,
  .bookedParkingMainDiv .waitingListParking .parkingDetailMainDiv ul li {
    width: 33%;
  }
  .bookedParkingMainDiv .waitingListParking.activityListDiv ul li .boxCard .parkingImgDiv {
    height: 500px !important;
  }
  .bookedParkingMainDiv .waitingListParking ul li .boxCard .parkingImgDiv {
    height: 300px !important;
  }
  .parkingTitleDiv .parkingTitleTxt label,
  .parkingTitleDiv .parkingTitleTxt span,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt label {
    font-size: 20px;
  }
  .parkingTitleDiv .parkingTitleTxt label,
  .parkingTitleDiv .parkingTitleTxt span {
    font-size: 18px;
  }
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt label,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt span,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt label,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt span {
    display: block;
    font-size: 16px;
  }
  .parkingTitleDiv .parkingTitleTxt span,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt span {
    padding: 0px 10px;
  }
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .parkingTitleTxt,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt {
    width: 55%;
  }
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt {
    width: 45%;
  }
  .bookedParkingMainDiv
    .recordDataListMain.parkingDetailsMainDiv
    ul
    li
    .boxCard
    .parkingImgDiv {
    height: 700px;
  }
  .bannerLogoNoticeDiv .noticeDespDiv p {
    font-size: 22px;
    line-height: 26px;
  }
  .bannerLogoNoticeDiv .logoImgDiv {
    width: 120px;
    height: 120px;
  }
  .bannerImgMainDiv .container {
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    height: 85%;
  }
  .bannerLogoNoticeDiv {
    position: absolute;
    top: 13%;
    width: 75%;
    background: rgb(0 0 0 / 50%);
    padding: 7%;
    margin: 20px auto;
    height: 350px;
  }
  .bannerLogoNoticeDiv .noticeDespDiv {
    font-size: 24px;
    color: #fff;
    padding: 0 10px;
    display: inline-block;
    width: 80%;
    float: right;
  }
  .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 32%;
  }
  .currentWaitingTimeDiv .tabOptionCWT {
    margin-right: 4px;
    font-size: 16px;
  }
  .pageNotFound h1 {
    font-size: 28px;
  }
  .pageNotFound span.pageNotTxt {
    margin: 12% auto;
  }
  .overlay-toggel select {
    padding: 8px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    width: 130px;
  }
  .blogImg {
    height: 200px;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }
  #card_body {
    border-bottom-left-radius: 30px !important;
  }
  .marineimg-div .imgDivBox {
    height: 200px;
  }
  .marineterminal-home a:hover .marineimg-div:before {
    height: 200px;
  }
  .userPicDiv .profilePicMainDiv .profileTxtIcon {
    width: 80px;
    height: 80px;
    font-size: 45px;
    line-height: 65px;
  }
  .userPicDiv .profilePicMainDiv .img-fluid {
    max-width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: contain;
  }
  .detailsMainPage .userPicDiv {
    width: 175px;
  }
  .detailsMainPage .userDetailsList {
    width: 60%;
  }
  .detailsMainPage .userDetailsList .col-sm-3,
  .detailsMainPage .userDetailsList .col-sm-9 {
    width: 100%;
    height: auto;
  }
  .detailsMainPage .userDetailsList p {
    margin-bottom: 0;
  }

  .dashboardInfoDetails .userDataInfo {
    width: 75%;
  }
  .vesselestimationdiv .col-5 {
    width: 40%;
    padding-right: 10px !important;
  }
  .vesselestimationdiv .col-4 {
    width: 35%;
    padding-right: 10px !important;
  }
  .vesselestimationdiv .col-3 {
    width: 25% !important;
    padding-right: 10px !important;
    font-size: 18px;
  }

  .vesselestimationdiv .vo-namecard-head .col-3 {
    padding-left: 0px !important;
    padding-right: 30px !important;
  }
  .vesselestimationdiv .vo-namecard-head,
  .vesselestimationdiv .rowDivData {
    padding-left: 10px !important;
    font-size: 16px;
  }
  .vesselestimationdiv .rowDivData .col-sm-8 {
    padding-right: 10px !important;
  }
  .searchOptDiv .serachOption {
    width: 50%;
  }
  .bookedParkingMainDiv .searchFilterDiv input {
    width: 75%;
  }
  .bookedParkingMainDiv .searchFilterDiv .serachOption {
    width: 25%;
  }

  .otherInformationForm .formMainDiv .formFieldDiv,
  .primaryInformationForm .formMainDiv .formFieldDiv {
    margin-bottom: 10px;
  }
  .pwdInputDiv {
    padding-bottom: 5px;
    padding-top: 10px;
  }
  .confirmPwdErrorDiv.text-center {
    margin-top: -15px;
    margin-bottom: 15px;
    padding-left: 30%;
  }
  .marineCameraModal .modal-dialog {
    top: 5vh;
  }
  .marine-container .marine-card .camera-txt {
     height: 90%;
    margin: 5px;
    font-size: 12px;
  }
  input[type="checkbox"] {
    width: 35px;
  }
  .otherInformationForm .formMainDiv .formFieldDiv span {
    width: 55%;
  }

  .news-Img {
    width: 100%;
    margin: 20px 0;
  }
  .otherInformationForm .formMainDiv .formFieldDiv,
  .primaryInformationForm .formMainDiv .formFieldDiv {
    width: 100%;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv span {
    width: 65%;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv input,
  .primaryInformationForm .formMainDiv .formFieldDiv select {
    width: 100%;
  }
  .uploadDocumentMainDiv .attachmentListDescp {
    width: 96%;
  }
  .uploadDocumentMainDiv .attachmentViewDiv {
    width: 30%;
    height: 150px;
  }
  .uploadDocumentMainDiv {
    display: inline-block;
    width: 100%;
  }
  .railcrossing-head .col-4 {
    width: 60%;
  }
  .railcrossing-head .col-8 {
    width: 40%;
  }
  .railcrossing-head .titleTabOption h2 b {
    width: 70%;
  }
  .tableOverFlow {
    width: 100%;
    display: block;
    overflow: auto;
  }
  .tableOverFlow table {
    width: 1000px;
  }
  .showGraphMainDiv .innerGraphDiv {
    width: 100%;
    display: inline-block;
    margin: 20px auto;
  }
  .truckTurnTimeListOpt ul li .infoListDiv .fixedTxt b {
    float: none;
  }
  .truckTurnTimeListOpt ul li .infoListDiv .fixedTxt label {
    float: none;
    width: 90%;
    text-align: center;
  }
  .mobileOTPDiv .mobileInputDiv {
    width: 80% !important;
  }
  .mobileOTPDiv button {
    width: 20%;
  }
  .speedometerMainDiv .speedometerInnerDiv {
    width: 100%;
  }
  .terminalTimeDetailDiv {
    width: 100% !important;
  }

  .securitySafetyMain h2,
  .trafficCameraMainDiv h2 {
    font-size: 24px !important;
  }
  .trafficCameraMainDiv .titleTabOption h2 b {
    width: 60%;
  }
  .selectUserTypeMainDiv {
    width: 45%;
    padding: 30px;
    margin: 30px 0;
    margin-right: 4%;
  }

  .titleTabOption h2 span {
    margin: 10px 10px;
    float: left;
    padding: 0;
  }
  .titleTabOption h2 b {
    padding-top: 6px;
    display: inline-block;
    font-weight: normal;
    width: 50%;
    font-size: 22px;
    text-align: left;
  }
  .titleTabOption .alertTxtDiv h2 b {
    padding-top: 10px;
  }
  .center-underline h2,
  .titleTabOption h2 {
    padding: 5px;
    height: 90px !important;
  }

  

  .confirmPwdErrorDiv.text-center {
    width: 92% !important;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt,
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 30% !important;
    /*width: 175px !important;*/
  }
  .currentWaitingTimeDiv .marine-container .mainCardDiv {
    width: 50%;
  }
  .marine-gallery-img {
    height: 210px;
  }
  .tools-section .tools-background-image {
    padding-top: 10px !important;
  }
  .marineterminal-head-section .terminal-name {
    font-size: 40px;
    padding: 20px 0 0 0;
    width: 100%;
    text-align: center;
  }
  .marineterminal-head-section .terminalImgDiv {
    width: 100%;
    text-align: center !important;
  }
  .container.nav-mobile.d-flex.justify-content-between {
    margin-top: 0px !important;
  }
  .navNewMenuDiv .navNewMenuListDiv {
    display: none;
  }

  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt {
    width: 35%;
  }
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 35%;
  }

  .driverregistration-section .form-group .col-sm-6 {
    width: 70%;
  }
  .footer-head-underline::before {
    width: 0px
  }
  .hoverToolDescp {
    display: block;
    width: 90%;
  }
  .desktopToolDescp {
    display: none;
  }
  .contactusDiv .rightDivInput {
    margin: 15px 0px 15px -3px;
  }
  .vo-namecard-head {
    font-size: 20px;
    padding: 20px 0px 20px 0px !important;
  }
  .rowDivData {
    padding: 10px 0px 10px 0 !important;
  }
  .rowDivData .col-3:nth-child(1),
  .vo-namecard-head .col-3:nth-child(1) {
    padding-left: 0px;
  }
  .rowDivData .col-3,
  .vo-namecard-head .col-3 {
    padding: 0px;
  }
  .faqs-section .divBoxNumber {
    margin-top: -10px;
  }
  .modules_rectangle:nth-child(even) {
    background-color: #fff;
  }
  .modules_rectangle:nth-child(3n + 1) {
    background-color: #fff !important ;
  }
  .modules_rectangle:nth-child(3) {
    background-color: #fff !important ;
  }
  .modules_rectangle {
    background-color: #fafafa !important ;
  }
  .faqs-section .titleTxtDiv {
    width: 100%;
  }
  .faqs-section .viewBtnDiv {
    float: right;
    width: 100%;
    position: relative;
    right: 0;
    margin: 0 0 20px 0;
  }
  .contactusDiv {
    width: 600px;
    margin: auto;
  }
  .findus-section .sendMsgBtn {
    margin: 20px auto !important;
    display: block;
  }
  .contactusDiv-footer {
    /* width: 220px; */
    margin: auto;
  }
  .contactusDiv-footer .p-3 {
    padding: 10px !important;
  }
  .portlink-div,
  .legalnotice-div,
  .resources-div,
  .contactusDiv-footer {
    float: none !important;
    margin: auto;
    /* width: 250px !important; */
  }
  .newsupdates-section .headingTxt {
    float: none;
    width: 100%;
    display: inline-block !important;
    text-align: center;
    margin: 0 0 20px 0;
    padding-bottom: 0 !important;
  }
  .newsupdates-section .viewAllBtn {
    float: right;
    width: 100%;
    display: inline-block !important;
    text-align: right;
    margin: 0px 0 20px 0;
  }
  .home-tools-section .newsDivBox {
    margin-bottom: 25px;
  }
  .home-tools-section .imgDivBox {
    width: 250px;
    margin: 0 0px 0 10px;
  }
  .home-tools-section .textDescpDiv {
    width: 60%;
  }
  header video {
    width: 100%;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
  .faqs-section .col-lg-8.col-xl-9 {
    width: 70%;
  }
  .banner_img {
    margin-top: 50px !important;
  }
  header {
    margin-top: 65px;
    background-color: #000;
    height: 400px;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
  }

  .nav-fixed {
    top: 0 !important;
  }
  .navmob {
    display: none;
  }
  .navcustomclass {
    position: fixed !important;
    z-index: 2005 !important;
    width: 100%;
    top: 0px !important;
    padding-right: 0px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
  }
  .navbar-toggler {
    background-color: white !important;
  }
  .navhide {
    display: none;
  }
  .logo-mobile {
    display: block !important;
    width: 60px;
    margin-right: auto;
  }
  .home-tools-sectiontwo.container,
  .findus-section .container {
    max-width: 100%;
    padding: 0;
  }
  .tools-section .tools-background-image {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .tools-section .tools-background-image .container {
    max-width: 100%;
  }
  .tools-section .tools-row1 .col-md-3,
  .tools-section .tooldivTwo .col-md-3 {
    height: 240px;
    display: inline-block;
  }
  .banner_img {
    width: 100%;
    height: 400px;
    margin: 0.188rem 0 0;
    object-fit: cover;
  }
  .vessel_img.drivenRegistrationBannerImg {
    width: 100%;
    height: auto;
    margin-top: 0px;
  }
  .contactDetailsDiv div {
    text-align: center;
    margin-bottom: 30px;
  }
  .contactDetailsDivFAQ div {
    text-align: center;
    margin: 30px;
  }
  .contactDetailsDivFAQ {
    margin: 20px;
  }
  .contactDetailsDiv {
    margin-bottom: 50px;
  }
  .trafficCameraFindDiv .switchBtnDiv img {
    width: 40px;
  }
  /*
.weatherDetailDiv .weatherInnerDiv{
  height: 100vh !important;
}*/
  .weatherDetailDiv .weatherTempInfoDiv {
    padding: 20px;
  }
  .weatherDetailDiv .currentWeatherStatus span b {
    font-size: 22px;
  }
  .weatherDetailDiv .currentWeatherStatus {
    width: 220px;
    padding: 0px 0 0 0;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 {
    font-size: 120px;
    padding: 0;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 img {
    width: 150px;
    top: -20px;
    margin: 0 0 0 30px;
  }
  .weatherDetailDiv .weatherTitleDiv span {
    width: 50%;
    margin-top: 0px;
  }
  .currentTempStatusDiv {
    margin: 0;
    height: 545px;
  }
  .currentTempStatusDiv ul {
    display: inline-block;
  }
  .currentTempStatusDiv ul li {
    display: inline-block;
    width: 98%;
    padding: 15px 20px;
    border: 0;
    border-bottom: 2px solid #fff;
    margin: auto;
  }
  .currentTempStatusDiv ul li b {
    display: inline-block;
  }
  .currentTempStatusDiv ul li b.timeTxt {
    font-size: 20px;
    margin: 0 60px;
  }
  .currentTempStatusDiv ul li img {
    width: 48px;
    display: inline-block;
    margin: 0 60px;
  }
  .currentTempStatusDiv ul li span {
    display: inline-block;
    float: right;
    width: 25%;
  }
  .lastUpdateDiv h3,
  .lastUpdateDiv .listOptionDiv {
    width: 100%;
    text-align: center;
  }
  .alertInnerDivList li span {
    width: 85%;
  }
  .newAlertVesselDiv .sty2,
  .newAlertVesselDiv .sty3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 880px) {
  .bookedSpaceSearchMain .container {
    max-width: 100%;
  }
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .parkingTitleTxt,
  .parkingDetailsNewMain
    .parkingInfoDiv
    .parkingTitleDiv
    span.innerDiv
    .statustxt {
    width: 100%;
    text-align: left;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv input {
    width: 50%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv .serachOption,
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    width: 110px;
  }
  .historicalTTTMainDiv .listOptionDiv .col-md-6:last-child {
    width: 100%;
  }
  .historicalTTTMainDiv .listOptionDiv button {
    float: none;
  }
  .historicalTTTMainDiv .listOptionDiv .col-md-6 {
    width: 33%;
    margin-top: 10px;
  }
  .todaySunStatus ul {
    display: inline-block;
    top: auto;
    left: auto;
    height: auto;
    background: transparent;
  }
  .currentTempStatusDiv ul {
    display: inline-block;
    top: 0;
    left: 0;
    background: transparent;
    text-align: left;
  }
  .home-tools-section .imgDivBox {
    width: 25%;
  }

  .home-tools-section .textDescpDiv {
    width: 65%;
  }
  .tools-section .tools-background-image .container .tooldivTwo {
    margin-top: 30px !important;
  }
  .container.vesselMainPage {
    max-width: 95%;
  }

  .reportTabsMainDiv .nav-tabs {
    width: 100%;
    height: auto !important;
  }
  .rightSideNavDiv {
    display: contents !important;
  }
  .header-sticky ul {
    position: relative;
    width: 120px;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
  }
  .profileDDownBtn.dropdown-menu {
    top: 40px;
    background: #fff;
    height: 200px;
    width: 195px;
  }
  .gm-style-mtc button {
    font-size: 16px !important;
    padding: 5px 12px !important;
  }
  .gm-style-mtc ul {
    width: 80px !important;
    height: auto !important;
    padding: 0 !important;
  }
  .gm-style-mtc ul li {
    font-size: 16px !important;
  }
  .gm-style-mtc ul li span {
    top: -4px;
    position: relative;
    left: -2px;
  }

  .newsDetailsMainDiv .news-content ul,
  .newsDetailsMainDiv .news-content_info ul {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
    text-align: left !important;
  }
  .gm-style-mtc ul,
  .truckTurnTimeListOpt ul,
  .alertInnerDivList,
  .pagination,
  .reportTabsMainDiv .nav-tabs,
  .weatherForecastDailyDiv ul,
  .uploadDocumentMainDiv .attachmentListDescp ul,
  .recordDataListMain ul,
  .nav-group-items,
  .note__unorderlist,
  .adminSideMenu .simplebar-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
  }
  .securitySafetyMapModal ul {
    text-align: left;
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
  }
  .note__unorderlist {
    text-align: left;
  }
  .truckTurnTimeListOpt ul li .titleDiv {
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
    text-align: left;
  }
  .truckTurnTimeListOpt ul li .titleDiv span img {
    width: 48px;
    margin: 0px 10px 0 0;
    vertical-align: top;
  }
  .truckTurnTimeListOpt ul li .arrowDDBtn {
    width: 38px;
    margin: 0 10px;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv input {
    width: 70%;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv .serachOption {
    width: 30%;
  }
}
@media (max-width: 858px) {
  .spanishString
    .bookedParkingMainDiv.parkingNewMainDiv
    .searchFilterDiv
    input {
    width: 38%;
  }
  .bookedParkingMainDiv .parkingPaymentMainDiv ul.listBookedParkingDiv li {
    width: 50% !important;
    height: 70px !important;
    margin: 10px 0 !important;
  }
  .bookedParkingMainDiv .securityDepositDetail ul.listBookedParkingDiv li {
    width: 50% !important;
    height: 70px !important;
    margin: 10px 0 !important;
  }
  .bookedParkingMainDiv
    .recordDataListMain.parkingPaymentMainDiv
    ul
    li
    .boxCard
    .parkingImgDiv {
    height: 600px !important;
  }

  .bookedParkingMainDiv
  .recordDataListMain.securityDepositDetail
  ul
  li
  .boxCard
  .parkingImgDiv {
  height: 600px !important;
}

  .bookedParkingMainDiv
    .recordDataListMain.parkingPaymentMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(1) {
    width: 100% !important;
  }
  .bookedParkingMainDiv
    .recordDataListMain.securityDepositDetail
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(1) {
    width: 50% !important;
  }
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(5),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li {
    width: 33.3% !important;
    height: 70px;
  }

  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(4),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(3),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(2) {
    height: 110px;
  }
  .parkingDetailsMainDiv .btnMainDiv {
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  .bookedSpaceSearchMain.truckTurnTimeMainDiv .titleDescpDiv h2 {
    font-size: 38px;
  }

  .bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
    width: 32.1%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv input {
    width: 48%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    margin-left: 5px;
  }
  .checkbtn {
    display: block;
  }

  ul {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #150606;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all 0.5s;
  }

  #check:checked ~ ul {
    left: 0;
  }

  .header-sticky ul {
    position: relative;
    width: 120px;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
    /*display: block;
    float: left;*/
  }
   
  .adminSideMenu .sidebar-nav {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
  }
}
@media screen and (max-width: 768px) {
  .home-tools-section .newsInnerBoxDiv .imgDivBox {
    height: 125px;
}
  .marineterminal-head-section .lastUpdatedTxt {
    /* position: relative; */
    top: 15px;
    font-size: 18px;
    text-align: center;
  }
  .CompanyEstablishedDateInputDiv .calendar_icon {
    top: -12px;
  }
  .parkingNewMainDiv .shareButtonDiv {
    top: 110px;
  }
  .bookedParkingMainDiv
    .recordDataListMain.parkingPaymentMainDiv
    ul
    li
    .boxCard
    .parkingImgDiv {
    height: 100px !important;
    width: 100%;
  }

  .bookedParkingMainDiv
  .recordDataListMain.securityDepositDetail
  ul
  li
  .boxCard
  .parkingImgDiv {
  height: 100px !important;
  width: 100%;
}
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv input {
    width: 62%;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv .serachOption,
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    width: 110px;
  }
  .removedOverlayTrigger.alertInnerDivList li span {
    width: 75% !important;
  }
  .removedOverlayTrigger.alertInnerDivList li .buttonDiv {
    width: 25%;
  }

  /*------Start LanguageString-Punjabi-------*/
  .punjabiString .reportTabsMainDiv .nav-tabs li.nav-item {
    width: 33%;
  }
  .punjabiString .reportTabsMainDiv .nav-tabs .nav-link {
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    text-align: center;
  }
  .punjabiString .removedOverlayTrigger.alertInnerDivList li .buttonDiv b,
  .punjabiString .alertAnnouncementsMain .buttonDiv b {
    font-size: 14px;
  }
  .punjabiString .removedOverlayTrigger.alertInnerDivList li .buttonDiv button,
  .punjabiString .alertAnnouncementsMain .rightCopyDiv .buttonDiv button {
    width: 90px;
  }
  /*------Start LanguageString-Punjabi-------*/
  /*------Start LanguageString-Spanish-------*/
  .spanishString
    .bookedParkingMainDiv.parkingNewMainDiv
    .searchFilterDiv
    input {
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }
  .spanishString .bookedParkingMainDiv.parkingNewMainDiv .searchFilterDiv {
    text-align: center;
  }
  .spanishString
    .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    select {
    float: none;
  }
  .spanishString .vesselTableMainDiv .titleTabOption .center-underline h2,
  .spanishString .vesselTableMainDiv .titleTabOption h2 {
    font-size: 24px;
  }
  .spanishString .reportTabsMainDiv .nav-tabs .nav-link {
    height: 84px;
    align-items: center;
    display: flex;
  }
  .spanishString .selectUserTypeMainDiv .driverTitleDiv h2 {
    height: 100%;
  }
  .spanishString .loginsection .col-lg-6.form-section {
    width: 100%;
  }
  .spanishString .loginsection .form-group.px-5 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  /*------ End LanguageString-Spanish-------*/
  .playnowButtonModal .modal-content,
  .playnowButtonModal .modal-body {
    width: 100%;
  }

  .alertAnnouncementsTabMain .tabVesselDiv .titleTabOption:nth-last-child(1) {
    width: 100%;
  }
  .alertAnnouncementsMain .rightCopyDiv {
    width: 20%;
  }
  .alertAnnouncementsMain .leftDescpDiv {
    width: 80%;
  }
  .bookedParkingMainDiv .waitingListParking ul li .boxCard .parkingImgDiv,
  .bookedParkingMainDiv .recordDataListMain ul li .boxCard .parkingImgDiv {
    height: 100px !important;
    width: 100% !important;
  }
  .bookedParkingMainDiv
    .recordDataListMain.parkingDetailsMainDiv
    ul
    li
    .boxCard
    .parkingImgDiv {
    height: 100px;
    width: 100%;
  }
  .bookedParkingMainDiv .waitingListParking ul li .boxCard .parkingInfoDiv,
  .bookedParkingMainDiv .recordDataListMain ul li .boxCard .parkingInfoDiv,
  .bookedParkingMainDiv
    .recordDataListMain.parkingDetailsMainDiv
    ul
    li
    .boxCard
    .parkingInfoDiv {
    padding: 0px;
    float: right;
    width: 100%;
  }
  .bannerLogoNoticeDiv .logoImgDiv {
    width: 100px;
    height: 100px;
  }
  .bannerLogoNoticeDiv .marineterminal-img {
    width: 100%;
    height: 100%;
  }
  .bannerLogoNoticeDiv .noticeDespDiv {
    font-size: 20px;
  }
  .rightSideNavDivHide {
    display: none !important;
  }
  .rightSideNavDiv {
    display: contents !important;
  }
  .profileDDownBtn.dropdown-menu {
    top: 40px;
    background: #fff;
    height: 200px;
    width: 195px;
  }
  .profileDivDDown.nav-item .btn-primary {
    padding: 0;
  }
  .profileDDownBtn .dropdown-menu li {
    width: 100%;
  }
  .blogImg {
    height: 290px;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }
  .overflowDiv {
    overflow: auto;
  }
  .overflowDiv .vesselestimationdiv {
    width: 800px;
  }
  .overflowDiv .vesselestimationdiv .dateDivCol3 {
    width: 25%;
    font-size: 16px;
  }
  .marineterminal-home a:hover .marineimg-div:before {
    height: 290px;
  }
  #card_body {
    border-bottom-left-radius: 30px !important;
  }
  .marineimg-div .imgDivBox {
    height: 290px;
  }
  .positionRelative .fa {
    margin-top: 20px !important;
  }
  .vesselestimationdiv .fa:before {
    padding: 10px 0;
  }
  .vesselestimationdiv .col-5 {
    width: 33%;
    font-size: 14px;
  }
  .vesselestimationdiv .col-4 {
    width: 33%;
    font-size: 14px;
  }
  .vesselestimationdiv b.thTxt {
    font-size: 20px !important;
  }
  .vesselestimationdiv .col-3 {
    width: 34%;
    font-size: 18px !important;
  }
  .vesselestimationdiv .vo-namecard-head .col-sm-8,
  .vesselestimationdiv .rowDivData .col-sm-8 {
    width: 60%;
  }
  .vesselestimationdiv .vo-namecard-head .col-sm-4,
  .vesselestimationdiv .rowDivData .col-sm-4 {
    width: 40%;
  }
  .reportTabsMainDiv .nav-tabs .nav-link {
    padding: 5px 5px;
  }
  .mobileAdmin img {
    width: 62px;
    padding: 0;
  }
  .header-sticky {
    padding: 0 !important;
  }
  .header-divider {
    display: none;
  }
  .adminFooter,
  .adminFooter span {
    display: inline-block;
    font-size: 14px;
    text-align: center;
  }
  .pwdInputFormDiv {
    margin: 10px 0;
    display: block !important;
    position: relative;
    height: auto !important;
    min-height: 100%;
  }
  .pwdInputFormDiv {
    margin: 0px;
  }
  .pwdInputFormDiv .text-danger {
    display: block;
    height: 40px;
    margin: 0;
  }
  .confirmPwdErrorDiv.text-center {
    padding-left: 35%;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt,
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 35% !important;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv label,
  .otherInformationForm .formMainDiv .formFieldDiv label {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: auto;
    width: 100%;
  }
  input[type="checkbox"] {
    width: 25px;
  }
  input[type="checkbox"]:after {
    left: 35%;
  }
  .newsDetailsMainDiv h1 {
    font-size: 38px;
  }
  .newsDetailsMainDiv h2 {
    font-size: 24px;
  }
  .weatherForecastDailyDiv ul li span .highTempTxt {
    font-size: 34px;
  }
  .weatherForecastDailyDiv ul li span .txtDescp {
    font-size: 20px;
    line-height: 24px;
  }
  .weatherForecastDailyDiv ul li span .lowTempTxt {
    font-size: 20px;
  }
  .weatherForecastDailyDiv ul li span .dayTxt {
    font-size: 20px;
  }
  .weatherForecastDailyDiv h1 {
    font-size: 38px;
  }
  .weatherForecastDailyDiv h1 img {
    margin: 0 10px 0 0;
  }
  .railcrossing-head .col-4 {
    width: 60% !important;
  }
  .railcrossing-head .col-8 {
    width: 40% !important;
  }
  .railcrossing-head .titleTabOption h2 b {
    width: 70%;
  }
  .newAlertVesselDiv.alertInnerDivList li span {
    width: 85%;
  }
  .alertInnerDivList li span {
    width: 80%;
  }
  .reportTabsMainDiv .nav-tabs,
  .truckTurnTimeListOpt ul,
  .alertInnerDivList,
  .weatherForecastDailyDiv ul,
  .uploadDocumentMainDiv .attachmentListDescp ul,
  .recordDataListMain ul,
  .header-sticky ul,
  .nav-group-items,
  .pagination,
  .adminSideMenu .sidebar-nav {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    transition: none;
  }
  .uploadDocumentMainDiv .attachmentListDescp {
    width: 96%;
  }
  .uploadDocumentMainDiv .applyBtnDiv {
    width: 100%;
  }
  .uploadDocumentMainDiv .attachmentViewDiv {
    width: 90%;
    height: 160px;
    margin: 20px;
  }
  .otherInformationForm .formMainDiv .formFieldDiv,
  .primaryInformationForm .formMainDiv .formFieldDiv {
    /* height: 100%;*/
    height: 100px;
    margin-bottom: 2px;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv select {
    margin-bottom: 25px !important;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv span {
    width: 98%;
  }
  .otherInformationForm .formMainDiv .formFieldDiv span {
    width: 98%;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv label,
  .otherInformationForm .formMainDiv .formFieldDiv label,
  .primaryInformationForm .formMainDiv .formFieldDiv input,
  .primaryInformationForm .formMainDiv .formFieldDiv select,
  .otherInformationForm .formMainDiv .formFieldDiv input,
  .otherInformationForm .formMainDiv .formFieldDiv select {
    width: 100%;
    margin: 5px;
  }
  .otherInformationForm .headingTxt h1,
  .primaryInformationForm .headingTxt h1 {
    font-size: 28px;
  }
  .uploadDocumentMainDiv h2 {
    font-size: 20px;
    line-height: 22px;
  }
  .uploadDocumentMainDiv .applyBtnDiv button {
    margin: 10px auto;
    float: none;
  }
  .truckTurnTimeListOpt ul li .infoListDiv .dynamicTxt {
    padding: 5px 10px;
    font-size: 20px;
    width: 95%;
  }
  .truckTurnTimeListOpt ul li .titleDiv {
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
    text-align: left;
  }
  .truckTurnTimeListOpt ul li .titleDiv span img {
    width: 48px;
    margin: 0px 10px 0 0;
    vertical-align: top;
  }
  .truckTurnTimeListOpt ul li .arrowDDBtn {
    width: 38px;
    margin: 0 10px;
  }
  .mobileOTPDiv .mobileInputDiv {
    width: 70% !important;
  }
  .mobileOTPDiv button {
    width: 30%;
  }
  .alertVesselTabDiv .locationAlertTxt {
    display: block;
    width: 100%;
  }
  .terminalTimeDetailDiv {
    width: 70% !important;
    margin: auto;
  }
  .currentTempStatusDiv ul li b.timeTxt {
    font-size: 20px;
    margin: 0px 50px;
  }
  .currentTempStatusDiv ul li img {
    width: 48px;
    margin: 0 50px;
  }
  .securitySafetyMain h2,
  .trafficCameraMainDiv h2 {
    font-size: 20px !important;
  }
  .trafficCameraMainDiv .titleTabOption h2 span {
    margin: 5px 10px 0 0;
  }
  .trafficCameraFindDiv .formDiv {
    width: 80%;
  }
  .trafficCameraFindDiv .switchBtnDiv {
    margin: 0 0 0px 20px;
  }
  .selectUserTypeMainDiv {
    width: 80%;
    padding: 30px;
    margin: 30px 0;
    margin-right: 0;
  }

  .titleTabOption h2 b {
    padding-top: 5px;
    display: inline-block;
    font-weight: normal;
    width: 50%;
    font-size: 22px;
    text-align: left;
    float: left;
  }
  .driverregistration-popup.cameraModalDiv {
    width: 500px;
  }

  .underContPageDiv {
    margin-top: 100px;
  }
  .underContPageDiv .underContMainDiv img {
    width: 80%;
  }
  .currentWaitingTimeDiv .tabOptionCWT {
    width: auto;
  }
  .currentWaitingTimeDiv .tabOptionCWT .card-body,
  .currentWaitingTimeDiv .tabOptionCWT .terminal-graph {
    padding: 5px 10px;
  }

  .currentWaitingTimeDiv .tabOptionCWT .card-body .card-body,
  .currentWaitingTimeDiv .tabOptionCWT .terminal-graph .card-body {
    padding: 0;
  }
  .vesselTabOptionDiv {
    overflow: auto;
  }
  .innerVesselDiv .rowDivData {
    cursor: pointer;
  }
  .innerVesselDiv {
    width: 768px;
    display: inline-block;
  }
  .securitySafetyMain .tabVesselDiv .titleTabOption {
    width: 100%;
  }
  .tabVesselDiv .titleTabOption {
    width: 100%;
    margin-top: 20px;
    padding-right: 0px;
    text-align: center;
  }
  .CamerasInfoDiv .titleTabOption {
    width: 100% !important;
  }
  .tabVesselDiv .titleTabOption:nth-last-child(1) {
    width: 100%;
    margin-top: 20px;
  }
  .tabVesselDiv .titleTabOption h2 {
    font-size: 24px;
  }
  .container.vesselMainPage {
    max-width: 95%;
  }
  .tools-section .tools-background-image {
    height: 100%;
    display: inline-block;
  }
  .tools-section .tools-row1 .col-sm-6,
  .tools-section .tooldivTwo .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
    float: left;
    height: 280px;
    display: inline-block;
  }
  .tools-section .tooldivTwo {
    display: block;
    margin: 0 !important;
  }
  .tools-section .tools-row1 {
    height: 240px;
    display: block;
  }
  header {
    height: 350px;
  }
  .faqs-section .titleTxtDiv {
    padding-bottom: 0px !important;
  }
  .faqs-section .viewBtnDiv div {
    width: 100%;
    text-align: center;
  }
  .faqs-section .viewBtnDiv div .text-end {
    text-align: center !important;
  }
  .home-tools-section .textDescpDiv {
    width: 60%;
  }
  .footer-sec {
    padding-bottom: 40px;
  }
  .newsInnerBoxDiv {
    margin-bottom: 40px;
  }
  .footer-head-underline {
    margin-top: 30px;
    border-bottom: 3px solid;
    padding-bottom: 10px;
  }

  .terminal-address-section .container,
  .gatehours-section .container,
  .gateinformation-section .container,
  .currentWaitingTimeDiv .container,
  .marineterminal-head-section .container,
  .marineterminal-nav-section .container {
    max-width: 95%;
    margin: auto;
  }
  /*.marineterminal-head-section .terminal-name {
        font-size: 26px;
        float: left;
        width: 50%;
    }
    .marineterminal-head-section .terminalImgDiv{
      float: right;
      width: 50%;
      display: inline-block;
    }*/
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt {
    width: 40%;
  }
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 40%;
  }

  .driverregistration-section .form-group .col-sm-6 {
    width: 65%;
  }
  .weatherDetailDiv .weatherTitleDiv span {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .weatherDetailDiv .weatherTitleDiv span img {
    width: 32px;
    display: inline-block;
    margin: 10px 10px 20px 0px;
    float: none;
  }
  .weatherDetailDiv .weatherTitleDiv span b.timeUpdateTxt {
    padding-left: 50px;
    top: -18px;
  }
  .weatherDetailDiv .weatherTitleDiv span b {
    vertical-align: middle;
    top: -5px;
  }
  .weatherDetailDiv .weatherTempInfoDiv {
    padding: 10px 20px;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 {
    font-size: 85px;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  .weatherDetailDiv .currentWeatherStatus {
    width: 250px;
    margin: 0 auto;
    padding: 0px 0 0 0;
    float: none;
    text-align: center;
    display: block;
  }
  .weatherDetailDiv .currentWeatherStatus span b {
    font-size: 20px;
    display: inline-block;
    width: 160px;
  }
  .currentTempStatusDiv ul li img {
    width: 42px;
    margin: 0 30px;
  }
  .currentTempStatusDiv ul li b.timeTxt {
    font-size: 20px;
    margin: 0px 30px;
  }
  .weatherDetailDiv .weatherInnerDiv {
    height: 600px;
  }
  .enterLocationInput {
    width: 100%;
  }
  .weatherInnerDiv .searchInputDiv input {
    width: 96%;
  }
  .weatherInnerDiv .searchInputDiv .fa-search {
    margin-left: 0;
  }
  .weatherInnerDiv .searchInputDiv {
    text-align: left;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .marineterminal-nav-section .marine,
  .marineterminal-nav-section .terminal {
    width: 25%;
  }
  .currentWaitingTimeDiv .marine-container .col-sm-12 {
    width: 50%;
  }
}
@media screen and (max-width: 680px) {
  .mobileSearchIcon {
    width: 50px;
    height: 40px;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv input {
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv {
    text-align: center;
  }
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv .serachOption,
  .parkingNewMainDiv.bookedParkingMainDiv .searchFilterDiv select {
    width: 110px;
    display: inline-block;
    float: none;
  }
  /*------Start LanguageString-Spanish-------*/
  .spanishString .currentWaitingTimeDiv .tabOptionCWT,
  .spanishString .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 100%;
  }
  .spanishString .legendLineColorDiv {
    width: 230px;
  }
  /*------End LanguageString-Spanish-------*/
  .lineBg {
    width: 20px;
    height: 20px;
    display: block;
    vertical-align: sub;
    margin: auto;
  }

  .legendLineColorDiv {
    width: 210px;
  }
  .legendLineColorDiv div {
    width: 100%;
    text-align: left;
  }
  .lineBg {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
  .headerBannerMainDiv {
    margin-top: 0px;
    height: 350px;
  }
  .contactUsMapDiv {
    height: 350px;
  }
  .newRecurringDate.searchOptDiv .serachOption {
    width: 50%;
  }
  .searchOptDiv .serachOption:last-child {
    width: 100% !important;
  }
  .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 40%;
  }
  .pageNotFound span {
    width: 100%;
    height: 100%;
  }
  .pageNotFound span.pageNotTxt {
    margin: 2% auto;
  }
  .vesselLocationInfo .innerInfoDiv {
    margin: 5px 10px !important;
  }
  .tableRespDiv,
  .allAlertsMainDiv .collapse.show .card-body {
    overflow-x: auto;
  }
  .tableRespDiv .vesselestimationdiv,
  .allAlertsMainDiv .collapse.show .vesselestimationdiv {
    width: 650px;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv label {
    height: auto;
  }

  .showGraphMainDiv .innerGraphDiv img {
    width: 100%;
  }
  .vessel_img.drivenRegistrationBannerImg {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  .banner_img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .marineterminal-head-section .terminal-name {
    font-size: 38px;
  }
  .tabVesselDiv .titleTabOption h2 {
    font-size: 22px;
  }
  header {
    height: 325px;
  }
  .contactusDiv {
    width: 90%;
  }
  .home-tools-section .imgDivBox {
    width: 30%;
  }
  .newsupdates-section .viewAllBtn div {
    display: inline-block;
  }
  .newsupdates-section .viewAllBtn {
    float: none;
    text-align: center;
  }
}
@media screen and (max-width: 580px) {
  .weatherDetailDiv .weatherTitleDiv{
    padding: 0;
  }
  .weatherDetailDiv .weatherTempInfoDiv {
    padding: 0px 20px;
}
  .newsDetailsMainDiv .news-content_info {
    margin-left: 0px;
  }
  .newsDetailsMainDiv .news-content ol,
  .newsDetailsMainDiv .news-content ul {
    display: block;
  }
  .tabNavLink.nav-tabs a.nav-link {
    width: 100%;
    background: #dcdcdc;
    border-bottom: 1px solid #999;
    margin-bottom: 1px;
    border-radius: 0;
  }
  .tabNavLink.nav-tabs a.nav-link.active {
    width: 100%;
    background: #ffffff;
  }
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(4),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(5),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li {
    width: 50% !important;
    height: 70px;
  }

  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(3),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(2) {
    height: 110px;
  }
  .searchNdTotalDataDiv .totalTxtDiv,
  .searchNdTotalDataDiv .resultOptList {
    float: none;
    width: 100%;
  }
  .searchNdTotalDataDiv .searchFilterDiv .serachOption {
    float: none;
    margin-top: 10px;
  }
  .bookingSpaceCalendarResultDiv h6,
  .searchNdTotalDataDiv .searchFilterDiv {
    text-align: center !important;
  }
  .historicalTTTMainDiv.bookedSpaceSearchMain .listOptionDiv .col-md-6 {
    width: 100%;
    margin-top: 0px;
  }
  .bookedSpaceSearchMain .lastUpdateDiv .listOptionDiv button.searchBtn {
    margin-top: 20px;
  }
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv,
  .bookingSpaceCalendarResultDiv .bookingDateMainDiv {
    height: 100px;
  }
  .bookingSpaceCalendarResultDiv b.bookingMonthYearTxt,
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    font-size: 10px;
    height: 40px;
  }
  .bookingSpaceCalendarResultDiv b.bookingDateTxt,
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv span {
    font-size: 38px;
    line-height: 100%;
    height: 50px;
    padding: 5px;
  }
  .searchSectionMain .headerBannerMainDiv {
    position: relative;
    margin-top: 15px;
    height: 100px;
  }
  .headerBannerMainDiv b {
    top: 100%;
    font-size: 28px;
  }
  .headerBannerMainDiv b:after {
    width: 60px;
    top: 50px;
  }
  .removedOverlayTrigger.alertInnerDivList {
    display: block;
    height: 100%;
  }
  .removedOverlayTrigger.alertInnerDivList li {
    display: inline-block;
    height: 100%;
  }
  .removedOverlayTrigger.alertInnerDivList li span,
  .removedOverlayTrigger.alertInnerDivList li .buttonDiv {
    width: 100% !important;
    display: block;
    float: none;
  }
  .removedOverlayTrigger.alertInnerDivList li .buttonDiv button {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 10px;
  }
  /*------Start LanguageString-Punjabi-------*/
  .punjabiString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    font-size: 15px;
  }
  .punjabiString .vesselLocationInfo label b {
    font-size: 16px;
  }
  .punjabiString .vesselLocationInfo label {
    font-size: 14px;
  }
  .punjabiString .vesselMapTab .gm-style .gm-style-iw-c {
    max-height: 500px !important;
  }
  .punjabiString .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 100%;
  }
  .punjabiString .currentWaitingTimeDiv .tabOptionCWT {
    width: 100%;
  }
  .punjabiString .titleTabOption h2 b {
    width: 65%;
    float: none;
  }
  /*------End LanguageString-Punjabi-------*/
  /*------Start LanguageString-Spanish-------*/
  .spanishString
    .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    .serachOption {
    display: inline-block;
    margin-bottom: 10px;
  }
  .spanishString
    .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    select {
    float: none;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv input {
    width: 100%;
    margin-bottom: 10px;
  }
  .spanishString .bookedParkingMainDiv .searchFilterDiv .serachOption {
    width: 150px;
    margin: 0px auto;
    display: block;
  }
  .spanishString .bookingSpaceCalendarResultDiv .searchNdTotalDataDiv h6,
  .spanishString
    .bookingSpaceCalendarResultDiv
    .searchNdTotalDataDiv
    .searchFilterDiv {
    padding: 0;
  }
  .spanishString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    padding: 0;
    margin-top: 0;
    font-size: 10px;
  }
  .spanishString .bookingSpaceCalendarResultDiv .innerBoxDiv {
    padding: 10px !important;
  }
  .spanishString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv,
  .spanishString .bookingSpaceCalendarResultDiv .bookingDateMainDiv {
    height: 100px;
    width: 95%;
  }
  .spanishString .searchNdTotalDataDiv .searchFilterDiv input {
    width: 80%;
  }
  .spanishString
    .historicalTTTMainDiv.bookedSpaceSearchMain
    .listOptionDiv
    .col-md-6 {
    width: 100%;
  }
  .spanishString .historicalTTTMainDiv .listOptionDiv label {
    height: auto;
  }
  .spanishString .container.vesselMainPage {
    max-width: 100%;
  }
  .spanishString .newVesselTabMainDiv .center-underline h2,
  .spanishString .newVesselTabMainDiv .titleTabOption h2 {
    padding: 10px 0px 0 0;
    text-align: center;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .titleTabOption
    .center-underline
    h2,
  .spanishString .newVesselTabMainDiv.newVesselTabMainDiv .titleTabOption h2 {
    font-size: 16px;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .titleTabOption
    h2:after {
    bottom: -40%;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-3.titleTabOption {
    width: 20% !important;
  }
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-5.titleTabOption,
  .spanishString
    .newVesselTabMainDiv.newVesselTabMainDiv
    .col-lg-4.titleTabOption {
    width: 40% !important;
  }
  .spanishString .reportTabsMainDiv .nav-tabs .nav-link {
    height: auto;
  }
  .spanishString .reportTabsMainDiv .nav-tabs li.nav-item {
    width: 100%;
    margin-top: 2px;
  }
  .spanishString .alertsMainDivPage .card.parentDivCard .lastUpdateDiv h3,
  .spanishString
    .alertsMainDivPage
    .card.parentDivCard
    .lastUpdateDiv
    .listOptionDiv {
    width: 100%;
    text-align: center;
  }
  .spanishString
    .truckTurnTimeListOpt
    .historicalSearchList
    .infoListDiv
    label {
    height: auto;
  }
  .spanishString .vesselTableMainDiv .titleTabOption .center-underline h2,
  .spanishString .vesselTableMainDiv .titleTabOption h2 {
    font-size: 18px;
  }
  .spanishString .vesselTableMainDiv .titleTabOption h2 b {
    width: 90%;
  }
  .spanishString .gateinformation-section .contactDetailsDiv h6 {
    width: 100%;
    margin: 10px auto;
    height: auto;
  }
  .spanishString .loginsection .form-group label.col-sm-2,
  .spanishString .loginsection .form-section form .form-group .col-sm-10 {
    width: 100%;
  }

  /*------ End LanguageString-Spanish-------*/

  .playnowButtonModal .modal-body .VideoInput_video {
    height: 300px;
  }

  .tileDivBox .card-body div:first-child {
    font-size: 40px !important;
  }
  .truckTurnTimeListOpt .historicalSearchList .infoListDiv {
    width: 100%;
  }
  .headerBannerMainDiv {
    margin-top: 0px;
    height: 275px;
  }
  .bookedParkingMainDiv .waitingListParking .parkingDetailMainDiv ul li,
  .bookedParkingMainDiv .parkingDetailMainDiv ul li {
    width: 50%;
  }
  .detailsMainPage .userDetailsList.viewUserDetailPage {
    width: 100%;
  }
  .viewUserDetailPage .col-sm-3,
  .viewUserDetailPage .col-sm-4 {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px;
    padding-right: 0px !important;
  }
  .marineterminal-head-section {
    margin-top: 50px;
  }
  .bannerImgMainDiv .container {
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    height: 100%;
  }
  .bannerImgMainDiv {
    position: relative;
    text-align: left;
    height: 250px;
    top: 70px;
  }
  .bannerLogoNoticeDiv {
    top: 0;
    padding: 2%;
  }
  .bannerImgMainDiv .banner_img {
    width: 100%;
    height: 250px;
    margin: 0 !important;
  }
  .bannerLogoNoticeDiv .marineterminal-img {
    width: 80px;
  }
  .bannerLogoNoticeDiv .logoImgDiv {
    width: 80px;
    height: 80px;
    float: none;
    margin: auto;
    text-align: center;
  }
  .bannerLogoNoticeDiv .noticeDespDiv {
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    margin-top: 10px;
    display: inline-block;
  }
  .bannerLogoNoticeDiv .noticeDespDiv span {
    text-align: center;
    display: block;
  }
  .bannerLogoNoticeDiv .noticeDespDiv p {
    height: 90px;
    text-align: left;
    margin-top: 10px;
  }
  .noteDespTxt.m-5 {
    margin: 30px !important;
  }
  .currentWaitingTimeDiv .tabOptionCWT,
  .currentWaitingTimeDiv .tabOptionCWT:first-child {
    width: 100%;
  }
  .detailsMainPage .userPicDiv {
    margin: auto;
    float: none;
  }
  .driverregistration-popup.cameraModalDiv {
    width: 96%;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv,
  .otherInformationForm .formMainDiv .formFieldDiv {
    height: 100%;
    margin-bottom: 20px;
  }
  .otherInformationForm .formMainDiv,
  .primaryInformationForm .formMainDiv {
    padding: 20px 10px;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv label,
  .otherInformationForm .formMainDiv .formFieldDiv label,
  .primaryInformationForm .formMainDiv .formFieldDiv input,
  .primaryInformationForm .formMainDiv .formFieldDiv select,
  .otherInformationForm .formMainDiv .formFieldDiv input,
  .otherInformationForm .formMainDiv .formFieldDiv select {
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
  }
  .primaryInformationForm .formMainDiv .formFieldDiv span {
    width: 100%;
  }

  .primaryInformationForm .formMainDiv .formFieldDiv .text-danger,
  .otherInformationForm .formMainDiv .formFieldDiv .text-danger {
    margin-bottom: 0px;
  }

  .driverregistration-section p.text-danger,
  .confirmPwdErrorDiv.text-center {
    margin-bottom: 0;
  }
  .pwdInputDiv {
    padding-bottom: 0;
    padding-top: 0;
  }
  .confirmPwdErrorDiv.text-center {
    padding-left: 0%;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt,
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 100% !important;
  }
  .policy-bg span {
    font-size: 12px;
    display: block;
    width: 95%;
    margin: auto;
  }
  .footer-sec .footer-links {
    margin-bottom: 5px;
  }
  .selectUserTypeMainDiv:last-child .driverTitleDiv h2 {
    margin-bottom: 20px;
  }
  .selectUserTypeMainDiv .driverTitleDiv h2 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .selectUserTypeMainDiv .custom-checkbox {
    margin: 20px auto;
  }
  .selectUserTypeMainDiv .driverTitleDiv p {
    margin-top: 0;
  }
  .marineCameraModal .modal-content {
    width: 90%;
    margin: auto;
  }
  .currentWaitingTimeDiv .marine-container .col-sm-12 {
    width: 100%;
  }
  .marine-container .marine-card .camera-txt {
    height: 100%;
  }

  .trafficCameraMainDiv .titleTabOption h2 b {
    width: 100%;
  }
  .trafficCameraMainDiv .titleTabOption h2 {
    padding: 10px 0 !important;
  }
  .driverregistration-popup.cameraModalDiv {
    height: 400px;
  }
  .driverregistration-popup.cameraModalDiv .innerImgDiv {
    height: 83%;
  }
  .marine-gallery-img {
    height: 200px !important;
  }

  .loginsection .form-section form .form-group label {
    width: 100px;
    display: inline-block;
  }
  .loginsection .form-section form .form-group .col-sm-10 {
    width: 74%;
  }
  .contactusDiv .rightDivInput {
    margin: 15px 0px 15px -2px;
  }
  .tabVesselDiv .titleTabOption h2 {
    font-size: 22px;
    text-align: center;
    padding: 0;
  }
  .tabVesselDiv .titleTabOption:nth-last-child(1) {
    padding: 0;
    margin-top: 20px;
    text-align: center;
  }
  .titleTabOption h2 span {
    margin: 10px auto;
    float: none;
    padding: 0;
    display: block;
  }
  .titleTabOption h2 b {
    padding-top: 5px;
    width: 50%;
    font-size: 22px;
    text-align: center;
    float: none;
  }
  .titleTabOption .alertTxtDiv h2 b {
    padding-top: 0px;
  }
  .titleTabOption .alertTxtDiv h2 span {
    margin: 0 0 5px 0px;
  }
  .modules_rectangle:nth-child(even) {
    background-color: #fafafa !important;
  }
  .modules_rectangle:nth-child(odd) {
    background-color: #fff !important;
  }
  .modules_rectangle {
    background-color: #fafafa;
  }
  .modules_rectangle:nth-child(3n) {
    background-color: transparent;
  }
  .faqs-section .col-lg-8.col-xl-9 {
    width: 68%;
  }
  header {
    height: 260px;
  }
  .faqs-section .row.container {
    max-width: 100%;
    margin: 10px auto;
    padding: 0;
  }
  .faqs-section .divBoxNumber {
    margin-top: -15px;
  }
  .marineterminal-head-section .terminal-name {
    font-size: 36px;
  }
  .marineterminal-nav-section .marine,
  .marineterminal-nav-section .terminal {
    font-size: 18px;
  }
  .marineterminal-head-section .container,
  .marineterminal-nav-section .container {
    max-width: 100%;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt {
    width: 45%;
  }
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 45%;
  }

  .driverregistration-section .form-group .col-sm-6 {
    width: 100%;
    margin-bottom: 0 !important;
  }
  .confirmPwdErrorDiv.text-center {
    padding-left: 0;
    margin-top: -12px;
    width: 100%;
  }
  .center-underline h2,
  .titleTabOption h2 {
    height: 100px !important;
  }
  .currentTempStatusDiv ul li b.timeTxt,
  .currentTempStatusDiv ul li img {
    margin: 0px 20px;
  }
  .marineterminal-nav-section .gateTab_2 {
    width: 80%;
  }
  .marineterminal-nav-section .gateTab_1 {
    width: 60%;
  }
  .marineterminal-nav-section .gateTab_3 {
    width: 100%;
  }
  .weatherForecastDailyDiv h1 {
    font-size: 32px;
  }
  .weatherForecastDailyDiv h1 img {
    width: 50px;
  }
  .weatherForecastDailyDiv ul li {
    padding: 0;
  }
  .weatherForecastDailyDiv ul li span .dateTxt {
    font-size: 14px;
  }
  .weatherForecastDailyDiv ul li span .dayTxt,
  .weatherForecastDailyDiv ul li span .lowTempTxt,
  .weatherForecastDailyDiv ul li span .txtDescp {
    font-size: 18px;
    line-height: 100%;
  }
  .weatherForecastDailyDiv ul li span .highTempTxt {
    font-size: 28px;
  }
  .weatherForecastDailyDiv ul li span .imgIcon {
    width: 64px;
  }
  .weatherForecastDailyDiv ul li span .lowTempTxt i {
    padding: 0 5px 0 0;
    top: -2px;
    font-size: 20px;
  }
  .trafficCameraMapDivTab .gm-style .gm-style-iw-c,
  .vesselMapTab .gm-style .gm-style-iw-c {
    /*max-width: 300px !important;
  max-height: 100% !important;*/
    transform: translate3d(-50%, -55%, 0);
  }
  .trafficCameraMapDivTab .gm-style .gm-style-iw-a,
  .vesselMapTab .gm-style .gm-style-iw-a {
    left: 50% !important;
    top: 50% !important;
  }
  .popupCaltransCameras .dataDescpTxt span:first-child,
  .chainControlMainDiv .chainControlDescp .titleTxt,
  .textDespMsgSign .titleTxt {
    margin-top: 20px;
  }
  .chainControlMainDiv {
    width: 100%;
  }
  .trafficCameraMapDivTab .gm-style .gm-style-iw-c,
  .vesselMapTab .gm-style .gm-style-iw-c {
    max-width: 300px !important;
    min-width: 300px !important;
    max-height: 400px !important;
  }
  .mapPopup {
    width: 100% !important;
  }
  .trafficCameraFindDiv .formDiv .has-search .form-control-feedback {
    padding: 0px 15px;
  }
  .trafficCameraFindDiv .formDiv .has-search .form-control-feedback img,
  .trafficCameraFindDiv
    .formDiv
    .has-search
    .form-control-feedback
    img.searchIconImg {
    width: 18px;
  }
  .trafficCameraFindDiv .formDiv .has-search .form-control {
    padding-left: 40px;
    height: 40px;
  }
  .trafficCameraFindDiv .switchBtnDiv img {
    width: 30px;
  }
  .trafficCameraFindDiv {
    padding: 10px;
  }
  .sty {
    font-size: 18px;
  }
  .tcolor {
    font-size: 20px;
  }
  .railborder,
  .railways-cross-section {
    width: 100%;
  }
  .reportModalClass .modal-content,
  .resourcesMapPopup .modal-content {
    width: 95%;
    margin: auto;
  }
  .resourcesMapPopup .leftDivInput,
  .resourcesMapPopup .rightDivInput {
    width: 100%;
    margin-right: 0;
  }
  .resourcesMapPopup .modal-dialog {
    margin-top: 40px;
  }
  .forgetmodel_parent {
    top: 25vh;
    width: 94%;
    margin: 0 auto;
    left: 3%;
  }
  .tollMapPopUpDiv p label,
  .tollMapPopUpDiv p span {
    width: 100% !important;
  }
  .mapPopup.tollMapPopUpDiv {
    width: 100% !important;
    height: 100% !important;
  }

  .resourcesMapPopup {
    margin-top: 40px;
    overflow: scroll;
    padding-bottom: 100px;
  }
  .resourcesMapPopup .col-md-12.formemail,
  .resourcesMapPopup .textareaDivContactus {
    padding: 0px 0px 2px 0px !important;
    margin: 0px auto;
  }
  .custom-modal {
    left: 0;
    width: 90%;
    margin: auto;
  }
  .reportTabsMainDiv .nav-tabs .nav-link {
    padding: 5px 5px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .home-tools-section .newsInnerBoxDiv .imgDivBox {
    height: 200pxpx;
  }
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv,
  .bookingSpaceCalendarResultDiv .bookingDateMainDiv {
    height: 115px;
  }
  .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    width: 100%;
    margin-top: 0;
  }
  .parkingNewMainDiv .bookParkingComponentDivUL li .boxCard .parkingInfoDiv {
    margin-top: 40px;
  }
  .bookedParkingMainDiv .parkingPaymentMainDiv ul.listBookedParkingDiv li {
    width: 100% !important;
    height: auto !important;
    margin: 5px 0 !important;
    padding-bottom: 10px;
  }

  .bookedParkingMainDiv .securityDepositDetail ul.listBookedParkingDiv li {
    width: 100% !important;
    height: auto !important;
    margin: 5px 0 !important;
    padding-bottom: 10px;
  }
  .bookedParkingMainDiv .recordDataListMain.securityDepositDetail .parkingDetailMainDiv ul.listBookedParkingDiv li:nth-last-child(1) {
    width: 100% !important;
}
.securityDepositDetail
.parkingDetailMainDiv
ul.listBookedParkingDiv
li:nth-last-child(1),
.securityDepositDetail
.parkingDetailMainDiv
ul.listBookedParkingDiv
li:nth-last-child(2) {
  height: auto !important;
  border-bottom: 1px solid #333 !important;
}

.horizontalDiv{
  border-top: none !important;
    padding-top: none !important;
}


  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(4),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(5),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li,
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(3),
  .bookedParkingMainDiv
    .recordDataListMain.existingReservationsMainDiv
    .parkingDetailMainDiv
    ul.listBookedParkingDiv
    li:nth-last-child(2) {
    height: auto;
    width: 100% !important;
    margin: 5px 0;
    padding-bottom: 10px;
  }
  .parkingDetailsMainDiv .btnMainDiv {
    text-align: center;
    display: block;
    width: 250px;
    margin: 0 auto;
  }
  .parkingTitleDiv .shareButtonDiv {
    position: relative;
  }
  .bookedSpaceSearchMain.truckTurnTimeMainDiv .titleDescpDiv h2 {
    font-size: 32px;
  }
  .bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
    width: 48%;
  }
  .otherInformationForm .headingTxt,
  .primaryInformationForm .headingTxt {
    padding: 5px 10px;
  }
  .otherInformationForm .headingTxt h1,
  .primaryInformationForm .headingTxt h1 {
    font-size: 24px;
  }
  .newRecurringDate.searchOptDiv .serachOption,
  .newRecurringDate.searchOptDiv .serachOption.numberStallsDiv,
  .newRecurringDate.searchOptDiv .serachOption.equipmentLengthDiv,
  .searchOptDiv .serachOption,
  .searchOptDiv .serachOption.numberStallsDiv,
  .searchOptDiv .serachOption.equipmentLengthDiv {
    width: 100%;
  }
  .bookedParkingMainDiv .finalPaymentParking ul li .parkingDetailMainDiv ul li {
    width: 50%;
    height: 100px;
  }
  /*------Start LanguageString-Punjabi-------*/
  .punjabiString .parkingSearchMain .searchOptDiv .calendar_icon-parking {
    right: 7px;
    top: -30px;
  }
  .punjabiString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    font-size: 15px;
    padding: 0px;
  }
  .spanishString
    .newRecurringDate.searchOptDiv
    .serachOption.equipmentLengthDiv,
  .spanishString .newRecurringDate.searchOptDiv .serachOption,
  .punjabiString .reportTabsMainDiv .nav-tabs li.nav-item {
    width: 100%;
  }
  .punjabiString .reportTabsMainDiv .nav-tabs .nav-link {
    font-size: 18px !important;
  }
  .punjabiString .tools-section .tools-row1 .col-sm-6,
  .punjabiString .tools-section .tooldivTwo .col-sm-6 {
    width: 50%;
    height: 250px;
  }
  .punjabiString .tools-section .tools-background-image {
    height: 1100px;
  }
  /*------End LanguageString-Punjabi-------*/

  /*------Start LanguageString-Spanish-------*/
  .spanishString .driverInventoryMainDiv .searchFilterDiv input {
    width: 100%;
    margin-bottom: 10px;
    width: 350px;
  }
  .spanishString .driverInventoryMainDiv .searchFilterDiv .serachOption {
    float: none;
    margin: auto;
    display: block;
    text-align: center;
  }
  .spanishString .searchOptDiv .serachOption {
    width: 100%;
  }
  .spanishString .searchOptDiv .serachOption .calendar_icon-parking {
    right: 4px;
  }
  /*------End LanguageString-Spanish-------*/
  .driverregistration-section.adminNewsUpdate .createNewsGroupDiv .form-group,
  .adminNewsUpdate .createNewsGroupDiv .blockDiv {
    width: 100%;
  }
  .dropzone {
    width: 100%;
    padding: 10px !important;
  }
  .dropzone p {
    text-align: center;
  }
  .maxImgTextMsg {
    display: block;
    font-size: 14px;
  }
  .textDescpDiv .txt-overflow {
    height: auto;
  }
  .news-head {
    font-size: 14px;
    margin-bottom: 0;
  }
  .newsInnerBoxDiv {
    margin-bottom: 20px;
  }
  .reportSectionMainDiv .searchFilterDiv input {
    width: 100%;
  }
  .reportSectionMainDiv .searchFilterDiv {
    text-align: center;
  }
  .reportSectionMainDiv .searchFilterDiv .serachOption {
    margin: 10px auto;
  }
  .selectRecordNumberDiv {
    width: 100%;
  }
  .exportFileDiv {
    margin: 10px 0;
    float: left;
  }
  .notfoundMainDiv {
    width: 250px;
    height: 280px;
  }
  .notfoundMainDiv div {
    overflow: visible !important;
    margin: 0 !important;
  }
  .bookedParkingMainDiv .waitingListParking ul li .parkingDetailMainDiv ul li,
  .bookedParkingMainDiv .waitingListParking .parkingDetailMainDiv ul li {
    width: 50%;
  }
  .historicalTTTMainDiv .listOptionDiv .col-md-6 {
    width: 100%;
  }
  .truckTurnTimeListOpt .historicalSearchList .infoListDiv {
    width: 100%;
  }
  .alertAnnouncementsMain .rightCopyDiv,
  .alertAnnouncementsMain .leftDescpDiv {
    width: 100%;
  }
  .alertAnnouncementsMain .leftDescpDiv p.tcolor {
    margin-bottom: 0px;
  }
  .alertAnnouncementsMain .leftDescpDiv p.sty {
    height: 100%;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
  }
  .alertAnnouncementsMain .rightCopyDiv {
    display: block;
  }
  .alertAnnouncementsMain .rightCopyDiv .buttonDiv button {
    position: relative;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .alertAnnouncementsMain .rightCopyDiv .buttonDiv {
    margin: 0 auto;
    position: relative;
    padding: 0;
    height: auto;
    display: inline-block;
    align-items: normal;
    float: none;
  }
  .newRecurringDate.searchOptDiv .serachOption,
  .searchOptDiv .serachOption input,
  .searchOptDiv .serachOption select {
    width: 100%;
  }

  .bookingDetailModal
    .modal-body
    .bookedParkingMainDiv
    .parkingDetailMainDiv
    ul
    li {
    width: 100% !important;
  }
  .bookedParkingMainDiv .searchFilterDiv input {
    width: 100%;
    margin-bottom: 10px;
  }
  .bookedParkingMainDiv .searchFilterDiv .serachOption {
    width: 100px;
    margin: 0px auto;
    display: block;
  }
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt {
    padding-left: 10px;
  }
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .statustxt,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .parkingTitleTxt,
  .parkingInfoDiv .parkingTitleDiv span.innerDiv .bookingTypeTxt {
    width: 100%;
    float: left;
    text-align: left;
  }
  .parkingTitleDiv .bookingTypeTxt label {
    padding-left: 10px;
  }
  .parkingDetailsMainDiv .parkingTitleDiv .statustxt label {
    padding: 0;
  }
  .alertListMainDiv .alertListOpt {
    width: 100%;
  }
  .alertListMainDiv .buttonDiv {
    width: 100%;
    height: 40px;
    display: inline-block;
    text-align: center;
    float: none;
    position: relative;
  }
  .alertListMainDiv .buttonDiv button {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .pagination li a {
    padding: 5px;
  }
  .newAlertVesselDiv li {
    display: inline-block;
  }
  .newAlertVesselDiv.alertInnerDivList li span {
    width: 100%;
  }
  .alertInnerDivList li .buttonDiv {
    width: 100%;
    margin-bottom: 10px;
    height: 50px;
  }
  .faq-section .trafficAlertDDown h5.mb-0 > a {
    font-size: 16px !important;
    width: 80%;
  }
  .faq-section .trafficAlertDDown .mb-0 > a:after {
    float: right;
    right: -25%;
    top: 20%;
  }
  .marineterminal-home a:hover .marineimg-div:before {
    height: 180px;
  }
  .blogImg,
  .marineimg-div .imgDivBox {
    height: 180px;
  }
  .blogImg {
    object-position: center left;
    object-fit: cover;
  }
  .reportModalClass .downloadOptDiv div button {
    width: 100%;
  }
  .reportModalClass .downloadOptDiv a,
  .reportModalClass .downloadOptDiv div {
    width: 50%;
  }
  .faq-section h5.mb-0 > a {
    padding: 10px 0 !important;
  }
  .tileDivBox .card-body div:last-child {
    font-size: 18px !important;
    margin: 2px 0 10px 0;
    height: 120px;
  }
  .boxMainDiv .card-body {
    padding: 10px;
  }
  .driverregistration-popup.cameraModalDiv {
    left: 50% !important;
    width: 90% !important;
    margin: auto;
    margin-left: 0%;
  }
  .custom-modal {
    left: 0 !important;
    width: 90% !important;
    margin: auto;
    margin-left: 5%;
  }
  .logindesc {
    font-size: 18px;
    width: 80%;
    margin: auto;
  }
  .currentWaitingTimeDiv .tabOptionCWT .card-body,
  .currentWaitingTimeDiv .tabOptionCWT .terminal-graph {
    padding: 5px 8px;
  }
  input[type="checkbox"] {
    width: 22px;
  }
  input[type="checkbox"]:after {
    left: 35%;
    top: 8%;
    width: 32%;
    height: 65%;
  }

  #drawer-handle img {
    width: 30px;
  }
  .filterMapMainDiv .filterOptDiv .checkbox {
    padding: 12px 0px;
    width: 100%;
    font-size: 16px;
  }
  .filterMapMainDiv .filterOptDiv .checkbox input {
    margin: 0 8px;
    width: 20px !important;
    height: 20px;
    top: 3px;
    margin-bottom: 10px;
    display: block;
    float: left;
  }
  .filterMapMainDiv .filterOptDiv .checkbox {
    line-height: 22px;
  }
  #drawer-content {
    width: 225px;
  }
  .weatherInnerDiv .searchInputDiv input {
    width: 95%;
  }
  .weatherForecastDailyDiv ul li span {
    width: 27%;
  }
  .weatherForecastDailyDiv ul li span:nth-child(2) {
    width: 18%;
  }
  .weatherForecastDailyDiv ul li span .highTempTxt {
    font-size: 24px;
  }
  .weatherForecastDailyDiv ul li span .dayTxt,
  .weatherForecastDailyDiv ul li span .lowTempTxt,
  .weatherForecastDailyDiv ul li span .txtDescp {
    font-size: 16px;
  }
  .weatherForecastDailyDiv {
    padding: 10px;
  }
  .weatherForecastDailyDiv h1 {
    font-size: 24px;
  }
  .lastUpdateDiv h3 {
    font-size: 20px;
  }
  .truckTurnTimeListOpt ul li .arrowDDBtn {
    margin: 0px;
  }
  .truckTurnTimeListOpt ul li .titleDiv span img {
    width: 40px;
    vertical-align: sub;
  }
  .truckTurnTimeMainDiv .titleDiv .arrowDDBtn,
  .truckTurnTimeMainDiv .titleDiv .arrowDDBtn.collapsed {
    width: 34px;
    height: 34px;
  }
  .truckTurnTimeListOpt ul li .titleDiv span h2 {
    font-size: 28px;
  }
  .lastUpdateDiv .listOptionDiv select {
    width: 65%;
    padding: 12px 4px;
    margin: 0 10px 0 0px;
  }
  .lastUpdateDiv .listOptionDiv button {
    padding: 6px 8px;
    font-size: 14px;
  }
  .lastUpdateDiv .listOptionDiv button img {
    width: 20px;
    margin-right: 5px;
  }
  .reportTabsMainDiv .nav-tabs .nav-link {
    font-size: 12px !important;
    padding: 5px;
  }
  .reportTabsMainDiv .nav-tabs .nav-link {
    margin-top: 1px;
  }
  .reportTabsMainDiv .nav-tabs .nav-item,
  .reportTabsMainDiv .nav-tabs .nav-link.active {
    width: 100%;
    margin-top: 1px;
  }
  .alertInnerDivList li span {
    width: 100%;
  }
  .alertInnerDivList li span label {
    text-align: center;
  }
  .alertInnerDivList li button {
    float: none;
  }
  .truckTurnTimeListOpt ul li .infoListDiv {
    width: 48%;
    margin-right: 0%;
    margin-left: 0%;
    height: 225px;
  }
  .terminalTimeDetailDiv {
    width: 100% !important;
    margin: auto;
  }
  .weatherDetailDiv .weatherTitleDiv span b {
    font-size: 20px;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 {
    font-size: 55px;
  }
  .weatherDetailDiv .weatherTempInfoDiv h1 img {
    width: 100px;
    top: -10px;
    margin: 0 0px 0 10px;
  }
  .weatherDetailDiv .currentWeatherStatus span {
    margin: 5px 0;
    color: #fff;
    display: inline-block;
  }
  .todaySunStatus {
    width: 250px;
    margin: auto;
  }
  .todaySunStatus ul li {
    display: inline-block;
    position: relative;
    border-bottom: 3px solid #fff !important;
    height: 60px;
    border: 0;
  }
  .todaySunStatus ul li:last-child {
    border-bottom: 0 !important;
  }
  .currentTempStatusDiv ul li b {
    width: 50%;
  }
  .currentTempStatusDiv ul li span {
    width: 50%;
  }
  .weatherDetailDiv .weatherInnerDiv {
    height: 620px;
  }
  .weatherDetailDiv .weatherTitleDiv span b {
    top: 0;
  }
  .weatherDetailDiv .weatherTitleDiv span b.timeUpdateTxt {
    top: 0;
    padding-left: 20px;
  }
  .currentTempStatusDiv {
    margin: 0;
    height: 700px;
  }
  .currentTempStatusDiv ul li {
    padding: 7px 10px;
  }
  .enterLocationInput {
    font-size: 40px;
  }

  .currentTempStatusDiv ul li b.timeTxt {
    font-size: 20px;
    margin: 0;
    float: right;
  }
  .securitySafetyMain h2,
  .trafficCameraMainDiv h2 {
    font-size: 18px !important;
  }
  .selectUserTypeMainDiv {
    width: 100%;
    padding: 30px;
    margin: 30px 0;
    margin-right: 0;
  }

  .titleTabOption h2 b {
    width: 100%;
  }

  .loginsection .form-section form .forgotBtn {
    width: 100%;
    display: inline-block !important;
    text-align: center;
  }
  .loginsection .form-section form .form-group label {
    width: 80px;
    display: inline-block;
  }
  .loginsection .form-section form .form-group .col-sm-10 {
    width: 70%;
  }
  .driverregistration-section .col-lg-6.col-sm-12:nth-child(odd) .formlabeltxt {
    width: 100%;
  }
  .driverregistration-section
    .col-lg-6.col-sm-12:nth-child(even)
    .formlabeltxt {
    width: 100%;
  }

  .driverregistration-section .form-group .col-sm-6 {
    width: 100%;
    margin-bottom: 0px !important;
  }
  .marineterminal-head-section .terminal-name {
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
  .marineterminal-head-section .terminalImgDiv {
    width: 100%;
    float: none;
    text-align: center !important;
    margin: 10px auto;
  }
  .hoverToolDescp {
    font-size: 12px;
  }
  .railways-cross-section .railalert_radius .border-grey .sty3 {
    padding-left: 10px;
    float: left;
  }
  .col-md-12.formemail {
    margin: 10px auto;
    padding: 10px 12px 10px 12px !important;
  }
  .textareaDivContactus {
    padding: 10px 12px 10px 12px;
    margin: 0 auto !important;
  }
  .contactusDiv .leftDivInput,
  .contactusDiv .rightDivInput {
    width: 93%;
    float: left;
    margin: 10px auto;
  }
  .vesselalert p.sty1 {
    text-align: left;
  }
  .tabVesselDiv .titleTabOption {
    margin-top: 15px;
    padding: 0;
  }
  .tabVesselDiv .titleTabOption h2 {
    font-size: 20px;
    padding: 10px;
  }
  header .overlay {
    background-color: transparent;
  }
  .tools-section .tools-row1 .col-sm-6,
  .tools-section .tooldivTwo .col-sm-6 {
    width: 50%;
    height: 225px;
  }
  .tools-section .tools-background-image {
    height: 1020px;
  }
  .faqs-section .col-lg-8.col-xl-9 {
    width: 100%;
  }
  header {
    min-height: 200px;
    height: 100%;
  }
  .home-tools-section .imgDivBox {
    width: 80%;
    margin: 10px auto;
  }
  .home-tools-section .textDescpDiv {
    width: 80%;
    margin: 0px auto;
  }
  .currentWaitingTimeDiv .marine-container .col-sm-12 {
    width: 100%;
  }
  .vessel_img.drivenRegistrationBannerImg {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
  .overlay-toggel .closebtn {
    top: -20px !important;
  }
  .overlay-content {
    top: 5% !important;
  }
  .aboutus_para {
    font-size: 18px;
    width: 85%;
    margin: auto;
    text-align: justify !important;
  }
}
@media screen and (max-width: 380px) {
  .tileDivBox .card-body div div:last-child {
    font-size: 18px !important;
    margin: 0px 0px 10px 0px;
  }
  .spanishString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    padding: 0;
    margin-top: 0;
    width: 100%;
    font-size: 10px;
  }
  .bookingSpaceCalendarResultDiv .bookingNumbersListDiv b {
    width: 47%;
  }
  .bookedParkingMainDiv .parkingDetailMainDiv ul li {
    width: 100%;
    margin: 10px 0 0px 0;
    height: 50px;
  }
  .bookedParkingMainDiv .waitingListParking ul li .parkingDetailMainDiv ul li,
  .bookedParkingMainDiv .waitingListParking .parkingDetailMainDiv ul li {
    width: 100%;
    margin: 5px 0;
    height: 50px;
  }
  .historicalTTTMainDiv .listOptionDiv .col-md-6 {
    width: 100%;
  }
  .alertAnnouncementsMain .catTitleTxt,
  .alertAnnouncementsMain .dateAlertTxt {
    width: 100%;
    float: left;
    margin: 0;
  }
  .tileDivBox .card-body div:last-child {
    font-size: 18px !important;
  }
  .boxMainDiv .card-body {
    padding: 10px 5px;
  }
  input[type="checkbox"] {
    width: 30px;
  }
  input[type="checkbox"]:after {
    width: 33%;
  }
  .weatherForecastDailyDiv ul li span .dayTxt,
  .weatherForecastDailyDiv ul li span .lowTempTxt,
  .weatherForecastDailyDiv ul li span .txtDescp {
    font-size: 14px;
    line-height: 100%;
  }
  .weatherForecastDailyDiv ul li span .imgIcon {
    width: 100%;
  }
  .weatherForecastDailyDiv ul li span .highTempTxt {
    font-size: 20px;
  }
  .weatherForecastDailyDiv h1 {
    font-size: 17px;
  }
  .loginsection .form-section form .form-group label {
    width: 100%;
    display: inline-block;
  }
  .loginsection .form-section form .form-group .col-sm-10 {
    width: 100%;
  }
  .loginsection .form-section form .forgotBtn {
    width: 100%;
    display: inline-block !important;
    text-align: center;
  }

  .currentWaitingTimeDiv .marine-container .col-sm-12 {
    width: 100%;
  }
  .currentWaitingTimeDiv .tabOptionCWT {
    margin-right: 2px;
    font-size: 16px;
  }
  .marineterminal-nav-section .gateTab_1 .marine {
    border-radius: 10px !important;
  }
  .marineterminal-nav-section .rowTabOptDiv,
  .marineterminal-nav-section .marine,
  .marineterminal-nav-section .terminal {
    width: 100% !important;
  }
  .marineterminal-nav-section .marine:first-child {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .marineterminal-nav-section .marine:last-child {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .tabVesselDiv .titleTabOption h2 {
    font-size: 18px;
    padding: 0px;
  }
  header {
    min-height: 165px;
    height: 100%;
  }
  .tools-section .tools-row1,
  .tools-section .tooldivTwo {
    height: auto;
    display: block;
    padding: 0 !important;
  }
  .tools-section .tools-row1 .col-sm-6 .card img,
  .tools-section .tooldivTwo .col-sm-6 .card img {
    width: 32px;
  }
  .tools-section .tools-background-image {
    height: 100%;
    display: inline-block;
  }
  .tools-section .tools-row1 .col-sm-6,
  .tools-section .tooldivTwo .col-sm-6 {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 340px) {
  .spanishString
    .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    .serachOption,
  .parkingNewMainDiv.bookedParkingMainDiv
    .searchFilterDiv
    .serachOption
    button {
    width: 145px;
  }
  .punjabiString .bookingSpaceCalendarResultDiv .totalSpacesBookedDiv b {
    font-size: 10px;
  }
  .tileDivBox .card-body div:last-child {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 992px) {
  .logo-mobile {
    display: none;
  }
}

/* Resource Media Queries Goes Here */

@media screen and (max-width: 320px) {
  .viewheight-100 {
    height: 350vh;
  }
}

@media screen and (min-width: 320px) and (max-width: 1025px) {
  .viewheight-100 {
    height: 370vh;
  }
}
@media screen and (min-width: 100px) {
  .viewheight-100 {
    height: 400vh;
  }
}

/* Footer CSS Masthan */
/* .bottom-fixed{
  position: absolute;
  bottom: 0;
  left: 0;
} */

/* Footer CSS Ends Here */

/* 
Marine Termina CSS starts
*/

/* Over lay effect */

.overlay-toggel {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2500;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  transition: 0.5s;
}

#myNav {
  transition: 0.5s linear;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  overflow: scroll;
  height: 500px;
}

.overlay-toggel a {
  padding: 8px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay-toggel a:hover,
.overlay-toggel a:focus {
  color: #f1f1f1;
}

.overlay-toggel .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* Mobile Logo  */

@media screen and (max-height: 450px) {
  .overlay-toggel {
    overflow-y: auto;
  }
  .overlay-toggel a {
    font-size: 20px;
  }
  .overlay-toggel .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media (max-width: 992px) {
  .blacknav {
    display: none;
  }
}

@media (max-width: 768px) {
  .banner_img {
    width: 100%;
    height: auto;
  }

  .marine-gallery-img {
    height: 200px !important;
    border-radius: 10px 10px 0px 0px;
  }
}

@media (min-width: 992px) {
}

/* Transition Class for Tools  
.transition__img {
  /* width: 200px; */
  /* transition: width 2s; */
  /* animation: pump .5s linear; 
}*/
/* @keyframes pump {
  0%{
    transform: scale(0);
  }
  25%{
    transform: scale(0.25);
  }
  50%{
    transform: scale(0.5);
  }
  100%{
    transform: scale(1);
  }
} */

.cursor-pointer {
  cursor: pointer;
}
.vessel__text__color {
  color: #0d6efd !important;
}

.fa-unsorted:before,
.fa-sort:before {
  padding: 10px;
}

.fontBlack {
  color: #000;
}

.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}
.resources:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.resources a.text-dark {
  padding: 19px 15px;
}
.resources a img {
  padding: 10px !important;
}
/* .customization_text2 img {
    width: 100px;
    height: 100%;
    margin-top: 0px;
    } */

@media screen and (min-width: 480px) and (max-width: 768px) {
  .weatherdiv {
    padding-top: 00px;
  }
  .truckroutes-div {
    padding-top: 60px;
  }
}

#preloader,
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  display: block;
  opacity: 0.9;
}
.preloaderNew {
  content: "";
  /* background-image: url(../src/assets/loader.gif);*/
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 100px;
  height: 100px;
  background-size: 100px;
}
#preloader:before,
.preloader:before {
  content: "";
  background-image: url(../src/assets/loader.gif);
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 100px;
  height: 100px;
  background-size: 100px;
}

.googleMapImageRedirect {
  margin-left: 50%;
}

/*This will work for firefox*/
@-moz-document url-prefix() {
  @media (max-width: 992px) {
    .weatherTabOpt h2 {
      height: 79px !important;
    }
  }
  @media (max-width: 768px) {
    .weatherTabOpt h2 {
      height: 80px !important;
    }
  }

  @media (max-width: 580px) {
    .weatherTabOpt h2 {
      height: 100px !important;
    }
  }
}

/* New CSS Title */
.waitlistsearchTitle {
  width: 98%;
  position: absolute;
  left: 50%;
  top: 28%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

@media (max-width: 1375px) {
  .waitlistsearchTitle {
    top: 21%;
  }
}

@media (max-width: 992px) {
  .waitlistsearchTitle {
    top: 15%;
  }
}
@media (max-width: 768px) {
  .waitlistsearchTitle {
    top: 15%;
  }
}

@media (max-width: 580px) {
  .waitlistsearchTitle {
    top: 15%;
  }
}

@media (max-width: 480px) {
  .waitlistsearchTitle {
    top: 0%;
  }
}


.terminalLogoImage {
  max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.popupClassButton {
  background-color: #0d58b4;
  border-color: #0d58b4;
}

.bookingClassDynamic {
  display: none
}


.contact-information {
  display: inline-block;
 padding-bottom: 3px;
 padding-top:25px ;
 position: relative;
 color: white;
 font-size: 1.375rem;
 margin-bottom:10px;
 
}
.contact-information::before {
 content: "";
 position: absolute;
 width: 100%;
 height: 1px;
 bottom: -10px;
 border-bottom: 3px solid white;
}


@media screen and (min-width: 992px) and (max-width: 1400px) {
  .titleTabAddClass h2 b {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .titleTabAddClass h2 {
    height: 62px !important;
  }
}

@media screen and (max-width: 576px) {
  .adminNewsUpdate .createNewsGroupDiv .blockDivCopy {
    width: 100%;
    float: left;
  }
}


.pdfiframe{
  width: 100%;
  height: 100%;
}

 .required::after {
  content:"   * ";
  color: red; 
}


.pdfPreviewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pdfPreviewContainer button {
  padding: 10px 20px;
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* .securityDeposit ul{
  overflow-y: visible !important;
} */
.statusMarigin{
  margin-bottom: 4px;
}

.completeColor {
  background: #30c700;
}
.inProgressColor {
  background: #f5d557;
}
.requestedColor {
  background: #f5d557;
}
.cancelledColor {
  background: #fe8f8f;
}
.rejectedColor {
  background: #fe8f8f;
}
.paidColor {
  background: #d5d5d5;
}

.securityDepositDetail ul li .boxCard .parkingInfoDiv{
  width: 100% !important; 
}
.securityDepositDetail ul li{
  height: auto !important;
}

.securityDepositDetail
.parkingDetailMainDiv
ul.listBookedParkingDiv
li:nth-last-child(1),
.securityDepositDetail
.parkingDetailMainDiv
ul.listBookedParkingDiv
li:nth-last-child(2) {
  height: auto !important;
  border: none;
}

.horizontalDiv{
  border-top: 1px solid #333;
  padding-top: 20px;
}


.depositImgDiv{
  margin: 15px;
  text-align: center;
}

.depositImgDiv img{
  width: 20%;
  border-radius: 10px;
}

.invoiceButton{
  color: #ffffff;
}

.invoiceButton:hover{
  color: #ffffff !important;
}

.refund-btn{
  background-color: #1c9ae2;
  float: left;
  margin-left: 10px;
  border-radius: 5px;
  border: 0px solid #333;
  color: #fff;
  padding: 7px 10px;
}
.searchClass{
  display: flex;
}