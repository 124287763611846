@import url(https://fonts.googleapis.com/css?family=Ubuntu);
@import url(https://fonts.googleapis.com/css?family=Lobster);
.section_view {
  display: contents;
  grid-gap: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "main main"
    "event event";
    font-family: 'Poppins';
    background-image: url("/src/assets/marinebanner.png");
   background-size: cover;
  background-position: center;
  min-height: 100vh;
}

@media (min-width: 1200px) {
    section_view {
    grid-template-areas:
      "main event"
      "main event";
  }

}

.leftBox {
  grid-area: main;
  padding: 50px;
  box-sizing: border-box;
}

.section_view .leftBox .content {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  transition: 0.5s;
  
}

/* .section_view .leftBox .content:hover {
  background-color: #1e28e9;
} */

.section_view .leftBox .content h1 {
  margin: 0;
  padding: 0;
  font-size: 50px;
  text-transform: uppercase;
}

.section_view .leftBox .content p {
  margin: 10px 0 0;
  padding: 0;
}

.section_view .events {
  grid-area: none;
  /* background-color: rgba(0, 0, 0, 0.5); */
  box-sizing: border-box;
  border-radius: 20px; /* All corners rounded with 20px radius */
  /* background-color: #f0f0f0; */
  box-shadow: 0 0 19.4px 2.2px rgba(0, 0, 0, 0.13);
  /* border-radius: 0px 10px 10px 10px; */
  padding: 20px;
}

.section_view .events ul {
  margin: 0;
  padding: 5px;
}

.section_view .events ul li {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
  grid-template-areas:
    "time time"
    "detail detail";
   padding: 5px;
}

@media (min-width: 768px) {
    .section_view .events ul li {
    grid-template-areas:
      "time detail"
      "time detail";
  }
}

.section_view .events ul li .time {
  grid-area: time;
  position: relative;
  background-color: #2C7B80;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.5s;
  border-radius: 10px 10px 10px 10px;
  /* background-image: linear-gradient(to right, #43797f , #fefefe); */

}

.section_view .events ul li:hover .time {
  /* background-color: #1e28e9; */
  /* box-shadow: 0 0 21.5px 0 rgba(0, 0, 0, 0.647); */
}

.section_view .events ul li .time h2 {
    margin: 36px;
    padding: 5px;
    color: #fff;
    
    font-size: 34px;
    text-align: center;
    line-height: 38px;

    /* margin: 0;
  padding: 0;
  color: #fff;
  font-size: 60px;
  line-height: 30px; */
}

.section_view .events ul li .time h2 span {
  font-size: 30px;
}

.section_view .events ul li .details {
  grid-area: detail;
  padding: 10px 20px 10px;
  background-color: #fff;
  box-sizing: border-box;
}

.section_view .events ul li .details h3 {
  margin: 0;
  padding: 0;
  font-size: 22px;
}

.section_view .events ul li .details p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 16px;
}

.section_view .events ul li .details a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 15px;
  border: 2px solid #262626;
  margin-top: 16px;
  font-size: 18px;
  transition: 0.5s;
}

/* .section_view .events ul li .details a:hover {
  background-color: #1e28e9;
  color: #fff;
  border-color: #1e28e9;
} */
 


.date {
    /* margin: 0 auto; */
    width: 200px;
    font-weight: bolder;
    margin: 10px;
    font-size:30px;
    background: #f8f8f8;
    font-family: 'Poppins';
    box-shadow: inset 0px 0px 40px rgba(0, 132, 255, 0.1);
    text-align: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    margin-right: 15px;
  }
  
  .month {
    font-weight: bold;
    font-size:20px;
    padding: 2% 2%;
    background-color: rgb(180,0,0);
    color: white;
  }
  
  .day {
    padding: 0% 0%;
    font-family: 'Poppins';
  }
.day_value{
    font-weight: bold;
    font-size:20px;
    padding: 0% 0%;
    font-family: 'Poppins';
    color: #ffffff;
}

.red_value{
    font-family: 'Poppins';
    color: #ff0000;
    font-size:25px;
    font-weight:bold;
    
}
.green_value{
    font-family: 'Poppins';
    color: #009823;
    font-size:25px;
    font-weight:bold;
    
}

.tbdvalue{
  font-family: 'Poppins';
  color: #034973;
  font-size:25px;
  font-weight:bold;
  
}
 
.calendercardclass{
  box-shadow: 0 0 21.5px 0 rgba(0, 0, 0, 0.11);
}
 
.terminalName{
  position: relative;
  top: 30%;
  left: 20%;
}

.container-circular-image {
  display: flex;
  /* margin: 10px; */
  margin: 10px 45px;
  justify-content: center;
  align-items: center;
}

.circular-image {
  width: 100px;
  height: 75px;
  /* border-radius: 50%; Makes the image circular */
  overflow: hidden; /* Ensures the image stays within the circular boundary */
  position: relative; /* Positioning context for the image */
  border: 2px solid #2C7B80; /* Add a black border */
 
}

.circular-image img {
  position: absolute; /* Position the image absolutely within the circular container */
        top: 50%; /* Move the image 50% from the top of the circular container */
        left: 50%; /* Move the image 50% from the left of the circular container */
        transform: translate(-50%, -50%); /* Center the image within the circular container */
        max-width: 100%; /* Ensure the image doesn't overflow the circular boundary */
        max-height: 100%; /* Ensure the image doesn't overflow the circular boundary */
        padding: 10px;
  
}



.container-base {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.container-base img,
.container-base p {
  flex: 1;
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;
  /* text-align:center; */
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
.downloadBtn {
  float: right;
  border-radius: 25px;
  background-color: #2a6fc0;
  color: #fff !important;
  text-align: center;
  padding: 5px 20px;
  margin: 20px;
  border: 0;
}