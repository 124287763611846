.lmc__edit__icon{
    height: 30px;
    margin : auto 0px;
}

.edit_delete_btn {

    /* background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer; */
    border: none;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  /* Darker background on mouse-over */
  /* .edit_delete_btn:hover {
    background-color: RoyalBlue;
  } */
  input.input__readonly{
      background-color:#ebebeb;
      border: none !important;
      outline: none;
  }
  p.text-danger {
    color: #dc3545!important;
    font-size: 12px;
    text-align: left;
    padding: 2px;
    width: 95%;
}

.lmc_uploadbtn{
  /* text-align: center; */
}

.lmc_previewbtn{
  display: inline !important;
  margin-right:30px !important;
}