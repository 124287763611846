/* ---------------gallery images------------- */
.location-icon{
    width: 30px;
    height: 30px;
}

.marine-card{
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 21.5px 0 rgba(0, 0, 0, 0.11);
}
.marine-container{
    box-shadow: 0 0 19.4px 2.2px rgba(0, 0, 0, 0.13);
    border-radius: 0px 10px 10px 10px;
}
.camera-txt{
    font-size: 18px;
    font-weight: 500;
}

.marinesty {
    color: #034973;
    font-size: 27px;
    text-align: center;
  }

  .borderclr{
          border-bottom: 1px solid #c0c0c0;
        width: 100%;
        box-shadow: 0 0 10.2px 0.3px rgba(0, 0, 0, 0.06);
  }

  .meter-bottom-text{
    font-size: 17px;
  }

  .terminal-name {
    font-size: 30px;
  }

  .ssacolor{
      color:#0d58b4;
  }

  .terminal-graph {
    border-radius:10px 10px 0px 0px !important;
    color : #fff !important;
    text-align: center;
    background-color: #4e4e4e;
  }
  
  .marineterminal-img{
    width: 130px;
    height: auto;
  }

  /* Colors for time */
  .street__wait__time__green{
    color: green;
  }
  .street__wait__time__yellow{
    color: #f8be00;
  }
  .street__wait__time__red{
    color: red;
  }

  /* Terminal Turn Time Colors */

.terminal__turn__time__green{
  color: green;
}


  .terminal__turn__time__red{
    color: red;
  }
  .terminal__turn__time__yellow{
    color: #fac200;
  }

  /* Total wait time */
  .terminal__total__wait__time__green{
    color: green;
  }
  .terminal__total__wait__time__yellow{
    color: #fac200;
  }
  .terminal__total__wait__time__red{
    color: red;
  }

  .currentwaittime-head{
    font-size: 20px;
    font-weight: bold;
  }

  /* Camera Load More Button css */
  .load-more{
    background-color: #034973; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    width: auto;
    margin: 0 auto;
    width: 200px !important;
  }
  .fade-in{
  animation: fadeIn .5s ease-in
  }
  @keyframes fadeIn{


    from {
      opacity: 0;
    }
    
    
  to{
    opacity: 1
  }
    

  } 

  .lightbox-target {
    position: fixed;
    top: -100%;
    width: 100%;
    background: rgba(0,0,0,.7);
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow: hidden;
     
    }
    
    /* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */
    
    .lightbox-target img {
    margin: auto;
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    max-height: 0%;
    max-width: 0%;
    border: 3px solid white;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
    box-sizing: border-box;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
      
    }
    
    /* Styles the close link, adds the slide down transition */
    
    a.lightbox-close {
    display: block;
    width:50px;
    height:50px;
    box-sizing: border-box;
    background: white;
    color: black;
    text-decoration: none;
    position: absolute;
    top: -80px;
    right: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    }
    
    /* Provides part of the "X" to eliminate an image from the close link */
    
    a.lightbox-close:before {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
    }
    
    /* Provides part of the "X" to eliminate an image from the close link */
    
    a.lightbox-close:after {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
    }
    
    /* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */
    
    .lightbox-target:target {
    opacity: 1;
    top: 0;
    bottom: 0;
      overflow:scroll;
    }
    
    .lightbox-target:target img {
    max-height: 100%;
    max-width: 100%;
    }
    
    .lightbox-target:target a.lightbox-close {
    top: 0;
    }
    .merine .terminal{
      background-color: #034973;
      font-size: 20px;
    }
    
 /* .pagination-parent{
   
 } */
 .pagination{
   justify-content: center;
 }

 .line {
  stroke-width: 2;
  fill: none;
}

.axis path {
  stroke: black;
}

.text {
  font-size: 12px;
}

.title-text {
  font-size: 12px;
}

.marineCameraModal .btnClose{
    color: #fff!important;
    background-color: #0d58b4!important;
    border-color: #0d58b4!important;
    margin: 10px auto;
    display: block;
    border: 0;
    padding: 5px 20px;
    border-radius: 5px;
}

.marine-container .tab-content {
  box-shadow: none;
  
}

.marine-container .tab-content .advantSearchClass .reportSectionMainDiv .searchFilterDiv input{
  width: 70%;
}