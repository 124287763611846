/* * {
    box-sizing: border-box;
  } */
  
  
  
  /* h1, h2 {
    color: #333333;
  } */
  
  .modal-btn-warning {
    font: inherit;
    padding:10px 40px;
    cursor: pointer;
    border-radius: 7px;
    /* background-color: #0d58b4; */
    background: none;
    color: black;
    border: 1px solid #ffc107;
    margin: 0 1rem;
  }
  
  .modal-btn-warning:hover {
   box-shadow: 0px 0px 5px 0px #ffc107;
   
  }
  .modal-btn-success{
    font: inherit;
    padding:10px 40px;
    cursor: pointer;
    border-radius: 7px;
    border: 1px solid #3DBE29;
    background: none;
    color: black;
    margin: 12px 1rem;
  }
  .modal-btn-success:hover{
    box-shadow: 0px 0px 5px 0px #3DBE29;
  }
  
  /* .btn--alt {
    background-color: transparent;
    color: #800040;
  } */
  
  /* .btn--alt:hover {
    background-color: #f8dae9;
  } */
  
  /* .card {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    width: 20rem;
  } */
  
  .actions {
    text-align: right;
  }
  
  .custom-modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #fbfbfb;
    padding: 1rem;
    text-align: center;
    width: 30rem;
    z-index: 1000;
    position: fixed;
    top: 50vh;
    left: calc(50% - 15rem);
    height: auto !important;
  }
  
  .backdrop {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  .bg-custom-success{
    background-color: #3DBE29 !important;
  }

  /* form {
    position: relative;
    width: 300px;
    padding: 30px;
}
input {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 20px !important;
    line-height: 40px;
}
input:focus {
    outline: 0;
}
svg, line {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
}
input[maxlength="3"] {
    padding-left: 40px;
    letter-spacing: 60px;
}
input[maxlength="3"] + svg line {
    stroke-dasharray: 35;
    stroke-dashoffset: -30;
}
input[maxlength="4"] {
    padding-left: 20px;
    letter-spacing: 50px;
}
input[maxlength="4"] + svg line {
    stroke-dasharray: 30;
    stroke-dashoffset: -10;
} */

.otp__text-center{
  display: flex;
  justify-content: center;
  width: 100%;
  /* margin-left: 160px; */
}
.otp__fs-25{
  /* width: 1rem; */
  /* font-size: 12px; */
  /* padding: 12px; */
  /* padding: 2px; */
  /* width: 20px; */
}


input.otp__fs-25{
  width: 2rem !important;
}
  