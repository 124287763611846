 
.aboutus-section .container-fluid{
    width: 100%;
    margin: auto;
    padding: 10px;
}
.Aboutus_head {
    font-size: 1.688rem;
}

.aboutus_para {
    font-size: 1.25rem;
    color: rgba(35, 35, 35, 0.9);
  }
  .aboutus-underline h2 {
    display: inline-block;
    position: relative;
  }
  
  .aboutus-underline h2:after {
    content: "";
    height: 0.125rem;
    width: 100%;
    background-color: black;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%);
  }

  .modules_para {
    width: 14.75rem;
    margin: 1.281rem 1rem 0 0;
    opacity: 0.9;
    font-family: var(--bs-font-sans-serif);
    font-size: 1.063rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 0, 0);
  }