.vo-namecard-head{
    background-color: #4e4e4e;
    /* padding-left: 10%; */
}
.vessel_estimated {
    border-radius: 10px 10px 0px 0px;
  }
  .lastcol{
      padding-left: 70px;
  }

  .padd-left{
      padding-left:100px;
  }
  .vesselestimationdiv{
      
  } 

  .operationicon-img{
      width: 30px;
  }
  .wetherresults{
    background-color: white;
    padding: 20px 50px;

   margin: 0 auto;
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    width: 90%;
    border-radius: 10px;
  }
  .wetherresults p{
      cursor: pointer;
      padding: 2px;
  }
  .wetherresults p:hover{
      /* padding: 20px; */
      background-color: #f2f2f2;
  }

  .weatherOpacityClass{
    opacity: 0 !important;
  }
  .overflowCss{
    overflow-y: scroll;
    height: 370px;
  }