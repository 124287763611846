.loginhead{
    font-size: 27px;
}

.logindesc{
    font-size: 18px;
}


.center-underline h2.loginhead {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    text-transform: uppercase;
}

.center-underline h2,
.titleTabOption h2{
    display: inline-block;
    position: relative;
    width: 100%;
    height: 50px; 
}

  .center-underline h2:after {
    /* content: "";
    height: 0.125rem;
    width: 100%;
    background-color: black;
    position: absolute;
    bottom: -20%;
    left: 0% !important;
    transform: translate(0%); */

   content: "";
    height: 0.125rem;
    width: 50%;
    background-color: black;
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translate(-50%);
  }
  .titleTabOption h2 span{
    margin: 5px 10px;
  }
  .titleTabOption .text-dark span img{
      filter: grayscale(100%) invert(0%) brightness(100%);
      /* filter: grayscale(100%) invert(0%) brightness(0.5);*/
      margin-top: -5px;
  }

  
  .titleTabOption .text-dark span img{ 
    margin-top: -5px;
}


  .titleTabOption .text-primary span img{
    filter: none;
}

  .titleTabOption .alertTxtDiv h2 span img{
   width:38px
  }

  .divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.login-label{
    font-size: 17px;
}
.form-section{
    border-radius: 5px;
  box-shadow: 0 0 14.5px 0 rgba(0, 0, 0, 0.13);
}
.login-btn{
    background-color: #0d58b4;
    border: none;
    color: white;
    padding: 10px 45px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}



  .emaildiv{
    position: relative;
  }
  .eyeiconspan{
    position: absolute;
    right: 5px;
    top: 29%;
  }
  .tickIconUsername{
    color: #338ff1;
    }
    .tickIconUsername
    {
    position: absolute;
    top: 25%;
    right: 10px;
    z-index: 4;
    }
    .toggle-password{
    position: absolute;
    top: 18%;
    right: 10px;
    z-index: 4; 
    }
    
    .loginsection .form-section form .form-group .col-sm-10{
    position: relative;
    }