.vo-namecard-head{
    background-color: #4e4e4e;
    /* padding-left: 10%; */
}
.vessel_estimated {
    border-radius: 10px 10px 0px 0px;
  }
  .lastcol{
      padding-left: 70px;
  }

  .padd-left{
      padding-left:100px;
  }
  .vesselMapTab{
      width: 70vw;
  } 

  .operationicon-img{
      width: 30px;
  }
  .gm-style-iw-a {
      visibility:hidden;
      /* display: none; */
  }
  .mapPopup {
    width: 320px;
    height: 162px; 
    padding: 65px 0;
    /*padding:40px 0;*/
    border-radius: 20px;
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.42);
    background-color:#fff;
    position: relative;
    visibility: visible;
  }
  .mapPopup h4{ 
    display:block;
    margin: 0 auto;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #076d8e;
  }
    .mapPopup p{
      display:block;
      margin: 10px auto;
      font-size: 26px;
      font-weight: normal;
      font-stretch: condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
    
    .mapPopup .closeBtnPopup{
        cursor: pointer;
        font-weight: bold;
        position: absolute;
        right: 5px;
        background: #550909;
        color: #fff !important;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        padding: 0px 0px;
        font-size: 18px;
        text-align: center;
        top: 5px;
        line-height: 30px;
        border: 0;
    }
    .mapPopup .closeBtnPopup:hover{
        color: #fff !important;
    }
    .tollMapPopUpDiv  {
        width: 350px;
    height: 220px;
    padding: 10px 20px;
    }
.text{
    display: inline-table;
    width: 70%;
}
.text span {
    margin: 5px auto !important;
    width: 100% !important;
}
    .tollMapPopUpDiv p{
        display: block;
        margin: 10px auto;
        text-align: left !important;
        font-size: 14px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }
    .tollMapPopUpDiv p label{
        width:30%;
        color: #076d8e;
    }
    .tollMapPopUpDiv p span{
        width: 70%;
        display: inline-flex;
    }

    /* .tollMapPopUpDiv p span p span {
        display: list-item;
    } */
    .tollMapPopUpDiv p span p  {
        position: relative;
    }
    .tollMapPopUpDiv button{
        text-transform: capitalize;
        color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
        border: 0;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 10px auto;
    display: block;
    }

  .fa-sort-asc{
    position: relative;
    top: 0px;
    left: 12px;
  }
  
b.text-center{
    text-align: center!important;
    display: block;
    width: 50%;

}
.marineDetailPage{
    padding: 8px !important;
}

.searchTerminalNameDiv{
    background: #fff;
    padding: 0px;
    margin: 10px 0 !important;
    text-align: right;
}

.searchTerminalNameSelect{
    width: 70%;
    padding: 7px 10px;
    line-height: 36px;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    background-color: #f9f9f9;
}
.terminalLabel{
    padding: 7px;
}

.advantSearchClass .reportSectionMainDiv {
    padding-right: 20px;
}

.advantSearchClass .reportSectionMainDiv .searchFilterDiv input{
    width: 70%;
}

